import React from 'react'
import { BiFile } from 'react-icons/bi'
import '../css/CoverageCard.css'
import { BsInfoCircle } from 'react-icons/bs'
import { RiCarLine } from 'react-icons/ri'

const CoverageCard = ({ condition, values ,handleClick,selectedPackage}) => {

    return (
        <>
            <div className="card border rounded-3 border-1 pb-3" style={{ boxShadow: condition == selectedPackage ? '0 20px 40px #34393f33':'',transform: condition == selectedPackage ? 'scaleY(1.06)':'',width:'350px'}} id={condition}>
                <div className="card-body w-100">
                    <div className='d-flex flex-row justify-content-between'>
                        <h3 className="pt-3 mt-3 fw-bold text-dark ">{condition}</h3>
                        {
                            condition == 'Better' && <div className="value-tag mt-4"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="mr-xxxs"><g fill="none" fill-rule="evenodd"><path fill="currentColor" d="M8.108 10.104a.628.628 0 0 1-.898.105L5.391 8.701l.798-.962 1.314 1.09L9.71 5.896l1 .752-2.602 3.456zM13.95 4.1a.75.75 0 0 0-.75-.75 5.778 5.778 0 0 1-4.6-2.3c-.284-.377-.917-.377-1.2 0a5.78 5.78 0 0 1-4.6 2.3.75.75 0 0 0-.75.75v3.547a7.207 7.207 0 0 0 3.407 6.148l2.145 1.341a.75.75 0 0 0 .795 0l2.145-1.341a7.207 7.207 0 0 0 3.408-6.148V4.1z"></path><path d="M0 0h16v16H0z"></path></g></svg><span>Our pick for you</span></div>
                        }
                        
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='py-4'>
                            <div>
                                <h5 className='text-dark fw-bold'><BiFile className='me-1' /> Policy coverage</h5>
                            </div>
                            <div className='coverage-details__item'>
                                <BsInfoCircle className='me-2' />
                                <div className="coverage-details__item__title" aria-describedby="coverage-item-personalInjuryProtection">
                                    <div className="coverage-details__item__title__text">Bodily injury (BI)</div>
                                </div>
                                <div className="coverage-details__item__leaders"></div>
                                <div className="coverage-details__item__value">{values[0]}</div>
                            </div>
                            <div className='coverage-details__item'>
                                <BsInfoCircle className='me-2' />
                                <div className="coverage-details__item__title" aria-describedby="coverage-item-personalInjuryProtection">
                                    <div className="coverage-details__item__title__text">Property damage (PD)
                                    </div>
                                </div>
                                <div className="coverage-details__item__leaders"></div>
                                <div className="coverage-details__item__value">{values[1]}</div>
                            </div>
                            <div className='coverage-details__item'>
                                <BsInfoCircle className='me-2' />
                                <div className="coverage-details__item__title" aria-describedby="coverage-item-personalInjuryProtection">
                                    <div className="coverage-details__item__title__text">Personal injury protection (PIP)</div>
                                </div>
                                <div className="coverage-details__item__leaders"></div>
                                <div className="coverage-details__item__value">{values[2]}</div>
                            </div>
                        </div>
                        <div className='pb-4'>
                            <div><h5 className='text-dark fw-bold'><RiCarLine className='me-1' /> Vehicle coverage</h5></div>
                            <div className='coverage-details__item'>
                                <BsInfoCircle className='me-2' />
                                <div className="coverage-details__item__title" aria-describedby="coverage-item-personalInjuryProtection">
                                    <div className="coverage-details__item__title__text">Collision (deductible)</div>
                                </div>
                                <div className="coverage-details__item__leaders"></div>
                                <div className="coverage-details__item__value">{values[3]}</div>
                            </div>
                            <div className='coverage-details__item'>
                                <BsInfoCircle className='me-2' />
                                <div className="coverage-details__item__title" aria-describedby="coverage-item-personalInjuryProtection">
                                    <div className="coverage-details__item__title__text">Comprehensive (deductible)</div>
                                </div>
                                <div className="coverage-details__item__leaders"></div>
                                <div className="coverage-details__item__value">{values[4]}</div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        {/* <ButtonInput type={'button'} name={'Show quotes at this coverage'} /> */}
                        <button type={'button'} className={`btn btn_quote ${condition == selectedPackage ? 'btn_quote_selected' :''}`} onClick={()=>handleClick(condition)}>
                            <p>Show quotes at this coverage</p> 
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CoverageCard