import React, { useContext, useEffect } from 'react'
import '../css/ButtonInput.css'
import {BsArrowRight} from 'react-icons/bs'
import { useLocation } from 'react-router'
import { COVERAGE_SELECTION, RESULT } from '../../services/UserRoutes'
import { UserValContext } from '../../context/UserContextProvider'

const ButtonInput = ({name,type,errors}) => {

    let errorLength = errors ? Object?.keys(errors)?.length : 0

    const { submitting,setSubmitting,button_color} = useContext(UserValContext)
    useEffect(()=>{setSubmitting(false)},[])

    const {pathname} = useLocation()

    return (
        <>
            <button type={`${type}`} onClick={()=>setSubmitting(true)} className={`btn btn_save ${((errorLength && errorLength > 0 ))&& submitting && 'disabled'}`}>
                <p>{name}</p>
                {
                    (pathname == COVERAGE_SELECTION  || pathname == RESULT) ?null :  <BsArrowRight className='btn_aerrow_icon'/>
                }
                
            </button>
        </>
    )
}

export default ButtonInput