import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useLocation } from 'react-router';
import { useContext } from 'react';
import { UserValContext } from '../context/UserContextProvider';
import { useEffect } from 'react';
import { COVERAGE_SELECTION, DRIVER_DETAIL, MANUAL_START, MANUAL_START_DETAIL, VEHICLE_DETAIL, VEHICLE_SELECT } from '../services/UserRoutes';
import { useState } from 'react';
import './MultiStepLoader.css'
import { VscDebugStart } from 'react-icons/vsc'
import { FiUsers } from 'react-icons/fi'
import { TbPremiumRights } from 'react-icons/tb'
import { RiHandCoinLine, RiCarLine } from 'react-icons/ri'
import { FORM_SETTING } from '../../admin/services/AdminRoutePath';
import { Box, Container } from '@mui/material';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <VscDebugStart />,
        2: <RiCarLine />,
        3: <FiUsers />,
        4: <TbPremiumRights />,
        5: <RiHandCoinLine />
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

export default function MultiStepLoader() {

    const { pathname } = useLocation();
    const { activeStep, setActiveStep, skipped, setSkipped, coverageStep, bg_color } = useContext(UserValContext)

    const [steps, setSteps] = useState([]);

    useEffect(() => {
        if (pathname == COVERAGE_SELECTION || pathname == DRIVER_DETAIL || pathname == VEHICLE_DETAIL || pathname == VEHICLE_SELECT || pathname == MANUAL_START_DETAIL || pathname == MANUAL_START) {
            if (coverageStep) {
                setSteps(['Start', 'Vehicles', 'Drivers', 'Coverage', 'Results'])
            }
            else {
                setSteps(['Start', 'Vehicles', 'Drivers', 'Results'])
            }
            // coverageStep ?setSteps( ['start', 'vehicles', 'drivers','coverage','results']) : setSteps(['start', 'vehicles', 'drivers','results'])
            // setSteps()
        }
        else {
            setSteps(['start', 'Home', 'Owner', 'Results'])
        }
    }, [coverageStep, pathname])


    return (
        <Container sx={{ margin: '0px', padding: '0px 30px', width: '100%', position: (pathname == FORM_SETTING) ? '' : 'fixed', backgroundColor: bg_color, zIndex: '222' }}>
            <Box>
                <Stack sx={{ margin: '0px', top: pathname != FORM_SETTING&& '100px', right:pathname != FORM_SETTING && '10%',paddingX:'30px',paddingTop: '35px', width: pathname == FORM_SETTING ? '100%' :'81%', position: (pathname == FORM_SETTING) ? '' : 'fixed', backgroundColor: bg_color, zIndex: '222' }} spacing={4}>
                    <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={ColorlibStepIcon} sx={{textTransform:'uppercase'}}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Stack>
            </Box>
        </Container>
    );
}