import React from 'react'

const InputButton = ({className,type,name,icon,databsdismiss,databstoggle,databstarget,event}) => {

    return (
        <>
        {
            !icon?<button className={className} type={type} data-bs-dismiss={databsdismiss} data-bs-toggle={databstoggle} data-bs-target={databstarget} onClick={event}>{name}</button> : <button className={className} type={type} data-bs-dismiss={databsdismiss} data-bs-toggle={databstoggle} data-bs-target={databstarget} onClick={event}><i className={icon}/> {name}</button>
        }
      
        </>
    )
}

export default InputButton