import {GET_INSURANCE_COMPANY, GET_MAKE_LIST,GET_MODEL_LIST,GET_TRIM_LIST,GET_COLORS} from "../action/UserAction";

const initialState = {
  userMakeList: [],
  userModelList: [],
  userTrimList: [],
  userInsuranceCompany:[],
  colors:{}
};
const UserReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_MAKE_LIST: {
      return {
        ...state,
        userMakeList: [...action.payload],
      };
    }
    case GET_MODEL_LIST: {
      return {
        ...state,
        userModelList: [...action.payload],
      };
    }
    case GET_TRIM_LIST: {
      return {
        ...state,
        userTrimList: [...action.payload],
      };
    }
    case GET_INSURANCE_COMPANY: {
      return {
        ...state,
        userInsuranceCompany: [...action.payload],
      };
    }
    case GET_COLORS: {
      return {
        ...state,
        colors: action.payload,
      };
    }
    default:
      return state;
  }
};

export default UserReducer;
