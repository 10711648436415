import { toast } from "react-toastify";
import { COVERAGE_SELECTION, DRIVER_DETAIL, HOME_OWNER, VEHICLE_SELECT } from "../../user/services/UserRoutes";
import { UserClient } from "../client/UserClient";

export const GET_MAKE_LIST = "GET_MAKE_LIST";
export const GET_MODEL_LIST = "GET_MODEL_LIST";
export const GET_TRIM_LIST = "GET_TRIM_LIST";
export const GET_INSURANCE_COMPANY = "GET_INSURANCE_COMPANY";
export const GET_COLORS = "GET_COLORS";

export const SAVE_MANUAL_START = "SAVE_MANUAL_START";
export const SAVE_MANUAL_START_DETAIL = "SAVE_MANUAL_START_DETAIL";
export const SAVE_VEHICLE_SELECT = "SAVE_VEHICLE_SELECT";
export const SAVE_VEHICLE_DETAIL = "SAVE_VEHICLE_DETAIL";
export const SAVE_DRIVER_DETAIL = "SAVE_DRIVER_DETAIL";
export const SAVE_HOME_START = "SAVE_HOME_START";
export const SAVE_HOME_DETAIL = "SAVE_HOME_DETAIL";
export const SAVE_PACKAGE_DETAIL = "SAVE_PACKAGE_DETAIL";
export const SAVE_INSURANCE_DETAIL = "SAVE_INSURANCE_DETAIL";
export const SAVE_HOME_OWNER = "SAVE_HOME_OWNER";
export const STORE_MANUALDETAIL = 'STORE_MANUALDETAIL';
export const STORE_DRIVERDETAIL = 'STORE_DRIVERDETAIL';

export const getUserMakeList = (year) => (dispatch) => {
  UserClient.get(`/user/vehical-make-list/${year}`)
    .then((res) => {
      dispatch({
        type: GET_MAKE_LIST,
        payload: res.data.data,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};
export const getUserModelList = (obj) => (dispatch) => {
  UserClient.post(`/user/vehical-model-list`, obj)
    .then((res) => {
      dispatch({
        type: GET_MODEL_LIST,
        payload: res.data.data,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};
export const getUserTrimList = (obj) => (dispatch) => {
  UserClient.post(`/user/vehical-trim-list`, obj)
    .then((res) => {
      dispatch({
        type: GET_TRIM_LIST,
        payload: res.data.data,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};
export const getInsuranceCompany = () => (dispatch) => {
  UserClient.get(`/user/get-insur-comp`)
    .then((res) => {
      dispatch({
        type: GET_INSURANCE_COMPANY,
        payload: res.data.data,
      });
    })
    .catch((e) => {
      console.log(e);
    });
}


// ---------------------------------------------STORE FORMS DATA------------------------------

export const storeManualDetail = (old_email, obj,navigate,values,toast) => (dispatch) => {
  UserClient.post(`/user/add-insur-user-detail/${old_email}`,obj)
    .then((res) => {
      dispatch({
        type: STORE_MANUALDETAIL,
        payload: res.data.data,
      });
      dispatch(saveManualStartDetail(values))
      navigate(VEHICLE_SELECT);
    })
    .catch((e) => {
      toast.error(e.response.data.message, {position: toast.POSITION.TOP_RIGHT})
    });
}

export const storeDriverDetail = (old_email, obj,navigate,values,toast,userManualStartDetail,pathname,coverage) => (dispatch) => {
  console.log(coverage,'--------------coverage')
  UserClient.post(`/user/add-vehicle-user-detail/${old_email}`,obj)
    .then((res) => {
      dispatch({type: STORE_DRIVERDETAIL,payload: res.data.data});
      if(pathname == DRIVER_DETAIL){
        dispatch(saveDriverDetail(values))
        dispatch(saveManualStartDetail({...userManualStartDetail,email:values?.email}))
        if(coverage == 'coverage') navigate(COVERAGE_SELECTION);
      }
      else{
        if(pathname == HOME_OWNER){ dispatch(saveHomeOwner(values));dispatch(saveManualStartDetail({...userManualStartDetail,email:values?.email,fname:values?.fname,lname:values?.lname,bdate:values?.bdate}))}
      }
    })
    .catch((e) => {
      toast.error(e.response.data.message, {position: toast.POSITION.TOP_RIGHT})
    });
}

export const saveManualStart = (obj) => {
  return {
    type: SAVE_MANUAL_START,
    payload: obj,
  };
};
export const saveManualStartDetail = (obj) => {
  return {
    type: SAVE_MANUAL_START_DETAIL,
    payload: obj,
  };
};
export const saveVehicleSelect = (obj) => {
  return {
    type: SAVE_VEHICLE_SELECT,
    payload: obj,
  };
};
export const saveVehicleDetail = (obj) => {
  return {
    type: SAVE_VEHICLE_DETAIL,
    payload: obj,
  };
};
export const saveDriverDetail = (obj) => {
  return {
    type: SAVE_DRIVER_DETAIL,
    payload: obj,
  };
};

export const savePackageDetail = (obj) => {
  return {
    type: SAVE_PACKAGE_DETAIL,
    payload: obj,
  };
};
export const saveInsuranceDetail = (obj, toast, navigate) => (dispatch) => {
  UserClient.post(`/user/store-insurance-detail`, obj)
    .then((res) => {
      // console.log(res,'====================res store detail===========================')
      dispatch({
        type: SAVE_INSURANCE_DETAIL,
        payload: res.data.data,
      });
      // toast.success('Lead added successfully !', {position: toast.POSITION.TOP_RIGHT})
    })
  setTimeout(() => { navigate(COVERAGE_SELECTION) }, 4000)
    .catch((e) => {
      console.log(e);
    });
}

// ---------------------------------------------HOME-INSURANCE-DETAILS------------------------------

export const saveHomeStart = (obj) => {
  return {
    type: SAVE_HOME_START,
    payload: obj,
  };
};
export const saveHomeDetail = (obj) => {
  return {
    type: SAVE_HOME_DETAIL,
    payload: obj,
  };
};
export const saveHomeOwner = (obj) => {
  return {
    type: SAVE_HOME_OWNER,
    payload: obj,
  };
};

// ----------------------------------------------GET-COLORS----------------------------------------------------

export const getColors = () => (dispatch) => {
  UserClient.get(`/user/get-form-color`).then((res) => {

    dispatch({
      type: GET_COLORS,
      payload: {
        fColor: res.data.data?.font_color,
        backColor: res.data.data?.bg_color,
        dColor: res.data.data?.dot_color,
        buttonColor: res.data.data?.button_color
      }
    })
  }).catch((e) => { console.log(e); })
}