import {
  SAVE_MANUAL_START,
  SAVE_MANUAL_START_DETAIL,
  SAVE_VEHICLE_DETAIL,
  SAVE_VEHICLE_SELECT, SAVE_DRIVER_DETAIL,
  SAVE_HOME_START,
  SAVE_HOME_DETAIL,
  SAVE_INSURANCE_DETAIL,
  SAVE_PACKAGE_DETAIL,
  SAVE_HOME_OWNER,
  STORE_MANUALDETAIL
} from "../action/UserAction";

const initialState = {
  userManualStart: {},
  userManualStartDetail: {},
  userVehicleSelect: {},
  userVehicleDetail: {},
  userDriverDetail: {},
  userHomeStart: {},
  userHomeDetail: {},
  userPackageDetail: {},
  userHomeOwner:{}
};
const UserStoreDetail = function (state = initialState, action) {
  switch (action.type) {
    case SAVE_MANUAL_START:
      {return {...state,userManualStart: action.payload};}
    case SAVE_MANUAL_START_DETAIL:
      {
        return {...state,userManualStartDetail: action.payload};
      }
    case SAVE_VEHICLE_SELECT:
      {
        return {
          ...state,
          userVehicleSelect: action.payload,
        };
      }
    case SAVE_VEHICLE_DETAIL:
      {
        return {
          ...state,
          userVehicleDetail: action.payload,
        };
      }
    case SAVE_DRIVER_DETAIL:
      {
        return {
          ...state,
          userDriverDetail: action.payload,
        };
      }
    case SAVE_HOME_START:
      {
        return {
          ...state,
          userHomeStart: action.payload,
        };
      }
    case SAVE_HOME_DETAIL:
      {
        return {
          ...state,
          userHomeDetail: action.payload,
        };
      }
    case SAVE_HOME_OWNER:
      {
        return {
          ...state,
          userHomeOwner: action.payload,
        };
      }
    case SAVE_PACKAGE_DETAIL:
      { return { ...state, userPackageDetail: action.payload } }
    case SAVE_INSURANCE_DETAIL:
      { return { ...state } }
    case STORE_MANUALDETAIL:
      { return { ...state } }
    default:
      return state;
  }
};

export default UserStoreDetail;
