import React, { useState } from 'react'
import '../css/AdminIntegration.css'
const Zapier = () => {
  const [show, setshow] = useState(false);
  const [show2, setshow2] = useState(false);
  return (
    <>
      {/* <div className="container-fluid zap-height">
    
        <div className="row">
          <div className="col">
            <div className="card mb-0">
              <div className="card-body">
                <div className="d-flex position-relative">
                  <div className='trigger-logo-zapier p-3'>
                    <svg width="30" height="30" className="Content-logo-box" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-v-0ec2719f=""><path fill-rule="evenodd" clip-rule="evenodd" d="M2.02002 12C2.02002 6.49002 6.49002 2.02002 12 2.02002C17.51 2.02002 21.98 6.49002 21.98 12C21.98 17.51 17.51 21.98 12 21.98C6.49002 21.98 2.02002 17.51 2.02002 12ZM11.48 13.74V20L16.35 10.26H13V4.00002L8.00002 13.74H11.48Z" fill="#666666"></path></svg>
                  </div>
                  <div className='ms-3 mt-1'>
                    <h5 className="mt-0 title-text">1. Trigger</h5>
                    <p className='p-text'>A trigger is an event that starts your Zap</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className="make-line col-12"><div className="zap-line" /></div>
          {show ? <> <div className="row p-0">
            <div className="col">
              <div className="card bg-red text-white mb-0">
                <div className="card-body">
                  <div className="d-flex position-relative">
                    <div className='trigger-logo-zapier p-3'>
                      <svg width="30" height="30" className="" fill='#fff' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" ><path fill-rule="evenodd" clip-rule="evenodd" d="M2.02002 12C2.02002 6.49002 6.49002 2.02002 12 2.02002C17.51 2.02002 21.98 6.49002 21.98 12C21.98 17.51 17.51 21.98 12 21.98C6.49002 21.98 2.02002 17.51 2.02002 12ZM11.48 13.74V20L16.35 10.26H13V4.00002L8.00002 13.74H11.48Z" fill="#fff"></path></svg>
                    </div>
                    <div className='ms-3 mt-1'>
                      <h5 className="mt-0 title-text text-white">Trigger</h5>
                      <p className='p-text'>An action is an event a Zap performs after it starts</p>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className='main-content'>
                  <div className='content-color'>
                    <div className='content-box'>
                      <div className='main-content'>
                        <div class="logo-flex-two d-flex" >
                          <div class="logo-app d-inline-flex" >
                            <div class="for-padding d-inline-flex m-2" >
                              <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4 8H8V4H4V8ZM10 20H14V16H10V20ZM8 20H4V16H8V20ZM4 14H8V10H4V14ZM14 14H10V10H14V14ZM16 4V8H20V4H16ZM14 8H10V4H14V8ZM16 14H20V10H16V14ZM20 20H16V16H20V20Z" fill="#FFBD59" ></path>
                              </svg>
                            </div>
                          </div>
                          <div class="main-text-line" ><div class="ones-text" >App Event</div>
                            <div class="two-text" > Start the Zap when something happens in an app </div></div></div>
                        <div className='logo-box'>
                          <div className='work-box d-flex'>
                            <div className="logo-box-one ml-2 "><div>
                              <img className="img-set" width={30} height={30} src="/assets/images/integration/active.png" /></div>
                              <div className='fs-5'>Active Campaign</div>
                            </div>
                            <div className="logo-box-one ml-2 "><div>
                              <img className="img-set" width={30} height={30} src="/assets/images/integration/google-sheet.png" /></div>
                              <div className='fs-5'>Google Sheet</div>
                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div></> : <div className="d-flex justify-content-center" style={{ cursor: "pointer" }} onClick={() => { setshow(!show) }}><div className="" style={{ height: 26 }}><svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ background: 'white', borderRadius: '50%' }}><path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#666666" /></svg></div></div>}

          <div className="make-line col-12"><div className="zap-line" /></div>
          <div className="d-flex justify-content-center"><div className="arrow-zap" >▼</div></div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card mb-0">
              <div className="card-body">
                <div className="d-flex position-relative">
                  <div className='trigger-logo-zapier p-3'>
                    <svg width="30" height="30" className="Content-logo-box" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-v-0ec2719f=""><path fill-rule="evenodd" clip-rule="evenodd" d="M2.02002 12C2.02002 6.49002 6.49002 2.02002 12 2.02002C17.51 2.02002 21.98 6.49002 21.98 12C21.98 17.51 17.51 21.98 12 21.98C6.49002 21.98 2.02002 17.51 2.02002 12ZM11.48 13.74V20L16.35 10.26H13V4.00002L8.00002 13.74H11.48Z" fill="#666666"></path></svg>
                  </div>
                  <div className='ms-3 mt-1'>
                    <h5 className="mt-0 title-text">2. Action</h5>
                    <p className='p-text'>A Action is an event that starts your Zap</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className="make-line col-12"><div className="zap-line" /></div>
          {show2 ? <> <div className="row p-0">
            <div className="col">
              <div className="card mb-0">
                <div className="card-body bg-red text-white">
                  <div className="d-flex position-relative">
                    <div className='trigger-logo-zapier p-3'>
                      <svg width="30" height="30" className="" fill='#fff' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" ><path fill-rule="evenodd" clip-rule="evenodd" d="M2.02002 12C2.02002 6.49002 6.49002 2.02002 12 2.02002C17.51 2.02002 21.98 6.49002 21.98 12C21.98 17.51 17.51 21.98 12 21.98C6.49002 21.98 2.02002 17.51 2.02002 12ZM11.48 13.74V20L16.35 10.26H13V4.00002L8.00002 13.74H11.48Z" fill="#fff"></path></svg>
                    </div>
                    <div className='ms-3 mt-1'>
                      <h5 className="mt-0 title-text text-white">Action</h5>
                      <p className='p-text'>An action is an event a Zap performs after it starts</p>
                    </div>
                  </div>
                </div>
                <div className='main-content'>
                  <div className='content-color'>
                    <div className='content-box'>
                      <div className='main-content'>
                        <div class="logo-flex-two d-flex" >
                          <div class="logo-app d-inline-flex" >
                            <div class="for-padding d-inline-flex m-2" >
                              <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4 8H8V4H4V8ZM10 20H14V16H10V20ZM8 20H4V16H8V20ZM4 14H8V10H4V14ZM14 14H10V10H14V14ZM16 4V8H20V4H16ZM14 8H10V4H14V8ZM16 14H20V10H16V14ZM20 20H16V16H20V20Z" fill="#FFBD59" ></path>
                              </svg>
                            </div>
                          </div>
                          <div class="main-text-line" ><div class="ones-text" >App Event</div>
                            <div class="two-text" > Start the Zap when something happens in an app </div></div></div>
                        <div className='logo-box'>
                          <div className='work-box d-flex'>
                            <div className="logo-box-one ml-2 "><div>
                              <img className="img-set" width={30} height={30} src="/assets/images/integration/active.png" /></div>
                              <div className='fs-5'>Active Campaign</div>
                            </div>
                            <div className="logo-box-one ml-2 "><div>
                              <img className="img-set" width={30} height={30} src="/assets/images/integration/google-sheet.png" /></div>
                              <div className='fs-5'>Google Sheet</div>
                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div></> : <div className="d-flex justify-content-center" style={{ cursor: "pointer" }} onClick={() => { setshow2(!show2) }}><div className="" style={{ height: 26 }}><svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ background: 'white', borderRadius: '50%' }}><path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#666666" /></svg></div></div>}

          <div className="make-line col-12"><div className="zap-line" /></div>
          <div className="d-flex justify-content-center"><div className="arrow-zap" >▼</div></div>
        </div>
        </div> */}
      



      


    </>
  )
}

export default Zapier