import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import { adminLogout } from '../../redux/action/AdminAction';
import { AdminValAuthContext } from '../context/AdminAuthProvider'
import { useDispatch } from 'react-redux';
import BackDrop from '../Input Function/BackDrop';
import { getLoggedInAdmin, sidebarToggle } from '../services/AdminLocalStorage';
import InputButton from '../Input Function/InputButton';


const AdminHeader = () => {

  const val = useContext(AdminValAuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [show,setShow]=useState(false)
  const {name} = getLoggedInAdmin();
  const handleLogout = () => {
    setLoading(true)
    dispatch(adminLogout(navigate, setLoading))

  }
  function useOutsideAlerter(ref) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
               setShow(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

let windowWidth;
useEffect(() => {
  windowWidth = (window.innerWidth)
  let divId = document.getElementById('page-topbar')
  sidebarToggle(divId,windowWidth,val?.showSidebar)
}, [windowWidth,val?.showSidebar])
const wrapperRef = useRef(null);
useOutsideAlerter(wrapperRef);
  return (
    <>
      {loading ? <BackDrop /> : null}
      <header id="page-topbar" >
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <button type="button" className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger" id="topnav-hamburger-icon" onClick={() => { val?.setShowSidebar(!val?.showSidebar) }}>
                <span className={`hamburger-icon ${val?.showSidebar ? '' : "show"}`} >
                  <span />
                  <span />
                  <span />
                </span>
              </button>
              {/* App Search*/}
              {/* <form className="app-search d-none d-md-block">
                <div className="position-relative">
                  <input type="text" className="form-control" placeholder="Search..." autoComplete="off" id="search-options" />
                  <span className="mdi mdi-magnify search-widget-icon" />
                  <span className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none" id="search-close-options" />
                </div>
              </form> */}
            </div>
            <div className="d-flex align-items-center">
              <div className="dropdown ms-sm-3 header-item">
                <InputButton event={()=>{setShow(true)}} type="button" className="btn text-muted" icon={"ri-account-circle-line fs-2"}/>
                <div ref={wrapperRef} className={`dropdown-menu dropdown-menu-end dropdown-wh ${show ? "d-block" : "d-none"}`}>
                  <h6 className="dropdown-header">Welcome {name}!</h6>
                  {/* <a className="dropdown-item" href="pages-profile.html"><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1" /> <span className="align-middle">Profile</span></a> */}
                  <a className="dropdown-item" onClick={() => { handleLogout() }} style={{ cursor: "pointer" }}><i className="mdi mdi-logout text-muted fs-16 align-middle me-1" /> <span className="align-middle" data-key="t-logout">Logout</span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default AdminHeader