import React from 'react'
import ButtonInput from '../comp/js/ButtonInput'
import { useNavigate } from 'react-router'
import { MANUAL_START } from '../services/UserRoutes'

const ThankYouPage = () => {

    const navigate = useNavigate()
    return (
        <>
            <div className="auth-page-wrapper mt-5 d-flex justify-content-center align-items-center pt-5">
                <div className="auth-page-content overflow-hidden p-0">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-7 col-lg-8">
                                <div className="text-center">
                                    <img src={require('../../assets/check.png')} style={{ height: '150px', border: '4px solid #315c62', borderRadius: '50%', padding: '10px' }} alt="img" className="img-fluid" />
                                    <div className="mt-3">
                                        <h3 className="text-uppercase">Allright , done !</h3>
                                        <p className="text-muted mb-4 text-justify">We're unable to find online rates for you at this time.
                                            Please call our agency for other bundled insurance options. It may be that some insurance companies need more information.</p>
                                        <span className='d-flex justify-content-center align-items-center' onClick={() => navigate(MANUAL_START)}>
                                            <ButtonInput name={"Redirect to home"} type={"button"} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ThankYouPage