import axios from "axios";
import { backend_url } from "../../user/services/Methods";

// const baseUrl = 'http://localhost:5000/api'
const baseUrl = `${backend_url}api/`

export const UserClient=   axios.create({
    baseURL:baseUrl,
    headers:{
        // 'Accept':'application/json',
        'Content-Type':'application/json',
        'Authorization':
        {
            toString () {
              return `Bearer ${localStorage.getItem('user-token')}`
            }
        }
}}); 
UserClient.interceptors.response.use( response => {
  return response;
}, error => {
  if(error?.response?.status == 401)
  {  
      localStorage.clear();
      window.location.reload();
      window.location.href  = 'https://app.insuringthefuture.com'
      // swal({
      //     icon: "warning",
      //     text: error.response.data.message,  
      //     closeOnClickOutside: false,
      //   }).then(function() {
      //     localStorage.clear();
      //     window.location.reload();
      //     window.location.href  = 'http://localhost:3000/admin/login'
      // });
  }
  return Promise.reject(error);
});