import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import InputButton from '../Input Function/InputButton';
import InputLodingButton from '../Input Function/InputLodingButton';
import InputText from '../Input Function/InputText';
import '../css/AdminGoogleModel.css'
import InputLabel from '../Input Function/InputLabel';
import InputSelectBox from '../Input Function/InputSelectBox';
import * as yup from 'yup'
import { ACTIVE_CAMPAIGN, GOOGLE_SHEET } from '../services/AdminRoutePath';
import { useLocation } from 'react-router';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { addActiveCampaignData, EDIT_LEAD_USER, getDataList, getDatas, GET_ACTIVE_CAMPAIGN, GET_GOOGLE_ALL_USERS, GET_USER_LIST, LeadEditData } from '../../redux/action/AdminAction';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { GOOGLE_API_KEY } from '../../user/services/UserRoutes';
import classNames from "classnames";
import moment from 'moment';
import { options } from '../../user/services/Methods';
const EditLeadsModel = ({ title, setTitle, showEditLeads, setShowEditLeads, editLeads, setEditLeads ,currentPage,setCurrentPage}) => {
    const [loading, setLoading] = useState(false);
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    let load = false;
    useEffect(() => {
        if (editLeads) {
            switch (title) {
                case 'user':
                    setFieldValue("fname", editLeads?.firstname)
                    setFieldValue('lname', editLeads?.lastname)
                    setFieldValue('dob', moment(editLeads?.birthdate).format('YYYY-MM-DD'))
                    setFieldValue('email', editLeads?.email)
                    setFieldValue('unit', editLeads?.unit)
                    setFieldValue('address', editLeads?.address)
                    setFieldValue('city', editLeads?.city)                   
                    setFieldValue('state', {
                        label: editLeads?.state, value: editLeads?.state
                    })
                    setFieldValue('zipcode', editLeads?.zipcode)
                    break;
                default:
                    break;
            }
        }
    }, [editLeads])

    useEffect(() => {
        if (!load) {
            switch (pathname) {
                case GOOGLE_SHEET:
                    dispatch(getDatas(setLoading, `admin/get-all-google-sheet-users`, GET_GOOGLE_ALL_USERS))
                    break;
                case ACTIVE_CAMPAIGN:
                    dispatch(getDatas(setLoading, 'admin/get-active-campaign', GET_ACTIVE_CAMPAIGN))
                    break;
                default:
                    break;
            }


        }
        return () => { load = true }
    }, [])
    const handleClose = () => {
        setShowEditLeads(false);
    }
    const { values, errors, handleBlur, handleChange, handleSubmit, touched, setFieldValue } = useFormik({
        initialValues: title == 'user' ? {
            fname: "",
            lname: "",
            dob: "",
            email: "",
            unit: "",
            address: "",
            city: "",
            state: "",
            zipcode: ""
        } : pathname == ACTIVE_CAMPAIGN ? {
            acName: "",

        } : ""
        ,
        validationSchema: title == 'user' ? yup.object({
            fname: yup.string().required("Please enter firstname"),
            lname: yup.string().required("Please enter lastname"),
            dob: yup.string().required("Please select birthdate"),
            email: yup.string().required("Please enter email"),
            address: yup.string().required("Please enter address"),
            city: yup.string().required("Please enter city"),
            state: yup.object().required("Please select state"),
            zipcode: yup.string().required("Please enter zipcode"),
        }) : pathname == ACTIVE_CAMPAIGN ? yup.object({
            acName: yup.object().required("Please select account"),
        }) : "",

        onSubmit: (values, { resetForm }) => {
            const formdata = new FormData();
            switch (title) {
                case 'user':
                    formdata.append("firstname", values?.fname)
                    formdata.append('lastname', values?.lname)
                    formdata.append('birthdate', moment(values?.dob).format('YYYY-MM-DD'))
                    formdata.append('email', values?.email)
                    formdata.append('unit', values?.unit ? values?.unit : null)
                    formdata.append('address', values?.address)
                    formdata.append('city', values?.city)
                    formdata.append('state', values?.state?.value)
                    formdata.append('zipcode', values?.zipcode)
                    break;
                case ACTIVE_CAMPAIGN:
                    formdata.append('api_url', values.acName.value.url);
                    formdata.append('api_key', values.acName.value.key);
                    break;
                default:
                    break;
            }
            switch (title) {
                case 'user':
                    dispatch(LeadEditData(formdata, setLoading, setShowEditLeads, setTitle, getDataList, currentPage, "User updated sucessfully!", EDIT_LEAD_USER, `admin/update-lead-user/${editLeads?.id}`, setCurrentPage, `admin/get-user-detail/?page=${currentPage}`,GET_USER_LIST));
                    break;
                case ACTIVE_CAMPAIGN:
                    dispatch(addActiveCampaignData(formdata, setLoading, setShowEditLeads));
                    break;
                default:
                    break;
            }
            setLoading(true);
            resetForm({ values: '' });
        }
    })
    const handleAddress = ({ formatted_address }) => {
        let address = formatted_address.split(',')
        setFieldValue('address', formatted_address.split(',').at(0))
        setFieldValue('city', address.at(-3))
        setFieldValue('state', { value: address.at(-2).slice(0, 3), label: address.at(-2).slice(0, 3) })
        setFieldValue('zipcode', address.at(-2).split(' ').at(-1))
    }
    return (
        <>
            <div className='admin-model'>
                <Modal
                    size={"md"}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={setShowEditLeads}
                    backdrop="static"
                    keyboard={false}
                    className='fade'
                >
                    <div className="modal-content border-0">
                        <div className="modal-header p-3 bg-soft-green">
                            <h5 className="modal-title text-capitalize" id="createTaskLabel">Edit leads</h5>
                            <InputButton type="button" className="btn-close" databsdismiss="modal" event={() => { handleClose() }} />
                        </div>
                        <div className="modal-body">

                            <form onSubmit={handleSubmit}>
                                {title == 'user' ? <>
                                    <div className="mb-3">
                                        <InputLabel className="form-label" name="First Name" />
                                        <InputText
                                            name="fname"
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter first name"
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            value={values?.fname}
                                            error={errors.fname}
                                            touch={touched.fname} />
                                    </div>
                                    <div className="mb-3">
                                        <InputLabel className="form-label" name="Last Name" />
                                        <InputText
                                            name="lname"
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter last name"
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            value={values?.lname}
                                            error={errors.lname}
                                            touch={touched.lname} />
                                    </div>
                                    <div className="mb-3">
                                        <InputLabel className="form-label" name="Birthdate" />
                                        <InputText
                                            name="dob"
                                            type="date"
                                            className="form-control"
                                            placeholder="Enter birthdate"
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            value={values?.dob}
                                            error={errors.dob}
                                            touch={touched.dob} />
                                    </div>
                                    <div className="mb-3">
                                        <InputLabel className="form-label" name="Email" />
                                        <InputText
                                            name="email"
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter email"
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            value={values?.email}
                                            error={errors.email}
                                            touch={touched.email} />
                                    </div>
                                    <div className="mb-3">
                                        <InputLabel className="form-label" name="Unit" />
                                        <InputText
                                            name="unit"
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter unit"
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            value={values?.unit}
                                            error={errors.unit}
                                            touch={touched.unit} />
                                    </div>
                                    <div className="mb-3">
                                        <InputLabel className="form-label" name="Address" />
                                        <ReactGoogleAutocomplete
                                            apiKey={GOOGLE_API_KEY}
                                            options={{ types: ["geocode", "establishment"], componentRestrictions: { country: "us" } }}
                                            types={["address"]}
                                            onChange={(e) => setFieldValue('address', e.target.value)}
                                            placeholder={values?.address ? values?.address : "Address"}
                                            onPlaceSelected={(place) => handleAddress(place)
                                            }
                                            className={classNames(`${!errors.address} form-control form-control-md `, { 'is-invalid': (values?.address == '' || !values?.address) && errors?.address })}
                                            name={'address'}
                                            value={values.address}
                                        />
                                         {!values.address &&errors.address  && <span className="text-danger">{errors.address}</span>}
                                    </div>
                                    <div className="mb-3">
                                        <InputLabel className="form-label" name="City" />
                                        <InputText
                                            name="city"
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter city"
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            value={values?.city}
                                            error={errors.city}
                                            touch={touched.city} />
                                    </div>
                                    <div className="mb-3">
                                        <InputLabel className="form-label" name="State" />
                                        <InputSelectBox
                                            title='user'
                                            name="state"
                                            className="form-select py-0 ps-0 select-control "
                                            setFieldValue={setFieldValue}
                                            handleBlur={handleBlur}
                                            value={values?.state}
                                            error={errors.state}
                                            touch={touched.state}
                                            opt={options}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <InputLabel className="form-label" name="Zipcode" />
                                        <InputText
                                            name="zipcode"
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter zipcode"
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            value={values?.zipcode}
                                            error={errors.zipcode}
                                            touch={touched.zipcode} />
                                    </div>
                                </> : null}
                                <div className="hstack gap-2 justify-content-end">
                                    <InputButton type="button" className="btn btn-ghost-success" databsdismiss="modal" icon="ri-close-fill align-bottom" name="Close" event={() => { handleClose() }} />
                                    {
                                        loading ? <InputLodingButton name={'Editing...'} className="btn btn-primary btn-load" databsdismiss="model" /> : <InputButton className="btn btn-primary text-capitalize" type="submit" name={`Edit`} />
                                    }
                                </div>
                            </form>

                        </div>
                    </div>

                </Modal>
            </div>
        </>
    )
}

export default React.memo(EditLeadsModel)