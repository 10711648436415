import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { getActiveCampaign, getBrands, getInsurComps, getModels, getTrimName, getUsers, getVehicles ,getGoogleAllAccount, GET_BRANDS, GET_TRIMNAME, GET_INSUR_COMPS, GET_MODELS, GET_VEHICLES, GET_USERS, GET_GOOGLE_ALL_USERS, GET_ACTIVE_CAMPAIGN, getDatas, GET_GOOGEL_SHEET_ALL_AC} from '../../redux/action/AdminAction'
import { AdminValAuthContext } from '../context/AdminAuthProvider'
import { ACTIVE_CAMPAIGN, ALL_BRANDS, ALL_INSUR_COMP, ALL_LEAD, ALL_MODELS, ALL_TRIMNAME, ALL_VEHICLE, GOOGLE_SHEET } from '../services/AdminRoutePath'

const AdminContent = () => {
  const val=useContext(AdminValAuthContext)
  let load=false;
  const [loading,setLoading]=useState(false);
  const navigate =useNavigate();
  const dispatch=useDispatch()
  useEffect(() => {
         if(!load)
         {
          setLoading(true)
          dispatch(getDatas(setLoading,`admin/get-brands`,GET_BRANDS));
          dispatch(getDatas(setLoading,'admin/get-models',GET_MODELS))
          dispatch(getDatas(setLoading,'admin/get-trimNames',GET_TRIMNAME))
          dispatch(getDatas(setLoading,'admin/get-vehicles',GET_VEHICLES))
          dispatch(getDatas(setLoading,'admin/get-all-user-detail',GET_USERS))  
          dispatch(getDatas(setLoading,`admin/get-insur-comp`,GET_INSUR_COMPS))
          dispatch(getDatas(setLoading,`admin/get-all-google-sheet-users`,GET_GOOGEL_SHEET_ALL_AC))
          dispatch(getDatas(setLoading,'admin/get-active-campaign',GET_ACTIVE_CAMPAIGN))
         }
           return ()=>{load = true}
    },[])
    const brandList = useSelector((state) => state.AdminReducer.brandList);
  const modelList = useSelector((state) => state.AdminReducer.modelList);
  const vehicleList = useSelector((state) => state.AdminReducer.vehicleList);
  const trimNameList = useSelector((state) => state.AdminReducer.trimNameList);
  const userList = useSelector((state) => state.AdminReducer.userList);
  const insurCompList = useSelector((state) => state.AdminReducer.insurCompList);
  const activeCampaignList = useSelector((state) => state.AdminReducer.activeCampaignList);
  const googleAllAccount = useSelector((state) => state.AdminReducer.googleAllAccount);

    const obj = [
      { title: "Total Brand", allLink:"View all brand", path: ALL_BRANDS, key: 1, icon: "bx bx-hive",total:brandList?.length, color:"text-success",bgColor:"bg-soft-success"},
      { title: "Total Model", allLink:"View all model", path: ALL_MODELS, key: 2, icon: "bx bx-qr",total:modelList?.length,color:"text-info",bgColor:"bg-soft-info" },
      { title: "Total Trimname", allLink:"View all trimname", path: ALL_TRIMNAME, key: 3, icon: "las la-layer-group",total:trimNameList?.length,color:"text-warning",bgColor:"bg-soft-warning" },
      { title: "Total Vehicle", allLink:"View all vehicle", path: ALL_VEHICLE, key: 4, icon: "las la-car-side",total:vehicleList?.length,color:"text-primary",bgColor:"bg-soft-primary" },
      { title: "Total Lead", allLink:"View all lead", path: ALL_LEAD, key: 5, icon: "bx bx-list-check",total:userList?.length,color:"text-secondary",bgColor:"bg-soft-secondary" },
      { title: "Total Insurance Company", allLink:"View all insurance company", path: ALL_INSUR_COMP, key: 6, icon: "las la-city",total:insurCompList?.length,color:"text-danger",bgColor:"bg-soft-danger" },
      { title: "Total Google Sheet Account", allLink:"View all google sheet account", path: GOOGLE_SHEET, key: 7, icon: "bx bx-spreadsheet",total:googleAllAccount?.length,color:"text-success",bgColor:"bg-soft-success" },
      { title: "Total Active Campaign Account", allLink:"View all active campaign account", path: ACTIVE_CAMPAIGN, key: 8, icon: " bx bx-detail",total:activeCampaignList?.length,color:"text-warning",bgColor:"bg-soft-warning" },
    ]
  return (
    <>
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="h-100">
                  <div className="row">
                    {obj && obj?.map((item,index)=>{
                      return  <div className="col-xl-3 col-md-6">
                      <div className="card card-animate">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-truncate mb-0"> {item?.title}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-4">
                            <div>
                                 
                            {loading ? <h4><i class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i></h4> :<h4 className="fs-22 fw-semibold ff-secondary mb-4">{item?.total}</h4>} 
                              
                              <a className="text-decoration-underline" onClick={()=>{navigate(item?.path)}} style={{cursor:"pointer"}}>{item?.allLink}</a>
                            </div>
                            <div className="avatar-sm flex-shrink-0">
                              <span className={`avatar-title ${item?.bgColor} rounded fs-3`}>
                                <i className={`${item?.icon} ${item?.color}`}/>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
    </>
  )
}

export default AdminContent