import React, { useContext, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { HOME_DETAIL } from "../../services/UserRoutes";
import ButtonInput from "../../comp/js/ButtonInput";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import TextFieldInput from "../../comp/js/TextFieldInput";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineCheck } from 'react-icons/ai'
import { BsInfoCircle } from 'react-icons/bs'
import { saveHomeOwner, saveInsuranceDetail, storeDriverDetail } from "../../../redux/action/UserAction";
import { btn_col, color, que_col, que_value_col, requiredFeild } from "../../services/Methods";
import { differenceInYears, parse } from "date-fns"
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'jquery-mask-plugin/dist/jquery.mask.min';
import { UserValContext } from "../../context/UserContextProvider";
import moment from "moment";

const HomeOwner = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {pathname} = useLocation()

    const { submitting, setSubmitting } = useContext(UserValContext)

    let { userHomeOwner, userDriverDetail, userHomeDetail, userHomeStart ,userManualStartDetail} = useSelector((state) => state?.persistedReducer);
    const numberStyle = { padding: "5px 7px" };

    const { values, setFieldValue, errors, handleBlur, handleChange, handleSubmit, touched, setValues, setFieldError } = useFormik({
        initialValues: { bdate: userHomeOwner?.bdate ? userHomeOwner?.bdate : userManualStartDetail?.bdate?userManualStartDetail?.bdate :"", fname: userHomeOwner?.fname ? userHomeOwner?.fname : userManualStartDetail.fname?userManualStartDetail?.fname :"", lname: userHomeOwner?.lname ? userHomeOwner?.lname : userManualStartDetail?.lname? userManualStartDetail?.lname: "", email: userHomeOwner?.email ? userHomeOwner?.email : userManualStartDetail?.email ? userManualStartDetail?.email :'' },
        validationSchema: yup.object().shape({
            fname: yup.string().required(requiredFeild), lname: yup.string().required(requiredFeild),
            email: yup.string().email("Invalid Email address").required("Email is required"),
            // bdate: yup.date().required(requiredFeild).max(sub({ years: 16 }, new Date()), "User must be 16 years old"), email: yup.string().email("Invalid Email address").required(requiredFeild)
        }),
        onSubmit: (values, { resetForm }) => {

            let home_obj = { home_type: userHomeStart?.home_kind, address: userHomeStart?.address, unit: userHomeStart?.unit, city: userHomeStart?.city, state: userHomeStart?.state?.value, zipcode: userHomeStart?.zipcode, own_home: userHomeDetail?.home_own, built_year: userHomeDetail?.built_year, buy_year: values?.buy_year, foundation_type: userHomeDetail?.foundation_type?.value, last_replace_year: userHomeDetail?.roof_year, squre_footage: userHomeDetail?.square_footage, rebuild_cost: userHomeDetail?.rebuilt_cost, closest_fire_station: userHomeDetail?.fire_station,firstname: values?.fname, lastname: values?.lname, birthdate: moment(values?.bdate, "MM/DD/YYYY").format("YYYY-MM-DD"), email: values?.email  }

            let old_email = userManualStartDetail?.email ? userManualStartDetail?.email:null;
            // dispatch(saveInsuranceDetail({ ...userDriverDetail?.insurance_obj, home_obj }, toast, navigate))

            let storeObj = {vehicle_obj:userDriverDetail?.insurance_obj?.vehicle_obj,driver_obj:userDriverDetail?.insurance_obj?.driver_obj,home_obj}
            dispatch(storeDriverDetail(old_email,storeObj,navigate,values,toast,userManualStartDetail,pathname))

            dispatch(saveHomeOwner(values));
            // if (values) naviagte(RESULT);
        },
    });

    useEffect(() => {
        if ((values?.bdate == '' || !values?.bdate)&& submitting) setFieldError('bdate', 'This field is required !')
        else if (values?.bdate?.length == 10) calculateDate(values?.bdate)
        else setFieldError['bdate'] = ''
    }, [values, submitting,errors])

    const calculateDate = (dob) => {
        const date = parse(dob, "MM/dd/yyyy", new Date())
        if (date == 'Invalid Date' && submitting) setFieldError('bdate', 'Invalid Date !')
        const age = differenceInYears(new Date(), date)
        if (age < 16) setFieldError('bdate', 'User must be 16 years old !')
    }


    return (
        <>
            <div className="container-fluid w-100 pt-3 g-3 mb-5" style={{ paddingTop: "20px" }}>
                <form onSubmit={handleSubmit}>
                    <div className="row g-2 mb-4">
                        <h3>Let’s build your protection from the foundation up!</h3>
                    </div>

                    <div className={`row g-2 mt-4`}>
                        <div className={`${que_col} col-12`}>
                            <div className="d-flex">
                                <span
                                    className="me-2 badge border border-user-secondary rounded-circle text-dark"
                                    style={{ height: '28px', width: '28px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: (values?.fname && values?.lname && values?.bdate && values?.email) ? color : '', padding: '2px' }}
                                >
                                    {(values?.fname && values?.lname && values?.bdate && values?.email) ? <AiOutlineCheck style={{ color: 'white', fontSize: '20px' }} /> : <div style={numberStyle}>1</div>}
                                </span>
                                <div className="w-100">
                                    <h3>Tell us a little about yourself</h3>
                                    <span
                                        className="description fw-bold"
                                        style={{ color: color }}
                                    >
                                        <BsInfoCircle className="fs-5 me-1" />
                                        <a href="#" style={{ color: color }} className="m-0 text-decoration-none">Learn about how your information is used</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={`${que_value_col} col-12`}>
                            <div
                                className="btn-group d-flex flex-column gap-3"
                                role="group"
                                aria-label="Basic radio toggle button group"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.rent_home}
                            >
                                <TextFieldInput w={"w-100"} placeholder={"First name"} error={errors.fname} touched={touched.fname} name={"fname"} value={values.fname} handleChange={handleChange} handleBlur={handleBlur}/>
                                <TextFieldInput w={"w-100"} placeholder={"Last name"} error={errors.lname} touched={touched.lname} name={"lname"} value={values.lname} handleChange={handleChange} handleBlur={handleBlur}/>
                                {/* <TextFieldInput
                                    type={'date'}
                                    w={"w-100"}
                                    placeholder={"Birthdate"}
                                    error={errors.bdate}
                                    touched={touched.bdate}
                                    name={"bdate"}
                                    value={values.bdate}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                /> */}
                                <TextFieldInput maxlength={10} pattern="[0-9/]+" type={'tel'} w={"w-100"} placeholder={"Birthdate"} holder={'MM/DD/YYYY'} error={errors.bdate} value={values?.bdate} handleChange={handleChange} touched={touched?.bdate} name={"bdate"} handleBlur={handleBlur}/>
                                <TextFieldInput w={"w-100"} placeholder={"Email"} error={errors.email} touched={touched.email} name={"email"} value={values.email} handleChange={handleChange} handleBlur={handleBlur}/>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className={`${btn_col} col-12 d-flex justify-content-between`}>
                            <NavLink to={HOME_DETAIL} className={'mt-lg-0 mt-4'} style={{ color: "#4a4f53", fontWeight: "600", display: "flex", alignItems: "center", }}>Back</NavLink>
                            <ButtonInput name={"Show my quotes"} type="submit" />
                        </div>
                    </div>
                </form>
                <ToastContainer />
            </div>
        </>
    )
}

export default HomeOwner