import React from 'react'
import { useNavigate } from 'react-router'
import InputButton from '../Input Function/InputButton';
import {DASHBOARD, GOOGLE_SHEET } from '../services/AdminRoutePath';

const Page400 = () => {
    const navigate = useNavigate();
    return (
        <>  <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
            <div className="auth-page-content overflow-hidden p-0">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-8">
                            <div className="text-center">
                                {/* <img src="/assets/images/error400-cover-orange1.png" alt="error img" className="img-fluid" /> */}
                                <div className="mt-3">
                                    <h3 className="text-uppercase">Sorry, User is alreay login!</h3>
                                    {/* <p className="text-muted mb-4">The page you are looking for not available!</p> */}
                                    <InputButton event={() => { navigate(GOOGLE_SHEET) }} className="btn btn-danger" name="Back" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}
export default Page400