import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import RadioInput from "../../../comp/js/RadioInput";
import ButtonInput from "../../../comp/js/ButtonInput";
import "../css/ManualStart.css";
import { useLocation, useNavigate } from "react-router";
import { MANUAL_START_DETAIL } from "../../../services/UserRoutes";
import { AiOutlineCheck } from "react-icons/ai";
import Number from "../../../step/Number";
import SearchSelect from "../../../comp/js/SearchSelect";
import { useDispatch, useSelector } from "react-redux";
import { getColors, saveManualStart } from "../../../../redux/action/UserAction";
import { useContext } from "react";
import { UserValContext } from "../../../context/UserContextProvider";
import { color, que_col,que_value_col,life_event_options, requiredFeild, showError } from '../../../services/Methods';
import { FORM_SETTING } from "../../../../admin/services/AdminRoutePath";


const ManualStart = () => {

  const { setCoverageStep,submitting,dot_color,button_color} = useContext(UserValContext)
  
  const dispatch = useDispatch();
  let details = useSelector((state) => state?.persistedReducer?.userManualStart);

  const numberStyle = { padding: "5px 7px",fontSize:'18px'};

  const [validation, setValidation] = useState(yup.string().required(requiredFeild));
  const [validationObj, setValidationObj] = useState({shopping: validation,rent_home: validation,car_insurance: validation,});

  const naviagte = useNavigate();
  const {pathname} = useLocation()

  const {values,setFieldValue,errors,handleBlur,handleChange,handleSubmit,touched,setValues,} = useFormik({
    initialValues: {
      shopping: details?.shopping ? details?.shopping :pathname == FORM_SETTING ? 'I’m buying a car soon'  :"",
      insured: details?.insured ? details?.insured : pathname == FORM_SETTING ? 'I’ve been deployed overseas' :"",
      rent_home: details?.rent_home ? details?.rent_home : pathname == FORM_SETTING ? 'I own my condo' : "",
      auto_home: details?.auto_home ? details?.auto_home : "",
      car_insurance: details?.car_insurance ? details?.car_insurance :pathname == FORM_SETTING ? 'No' : "",
      life_event: details?.life_event ? details?.life_event : "",
    },
    validationSchema: yup.object().shape(validationObj),
    onSubmit: (values, { resetForm }) => {
      dispatch(saveManualStart(values));
      if (values) naviagte(MANUAL_START_DETAIL);
    },
  });

  const handleRadio = (value, name) => {
    setFieldValue(name, value);
  };

  useEffect(() => {
    if (values["car_insurance"] == "No")
      setValidationObj({ ...validationObj, insured: validation });
    else if (values["car_insurance"] != "No")
      setValidationObj((validationObj) => {
        const newData = { ...validationObj };
        delete newData["insured"];
        return newData;
      });

    if (values["rent_home"] == "I own my home") {

      setValidationObj({ ...validationObj, auto_home: validation });
    }
    else if (values["rent_home"] != "I own my home")
      setValidationObj((validationObj) => {
        const newData = { ...validationObj };
        delete newData["auto_home"];
        return newData;
      });

    if (values["shopping"] == "A life event affected my policy")
      setValidationObj({
        ...validationObj,
        life_event: yup.object().required(requiredFeild),
      });
    else if (values["shopping"] != "A life event affected my policy")
      setValidationObj((validationObj) => {
        const newData = { ...validationObj };
        delete newData["life_event"];
        return newData;
      });

    if (values["auto_home"] && values["auto_home"] == "Not right now") setCoverageStep(false)
    else if (values["auto_home"] == "Yes") setCoverageStep(true)

  }, [values]);

  return (
    <>
      <div
        className="container-fluid w-100 pt-3 g-3 mb-5"
        style={{ paddingTop: "20px" }}
      >
        <form onSubmit={handleSubmit}>
          <div className="row gy-4 mb-4">
            <h2>We’ll make this fast & simple</h2>
          </div>
          <div className="row gy-4">
            <div className={`${que_col} col-12`}>
              <div className="d-flex">
                <Number number={1} value={values.car_insurance} />
                <h3>Do you currently have car insurance?</h3>
              </div>
            </div>
            <div className={`${que_value_col} col-12`}>
              <div
                className="btn-group d-flex flex-row gap-3"
                role="group"
                aria-label="Basic radio toggle button group"
                id="car_insurance"
                name="car_insurance"
                onChange={(e) => handleRadio(e.target.value, "car_insurance")}
                onBlur={handleBlur}
                value={values.car_insurance}
              >
                <RadioInput
                  value={"Yes"}
                  name={"car_insurance"}
                  w={"w-50"}
                  val={values.car_insurance}
                  error={errors.car_insurance}
                  touch={touched.car_insurance}
                  checked={values.car_insurance == "Yes" ? true : false}
                />
                <RadioInput
                  value={"No"}
                  name={"car_insurance"}
                  w={"w-50"}
                  val={values.car_insurance}
                  error={errors.car_insurance}
                  touch={touched.car_insurance}
                  checked={values.car_insurance == "No" ? true : false}
                />
              </div>
              {errors.car_insurance && (<span className="text-danger fs-4">{showError(errors.car_insurance,submitting)}</span>)}
            </div>
          </div>
          {
            <div
              className={`row gy-4 mt-4 ${values.car_insurance == "No" ? "target d-flex" : "target_none d-none"}`}
            >
              <div className={`${que_col} col-12`}>
                <div className="d-flex">
                  <Number number={2} value={values.insured} />
                  <div className="w-100">
                    <h3>Why aren’t you insured?</h3>
                    <span className="text-start w-w-100">
                      There are plenty of reasons—tell us more about yours.
                    </span>
                  </div>
                </div>
              </div>
              <div className={`${que_value_col} col-12`}>
                <div
                  className="btn-group d-flex flex-column gap-3"
                  role="group"
                  aria-label="Basic radio toggle button group"
                  id="insured"
                  name="insured"
                  onChange={(e) => handleRadio(e.target.value, "insured")}
                  onBlur={handleBlur}
                  value={values.insured}
                  key={2}
                >
                  <RadioInput value={"I’ve been deployed overseas"} name={"insured"} w={"w-100"} val={values.insured} error={errors.insured} touch={touched.insured} checked={values.insured == "I’ve been deployed overseas" ? true : false}
                  />
                  <RadioInput value={"I wasn’t required to have insurance"} name={"insured"} w={"w-100"} val={values.insured} error={errors.insured} touch={touched.insured} checked={
                    values.insured ==
                      "I wasn’t required to have insurance"
                      ? true
                      : false
                  }
                  />
                  <RadioInput
                    value={"It ended in the last 31 days"}
                    name={"insured"}
                    w={"w-100"}
                    val={values.insured}
                    error={errors.insured}
                    touch={touched.insured}
                    checked={
                      values.insured == "It ended in the last 31 days"
                        ? true
                        : false
                    }
                  />
                  <RadioInput
                    value={"My policy lapsed"}
                    name={"insured"}
                    w={"w-100"}
                    val={values.insured}
                    error={errors.insured}
                    touch={touched.insured}
                    checked={
                      values.insured == "My policy lapsed" ? true : false
                    }
                  />
                  <RadioInput
                    value={"Other"}
                    name={"insured"}
                    w={"w-100"}
                    val={values.insured}
                    error={errors.insured}
                    touch={touched.insured}
                    checked={values.insured == "Other" ? true : false}
                  />
                </div>
                {errors.insured && (<span className="text-danger fs-4">{showError(errors.insured,submitting)}</span>)}
              </div>
            </div>
          }

          <div className="row gy-4 mt-3">
            <div className={`${que_col} col-12`}>
              <div className="d-flex">
                <span
                  className="me-2 badge border border-user-secondary rounded-circle text-dark"
                  style={{
                    height: "28px",
                    width: "28px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: values.rent_home ? dot_color : "",
                    padding: "2px",
                  }}
                >
                  {values.rent_home ? (
                    <AiOutlineCheck style={{ color: "white", fontSize: "20px" }}/>) : values.car_insurance == "No" ? (
                    <div style={numberStyle}>3</div>) : (<div style={numberStyle}>2</div>)}
                </span>
                <div className="w-100">
                  <h3>Do you own or rent your home?</h3>
                  <span className="text-start w-w-100">
                    Home and condo owners will need higher coverage than
                    renters—they need more financial protection for their
                    assets.
                  </span>
                </div>
              </div>
            </div>
            <div className={`${que_value_col} col-12`}>
              <div
                className="btn-group d-flex flex-column gap-3"
                role="group"
                aria-label="Basic radio toggle button group"
                onChange={(e) => handleRadio(e.target.value, "rent_home")}
                onBlur={handleBlur}
                value={values.rent_home}
              >
                <RadioInput value={"I own my home"} name={"rent_home"} w={"w-100"} val={values.rent_home} error={errors.rent_home} touch={touched.rent_home} checked={
                    values.rent_home == "I own my home" ? true : false
                  }
                />
                <RadioInput value={"I own my condo"} name={"rent_home"} w={"w-100"} val={values.rent_home} error={errors.rent_home} touch={touched.rent_home} checked={values.rent_home == "I own my condo" ? true : false} />
                <RadioInput value={"I rent"} name={"rent_home"} w={"w-100"} val={values.rent_home} error={errors.rent_home} touch={touched.rent_home} checked={values.rent_home == "I rent" ? true : false} />
                <RadioInput value={"Other"} name={"rent_home"} w={"w-100"} val={values.rent_home} error={errors.rent_home} touch={touched.rent_home} checked={values.rent_home == "Other" ? true : false} />
              </div>
              {errors.rent_home && (<span className="text-danger fs-4 fs-4">{showError(errors.rent_home,submitting)}</span>)}
            </div>
          </div>
          <div className={`row gy-4 mt-3 ${values.rent_home == "I own my home" ? "target d-flex" : "target_none d-none"}`}>
            <div className={`${que_col} col-12`}>
              <div className="d-flex">
                <span
                  className="me-2 badge border border-user-secondary rounded-circle text-dark"
                  style={{height: "28px",width: "28px",display: "flex",justifyContent: "center",alignItems: "center",backgroundColor: values.auto_home ? dot_color : "",padding: "2px",
                  }}
                >
                  {values.auto_home ? (<AiOutlineCheck style={{ color: "white", fontSize: "20px" }}/>) : values.car_insurance == "No" && values.rent_home == 'I own my home'? (<div style={numberStyle}>4</div>) : values.car_insurance == "No" ? <div style={numberStyle}>3</div> : (<div style={numberStyle}>3</div>)}
                </span>
                <div className="w-100">
                  <h3>
                    Do you want to bundle Auto + Home insurance for bigger
                    savings?
                  </h3>
                  <span className="text-start w-100">
                    We’ll focus on your auto details first, then talk about your
                    home
                  </span>
                </div>
              </div>
            </div>
            <div className={`${que_value_col} col-12`}>
              <div
                className="btn-group d-flex flex-row gap-3"
                role="group"
                aria-label="Basic radio toggle button group"
                id="auto_home"
                name="auto_home"
                onChange={(e) => handleRadio(e.target.value, "auto_home")}
                onBlur={handleBlur}
                value={values.auto_home}
              >
                <RadioInput
                  value={"Yes"}
                  name={"auto_home"}
                  w={"w-50"}
                  val={values.auto_home}
                  error={errors.auto_home}
                  touch={touched.auto_home}
                  checked={values.auto_home == "Yes" ? true : false}
                />
                <RadioInput
                  value={"Not right now"}
                  name={"auto_home"}
                  w={"w-50"}
                  val={values.auto_home}
                  error={errors.auto_home}
                  touch={touched.auto_home}
                  checked={
                    values.auto_home == "Not right now" ? true : false
                  }
                />
              </div>
              {errors.auto_home && touched.auto_home && (<span className="text-danger fs-4">{showError(errors.auto_home,submitting)}</span>)}
            </div>
          </div>

          <div className="row gy-4 mt-3">
            <div className={`${que_col} col-12`}>
              <div className="d-flex">
                <span
                  className="me-2 badge border border-user-secondary rounded-circle text-dark"
                  style={{height: "28px",width: "28px",display: "flex",justifyContent: "center",alignItems: "center",backgroundColor: values.shopping ? dot_color : "",padding: "2px",
                  }}
                >
                  {values?.shopping ? (<AiOutlineCheck style={{ color: "white", fontSize: "20px" }}/>) : (
                    (!values?.shopping && values.car_insurance == "No" &&
                      values.rent_home == "I own my home") ? ('5') : (values.car_insurance == "No" || values.rent_home == "I own my home") ? (<div style={numberStyle}>4</div>) : (
                      <div style={numberStyle}>3</div>
                    ))}

                  {}
                </span>
                <div className="w-w-100">
                  <h3>Why are you shopping for insurance today?</h3>
                </div>
              </div>
            </div>
            <div className={`${que_value_col} col-12`}>
              <div
                className="btn-group d-flex flex-column gap-3"
                role="group"
                aria-label="Basic radio toggle button group"
                onChange={(e) => handleRadio(e.target.value, "shopping")}
                onBlur={handleBlur}
                value={values.shopping}
              >
                <RadioInput
                  value={"I think I’m paying too much"}
                  name={"shopping"}
                  w={"w-100"}
                  val={values.shopping}
                  error={errors.shopping}
                  touch={touched.shopping}
                  checked={
                    values.shopping == "I think I’m paying too much" ? true : false
                  }
                />
                <RadioInput
                  value={"I’m buying a car soon"}
                  name={"shopping"}
                  w={"w-100"}
                  val={values.shopping}
                  error={errors.shopping}
                  touch={touched.shopping}
                  checked={
                    values.shopping == "I’m buying a car soon" ? true : false
                  }
                />
                <RadioInput
                  value={"A life event affected my policy"}
                  name={"shopping"}
                  w={"w-100"}
                  val={values.shopping}
                  error={errors.shopping}
                  touch={touched.shopping}
                  checked={
                    values.shopping == "A life event affected my policy" ? true : false
                  }
                />
                <RadioInput
                  value={"I’m just looking at my options"}
                  name={"shopping"}
                  w={"w-100"}
                  val={values.shopping}
                  error={errors.shopping}
                  touch={touched.shopping}
                  checked={
                    values.shopping == "I’m just looking at my options" ? true : false
                  }
                />
              </div>
              {errors.shopping && touched.shopping && (<span className="text-danger fs-4">{showError(errors.shopping,submitting)}</span>)}
            </div>
          </div>

          {
            <div className={`row gy-4 mt-4 ${values.shopping == "A life event affected my policy" ? "target d-flex" : "target_none d-none"}`}>
              <div className={`${que_col} col-12`}>
                <div className="d-flex">
                  <span
                    className="me-2 badge border border-user-secondary rounded-circle text-dark"
                    style={{ height: "28px", width: "28px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: values.life_event ? dot_color : "", padding: "2px",
                    }}
                  >
                    {values.life_event ? (
                      <AiOutlineCheck
                        style={{ color: "white", fontSize: "20px" }}
                      />
                    ) : values.car_insurance == "No" && values.rent_home == 'I own my home'? (
                      <div style={numberStyle}>6</div>
                    ) : values.car_insurance == "No" ? <div style={numberStyle}>5</div> : (
                      <div style={numberStyle}>4</div>
                    )}
                  </span>
                  <div className="w-100">
                    <h3>What life event affected your current policy?</h3>
                    <span className="text-start w-w-100">
                      Insurance companies use this information for your quotes.
                    </span>
                  </div>
                </div>
              </div>
              <div className={`${que_value_col} col-12`}>
                <SearchSelect w={"w-100"} placeholder={"Select life event"} isSearchable={true} name={"life_event"} value={values.life_event} error={errors.life_event} touched={touched.life_event} handleChange={handleChange} handleBlur={handleBlur} setFieldValue={setFieldValue} opt={life_event_options}
                />
                {errors.life_event && touched.life_event && (<span className="text-danger fs-4">{showError(errors.life_event,submitting)}</span>)}
              </div>
            </div>
          }

          <div className="row">
          {/* col-lg-10 */}
            <div className=" col-12 gy-3">
              <p className="mt-4 footer-text text-justify">
                By clicking "Save & continue," you consent to Insure the future saving
                the information you entered and sharing it with insurance
                carriers and other insurance professionals so you can get the
                most up-to-date quotes, no matter what device you're using.
                Additionally, carriers and insurance professionals may use this
                to obtain information about your credit history. You also agree
                to Insure the future's Privacy Policy and Terms of Service.
              </p>
            </div>
            {/* offset-lg-1 */}
            <div className="col-6 gy-3 offset-3 w-75 w-lg-auto d-flex justify-content-end text-end ">
              <ButtonInput name={"Save & continue"} type={"submit"} errors={errors}/>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ManualStart;
