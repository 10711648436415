import React, { useContext, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import RadioInput from "../../../comp/js/RadioInput";
import TextFieldInput from "../../../comp/js/TextFieldInput";
import SearchSelect from "../../../comp/js/SearchSelect";
import { DRIVER_DETAIL, VEHICLE_SELECT } from "../../../services/UserRoutes";
import { NavLink, useNavigate } from "react-router-dom";
import ButtonInput from "../../../comp/js/ButtonInput";
import { AiOutlineCheck } from 'react-icons/ai'
import { color,que_col,que_value_col, frequency, requiredFeild } from "../../../services/Methods";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { saveVehicleDetail } from "../../../../redux/action/UserAction";
import { UserValContext } from "../../../context/UserContextProvider";

const VehicleDetail = () => {

  const {button_color,dot_color} = useContext(UserValContext)
  const dispatch = useDispatch();
  let details = useSelector((state) => state.persistedReducer?.userVehicleDetail);
  let selectedVehicles = useSelector((state) => state.persistedReducer?.userVehicleSelect);

  const [vehicles, setVehivles] = useState(Object.keys(selectedVehicles).length > 0 && selectedVehicles?.checkArray ? [1, ...selectedVehicles?.checkArray] : [1]);

  let val = {}
  Array.from({ length: vehicles?.length }, (_, i) => i + 1).map((ind) => {
    val[`own_vehicle${ind}`] = ''
    val[`use_vehicle${ind}`] = ''
    val[`mileage${ind}`] = ''
    val[`frequency${ind}`] = ''
  })

  const [valObj, setValObj] = useState(val);
  const [schemaObj, setSchemaObj] = useState({
    own_vehicle1: yup.string().required(requiredFeild),
    use_vehicle1: yup.string().required(requiredFeild),
    mileage1: yup.number().typeError("Please enter a valid number").required(requiredFeild).min(0, "Daily mileage should be equal to or less than 275 miles").max(275, "Daily mileage should be equal to or less than 275 miles"),
    frequency1: yup.object().required(requiredFeild),
  });

  const numberStyle = { padding: '5px 7px' ,fontSize:'18px'}
  const navigate = useNavigate();

  const handleValues = () => {
    if (Object.keys(details)?.length > 0) {
      // setValObj(details) ; 
      return details
    }
    else if (Object.keys(details)?.length < 1) {
      let val = valObj

      Array.from({ length: vehicles.length }, (_, i) => i + 1).map((ind) => {
        val[`own_vehicle${ind}`] = ''
        val[`use_vehicle${ind}`] = ''
        val[`mileage${ind}`] = ''
        val[`frequency${ind}`] = ''

      });
      return val
    }
  }

  const handleSchema = () => {
    if (Object.keys(details).length < 1) {
      let schema = schemaObj
      Array.from({ length: vehicles.length }, (_, i) => i + 1).map((ind) => {
        schema[`own_vehicle${ind}`] = yup.string().required(requiredFeild)
        schema[`use_vehicle${ind}`] = yup.string().required(requiredFeild)
        schema[`mileage${ind}`] = yup.number().typeError("Please enter a valid number").required(requiredFeild).min(0, "Daily mileage should be equal to or less than 275 miles").max(275, "Daily mileage should be equal to or less than 275 miles")
        schema[`frequency${ind}`] = yup.object().required(requiredFeild)
      });
      return schema
    }
  }

  const { values, setFieldValue, errors, setErrors, handleBlur, handleChange, handleSubmit, touched, setValues, } = useFormik({
    initialValues: handleValues(),
    validationSchema: yup.object().shape(handleSchema()),
    onSubmit: (values, { resetForm }) => {

      // two forms data object
      let vehicle_obj = Array.from({ length: selectedVehicles?.checkArray?.length + 1 }, (v, i) => i + 1).map((v, index) => {
        return { year: selectedVehicles[`vehicle${v}0`]?.value, make: selectedVehicles[`vehicle${v}1`]?.value, model: selectedVehicles[`vehicle${v}2`]?.value, trime_name: selectedVehicles[`vehicle${v}3`]?.value, own_lease: values[`own_vehicle${v}`], mostly_used: values[`use_vehicle${v}`], miles_feq: values[`frequency${v}`]?.value, miles_drive: values[`mileage${v}`] }
      });

      dispatch(saveVehicleDetail(values))
      if (values) navigate(DRIVER_DETAIL)
    },
  });

  return (
    <>
      <div className="container-fluid mt-2 g-3 mb-5">
        <div className="row gy-4">
          <h2>Tell us about your vehicles</h2>
        </div>
        <form onSubmit={handleSubmit}>
          {Array.from({ length: vehicles?.length }, (_, i) => i + 1).map((v, inedx) => {
            return (
              <>
                <div className="row">
                  <div className="col-12">
                    <h6 className="mt-4">
                      ( vehicle {v} of {vehicles?.length})
                    </h6>
                    <h2 className="d-flex align-items-center gy-4">
                      {(selectedVehicles && selectedVehicles[`vehicle${v}1`]?.image == ' ') ? null : <img className="me-2 rounded-circle" src={`${selectedVehicles[`vehicle${v}1`]?.image}`} alt="image" style={{ height: '35px', width: '35px' }} />}
                      {selectedVehicles[`vehicle${v}0`]?.value && <span className="me-2">{selectedVehicles[`vehicle${v}0`]?.value}</span>}
                      {selectedVehicles[`vehicle${v}1`]?.value && <span className="me-2">{selectedVehicles[`vehicle${v}1`]?.value}</span>}
                      {selectedVehicles[`vehicle${v}2`]?.value && <span className="me-2">{selectedVehicles[`vehicle${v}2`]?.value}</span>}
                    </h2>
                  </div>
                </div>
                <div className="row gy-4 mt-3">
                  <div className={`${que_col} col-12`}>
                    <div className="d-flex">
                      <span
                        className="me-2 badge border border-user-secondary rounded-circle text-dark"
                        style={{
                          height: "28px",
                          width: "28px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: values[`own_vehicle${v}`]
                            ? dot_color
                            : "",
                          padding: "2px",
                        }}
                      >
                        {values[`own_vehicle${v}`] ? (
                          <AiOutlineCheck
                            style={{ color: "white", fontSize: "20px" }}
                          />
                        ) : (
                          <div style={numberStyle}>1</div>
                        )}
                      </span>
                      <div className="w-100">
                        <h3>Do you own or lease this vehicle?</h3>
                        <span className="text-start w-w-100">
                          Drivers making payments or leasing their vehicle
                          usually need more coverage than people who own their
                          vehicle outright.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={`${que_value_col} col-12`}>
                    <div
                      className="btn-group d-flex flex-column gap-3"
                      role="group"
                      aria-label="Basic radio toggle button group"
                      id={`own_vehicle${v}`}
                      name={`own_vehicle${v}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[`own_vehicle${v}`]}
                    >
                      <RadioInput
                        value={"Own—paid in full"}
                        name={`own_vehicle${v}`}
                        w={"w-100"}
                        val={values[`own_vehicle${v}`]}
                        error={errors[`own_vehicle${v}`]}
                        touch={touched[`own_vehicle${v}`]}
                        checked={
                          values[`own_vehicle${v}`] == "Own—paid in full"
                            ? true
                            : false
                        }
                      />
                      <RadioInput
                        value={"Own—making payments"}
                        name={`own_vehicle${v}`}
                        w={"w-100"}
                        val={values[`own_vehicle${v}`]}
                        error={errors[`own_vehicle${v}`]}
                        touch={touched[`own_vehicle${v}`]}
                        checked={
                          values[`own_vehicle${v}`] == "Own—making payments"
                            ? true
                            : false
                        }
                      />
                      <RadioInput
                        value={"Lease"}
                        name={`own_vehicle${v}`}
                        w={"w-100"}
                        val={values[`own_vehicle${v}`]}
                        error={errors[`own_vehicle${v}`]}
                        touch={touched[`own_vehicle${v}`]}
                        checked={values[`own_vehicle${v}`] == "Lease" ? true : false}
                      />
                    </div>
                    {errors[`own_vehicle${v}`] && touched[`own_vehicle${v}`] && (
                      <span className="text-danger fs-4">
                        {errors[`own_vehicle${v}`]}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row gy-4 mt-3">
                  <div className={`${que_col} col-12`}>
                    <div className="d-flex">
                      <span
                        className="me-2 badge border border-user-secondary rounded-circle text-dark"
                        style={{
                          height: "28px",
                          width: "28px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: values[`use_vehicle${v}`]
                            ? dot_color
                            : "",
                          padding: "2px",
                        }}
                      >
                        {values[`use_vehicle${v}`] ? (
                          <AiOutlineCheck
                            style={{ color: "white", fontSize: "20px" }}
                          />
                        ) : (
                          <div style={numberStyle}>2</div>
                        )}
                      </span>
                      <div className="w-100">
                        <h3>What do you mostly use it for?</h3>
                        <span className="text-start w-w-100">
                          Most drivers choose "Personal/commuting."
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={`${que_value_col} col-12`}>
                    <div
                      className="btn-group d-flex flex-column gap-3"
                      role="group"
                      aria-label="Basic radio toggle button group"
                      id={`use_vehicle${v}`}
                      name={`use_vehicle${v}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[`use_vehicle${v}`]}
                    >
                      <RadioInput
                        value={"Personal/commuting"}
                        name={`use_vehicle${v}`}
                        w={"w-100"}
                        val={values[`use_vehicle${v}`]}
                        error={errors[`use_vehicle${v}`]}
                        touch={touched[`use_vehicle${v}`]}
                        checked={
                          values[`use_vehicle${v}`] == "Personal/commuting"
                            ? true
                            : false
                        }
                      />
                      <RadioInput
                        value={"Pleasure"}
                        name={`use_vehicle${v}`}
                        w={"w-100"}
                        val={values[`use_vehicle${v}`]}
                        error={errors[`use_vehicle${v}`]}
                        touch={touched[`use_vehicle${v}`]}
                        checked={
                          values[`use_vehicle${v}`] == "Pleasure" ? true : false
                        }
                      />
                      <RadioInput
                        value={"Farm"}
                        name={`use_vehicle${v}`}
                        w={"w-100"}
                        val={values[`use_vehicle${v}`]}
                        error={errors[`use_vehicle${v}`]}
                        touch={touched[`use_vehicle${v}`]}
                        checked={values[`use_vehicle${v}`] == "Farm" ? true : false}
                      />
                      <RadioInput
                        value={"Business/rideshare"}
                        name={`use_vehicle${v}`}
                        w={"w-100"}
                        val={values[`use_vehicle${v}`]}
                        error={errors[`use_vehicle${v}`]}
                        touch={touched[`use_vehicle${v}`]}
                        checked={
                          values[`use_vehicle${v}`] == "Business/rideshare"
                            ? true
                            : false
                        }
                      />
                    </div>
                    {errors[`use_vehicle${v}`] && touched[`use_vehicle${v}`] && (
                      <span className="text-danger fs-4">
                        {errors[`use_vehicle${v}`]}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row gy-4 mt-3">
                  <div className={`${que_col} col-12`}>
                    <div className="d-flex">
                      <span
                        className="me-2 badge border border-user-secondary rounded-circle text-dark"
                        style={{
                          height: "28px",
                          width: "28px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor:
                            values[`mileage${v}`] && values[`frequency${v}`]
                              ? dot_color
                              : "",
                          padding: "2px",
                        }}
                      >
                        {values[`mileage${v}`] && values[`frequency${v}`] ? (
                          <AiOutlineCheck
                            style={{ color: "white", fontSize: "20px" }}
                          />
                        ) : (
                          <div style={numberStyle}>3</div>
                        )}
                      </span>
                      <div className="w-100">
                        <h3>How many miles do you drive?</h3>
                        <span className="text-start w-w-100">
                          Most drivers average 30 miles per day.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={`${que_value_col} col-12`}>
                    <div
                      className="btn-group d-flex flex-md-row flex-column gap-3"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <div className="d-flex flex-column w-100">
                        <SearchSelect
                          isSearchable={false}
                          isDisabled={false}
                          w={"w-100"}
                          placeholder={"Select frequency"}
                          name={`frequency${v}`}
                          value={values[`frequency${v}`]}
                          error={errors[`frequency${v}`]}
                          touched={touched[`frequency${v}`]}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          opt={frequency}
                        />
                      </div>
                      <div className="d-flex flex-column w-100">
                        <TextFieldInput
                          placeholder={"Estimated mileage"}
                          error={errors[`mileage${v}`]}
                          touched={touched[`mileage${v}`]}
                          name={`mileage${v}`}
                          value={values[`mileage${v}`]}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}

          <div className="row">
          {/* col-lg-10 */}
            <div className="gy-5 col-12 d-flex justify-content-between">
              <NavLink className={'mt-lg-0 mt-4'} to={VEHICLE_SELECT} style={{color: "#4a4f53",fontWeight: "600",display: "flex",alignItems: "center",}}>Back</NavLink>
              <ButtonInput name={"Save & continue"} type="submit" errors={errors}/>
            </div>
          </div>

        </form>
      </div>
    </>
  );
};

export default VehicleDetail;