import React from 'react'
import {BsCheckLg} from 'react-icons/bs'

const ResultRadioCard = ({ values, condition,id,name,checked,radioValue,setRadioValue}) => {

    let cost = values[0].split('/')
    return (
        <>
            <label htmlFor={name} className={`custom-radio d-block my-3 ${radioValue == name ? 'is-selected' :''}`} onClick={()=>setRadioValue(name)}>
                <input type="radio" id={id} defaultValue="minimum" className="coverage-card-container d-none d-md-block" value={radioValue}
                    name={name}
                    checked={checked}
                    // onChange={()=>setRadioValue(name)}
                />
                <div className="radio-wrap">
                    <div className="d-flex justify-content-between">
                        <div className="text-lg text-dark-black radio-title mb-1">{condition}</div>
                        <div className="radio-control d-flex justify-content-center align-items-center">{name == radioValue && <BsCheckLg className='text-white'/>}
                        </div>
                    </div>
                    <p className="text-muted text-sm">Paying less is your biggest priority</p>
                    <div className="text-muted text-sm">Bodily Injury</div>
                    <p className="text-bold">{cost.at(0)}<span className="strong">/</span>{cost.at(-1)}</p>
                    <div className="text-muted text-sm">Comprehensive + Collison deductible</div><div className="text-bold">{values[1]}</div>
                    <div className="mt-05 d-md-none">
                        <button className="primary-button primary-button--purple primary-button--small primary-button--square primary-button--full-width primary-button--black" type="button">Select Coverage</button>
                    </div>
                </div>
            </label>
        </>
    )
}

export default ResultRadioCard