import { backend_url } from "./Methods"

// export const START = '/insurance/car/prefill/start/'
export const MANUAL_START = '/'
export const MANUAL_START_DETAIL = '/insurance/car/prefill/start/details/'
export const VEHICLE_SELECT = '/insurance/car/prefill/vehicles/select/'
export const VEHICLE_DETAIL = '/insurance/car/prefill/vehicles/details/'
export const DRIVER_DETAIL = '/insurance/car/prefill/drivers/details/'
export const COVERAGE_SELECTION = '/insurance/car/prefill/coverage-selection/'
export const HOME_START = '/insurance/home/start/'
export const HOME_DETAIL = '/insurance/home/details/'
export const HOME_OWNER = '/insurance/home/owner/'
export const RESULT = '/insurance/car/results/'
export const THANKYOU = '/insurance/car/end/'

export const BRAND_IMAGE_PATH = `${backend_url}images/brand/`
export const INSURANCE_COMPANY_IMAGE_PATH = `${backend_url}images/insurCompany/`
export const GOOGLE_API_KEY = 'AIzaSyCTDiHt3vQPfzaHTLOeXeqZrSc3_7ukXew'
