import React ,{createContext,useState} from 'react';

export const AdminValAuthContext = createContext();

const AdminAuthProvider = ({children})=>{
    const [fontColor, setFontColor] = useState();
    const [btnColor, setBtnColor] = useState();
    const [bgColor, setbgColor] = useState();
    const [dotColor,setDotColor]=useState();
    const [color, setColor] = useState(fontColor);
    const [color1, setColor1] = useState(btnColor);
    const [color2, setColor2] = useState(bgColor);
    const [color3, setColor3] = useState(dotColor);
    const [showSidebar,setShowSidebar]=useState(true);
    const [showSidebarSm,setShowSidebarSm]=useState(false);
    const [showModel,setShowModel]=useState();
    const [hideSelectModel,setHideSelectModel]=useState(true);
    const [hideSelectTrim,setHideSelectTrim]=useState(true);
    const [hideSelectFromPage,setHideSelectFromPage]=useState(true);
    const [selectedSideMenu,setSelectedSideMenu]=useState();
    return <AdminValAuthContext.Provider value={{showSidebar,setShowSidebar,showModel,setShowModel,hideSelectModel,setHideSelectModel,setHideSelectTrim,hideSelectTrim,selectedSideMenu,setSelectedSideMenu,showSidebarSm,setShowSidebarSm,hideSelectFromPage,setHideSelectFromPage,fontColor, setFontColor,btnColor, setBtnColor,bgColor, setbgColor,dotColor,setDotColor,color, setColor,color1, setColor1,color2, setColor2,color3, setColor3}}>{children}</AdminValAuthContext.Provider>
}
export default AdminAuthProvider


