
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Select from 'react-select';
import { getBrands, getBrandToModel, getDatas, getGoogleSheets, getModels, getModelToTrimName, getPageToQue, getQueToVal, getTrimName, getValToSQue, GET_BRANDS } from '../../redux/action/AdminAction';
import AdminAuthProvider, { AdminValAuthContext } from '../context/AdminAuthProvider';
import "../css/InputSelectBox.css"
import { ACTIVE_CAMPAIGN, ALL_BRANDS, ALL_MODELS, ALL_TRIMNAME, ALL_VEHICLE, FORM_SUB_QUESTION, FORM_SUB_VALUE, FORM_VALUE, GOOGLE_SHEET } from '../services/AdminRoutePath';
const InputSelectBox = ({ className, name, error, touch, value, handleBlur, handleChange, placeholder, setFieldValue, action ,opt,brand,title,opt2}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const val = useContext(AdminValAuthContext);
  let load = false;

  const [editPlaceholder, setEditPlaceholder] = useState()
  const [yearArray, setYearArray] = useState([])
  const [loading,setLoading]=useState(false);

  function handleSelect(data) {
    if (name == "brandName") {
      val?.setHideSelectModel(false);
      dispatch(getBrandToModel(data?.value))
      if (pathname == ALL_TRIMNAME || pathname == ALL_VEHICLE) {
        setFieldValue('modelName', '')
        if (action == 'edit') {
          setEditPlaceholder('Select...')
        }
      }
    }
    else if (name == "modelName") {
      val?.setHideSelectTrim(false);
      dispatch(getModelToTrimName(data?.value))
      if (pathname == (ALL_VEHICLE)) {
        setFieldValue('trimName', '')
        if (action == 'edit') {
          setEditPlaceholder('Select...')
        }
      }
    }
    setFieldValue(`${name}`, data)

  }



  // const brandList = useSelector((state) => state.AdminReducer.brandList);
  const brandToModelList = useSelector((state) => state.AdminReducer.brandToModelList);
  const modelToTrimNameList = useSelector((state) => state.AdminReducer.modelToTrimNameList);

  // const brand = brandList?.map((b) => {
  //   return { value: b?.id, label: b?.brand_name }
  // })

  const brandToModel = brandToModelList?.map((b) => {
    return { value: b?.id, label: b?.model_name }
  })
  const modelToTrimName = modelToTrimNameList?.map((b) => {
    return { value: b?.id, label: b?.name }
  })
  const year = [];
  useEffect(() => {
    for (var i = 1981; i <= new Date().getFullYear(); i++) {
      year.push(i)
    }
    setYearArray(year)
  }, [])
  const year_final = yearArray?.map((b) => {
    return { value: b, label: b }
  })

  const trans_code = [
    { value: "AUTOMATIC", label: "AUTOMATIC" },
    { value: "MANUAL", label: "MANUAL" }
  ]

  function handleIntigrationSelect(data) {
    if (name == "page_id") {
      val?.setHideSelectFromPage(false);
      dispatch(getPageToQue(data?.value))
      setFieldValue('ques_id', '')
    }
    else if (name == "ques_id") {
      val?.setHideSelectModel(false);
      dispatch(getQueToVal(data?.value))
      setFieldValue('q_v_id', '')
    }
    else if(name == "q_v_id")
    {
      val?.setHideSelectTrim(false);
      dispatch(getValToSQue(data?.value))
      setFieldValue('sq_id', '')
    }
    setFieldValue(`${name}`, data)
  }
  const pageToQueList = useSelector((state) => state.AdminReducer.pageToQueList);
  const pageToQue= pageToQueList?.map((b) => {
    return { value: b?.id, label: b?.q_title }
  })
  const queToValList = useSelector((state) => state.AdminReducer.queToValList);
  const queToVal = queToValList?.map((b) => {
    return { value: b?.id, label: b?.q_value }
  })
  const valToSQueList = useSelector((state) => state.AdminReducer.valToSQueList);
  const valToSQue = valToSQueList?.map((b) => {
    return { value: b?.id, label: b?.s_title }
  })
  return (
    <>


      <div className='admin-select'>
         { (title == 'user')  ||( (pathname == ACTIVE_CAMPAIGN) || (pathname == FORM_VALUE) || (pathname == FORM_SUB_QUESTION) || (pathname == FORM_SUB_VALUE))  ? <Select
          name={name}
          onChange={handleIntigrationSelect}
          onBlur={handleBlur}    
          options={name == "ques_id" ? pageToQue :name == "q_v_id" ? queToVal :name == "sq_id" ? valToSQue: opt}
          value={value}
          isSearchable={true}
          isMulti={false}
          placeholder={action == 'edit' ? editPlaceholder : placeholder}
          isDisabled={(name == "ques_id" || name == "q_v_id" || name == "sq_id") ? (name == 'ques_id' ? val?.hideSelectFromPage: name == 'q_v_id' ? val?.hideSelectModel :name == 'sq_id' ? val?.hideSelectTrim : null) : false}
          className={classNames(`${className} ${(name == 'ques_id' ? val?.hideSelectFromPage :name == 'q_v_id' ? val?.hideSelectModel : name == 'sq_id' ? val?.hideSelectTrim:null) && (name == 'ques_id' ||name == "q_v_id" || name == "sq_id") &&  action == 'edit' ? 'select-disable' : ''}`, { 'is-invalid': error && touch })}
        /> : <Select
          name={name}
          placeholder={action == 'edit' ? editPlaceholder : placeholder}
          onChange={handleSelect}
          onBlur={handleBlur}
          // options={name == "brandName" ? brand : name == "modelName" ? brandToModel?.length === 0 ? [{ label: "-", value: 0 }] : brandToModel : name == "trimName" ? trim : null}
          options={name == "brandName" ? brand : name == "modelName" ? brandToModel : name == "trimName" ? modelToTrimName : name == "year" ? year_final : name == "transmissionCode" ? trans_code : null}
          value={value}
          isSearchable={true}
          isMulti={false}
          isDisabled={(name == "modelName" || name == "trimName") ? (name == 'modelName' ? val?.hideSelectModel : name == 'trimName' ? val?.hideSelectTrim : null) : false}
          className={classNames(`${className} ${(name == 'modelName' ? val?.hideSelectModel : name == 'trimName' ? val?.hideSelectTrim : null) && (name == "modelName" || name == "trimName") && (action == 'add' || action == 'edit') ? 'select-disable' : ''}`, { 'is-invalid': error && touch })}
        />}
       
      </div>
      {error && touch ? (<span className='text-danger'>{error}</span>) : null}

    </>
  )
}

export default InputSelectBox
