import React, { useContext, useRef } from "react";
import ClassNames from 'classnames';
import { useLocation } from "react-router";
import { HOME_DETAIL } from "../../services/UserRoutes";
import { UserValContext } from "../../context/UserContextProvider";
import $ from 'jquery';


const TextFieldInput = ({ w, placeholder, name, error, touched, value, handleChange, handleBlur, type, min, maxlength, pattern, holder }) => {
  const { pathname } = useLocation();
  const { submitting } = useContext(UserValContext)

  $(document).ready(function () {
    $('.date').mask('00/00/0000');
    if(name == 'buy_home') $(`.${name}`).mask('00/0000');
  });

  return (
    <>
      <div className="user-input">
        <div className="form-floating">
          <input
            className={ClassNames(`${type == 'tel' && pathname != HOME_DETAIL ? 'date' : name == 'buy_home' && `${name}`} form-control form-control-md  ${w}`, { 'is-invalid': type == 'tel' ? error && submitting : submitting && error && (value == '' || !value) })}
            type={type ? type : 'text'}
            max={type == 'date' ? "9999-12-31" : ''}
            maxLength={maxlength}
            pattern={pattern}
            value={value}
            name={name}
            placeholder={holder ? holder : ' '}
            onChange={handleChange}
            onBlur={handleBlur}
            aria-label=".form-control-md example"
            id={name}
          />
          <label htmlFor={name}>{placeholder}</label>
        </div>

        {/* <input
          style={{ paddingTop: '0.8rem', paddingBottom: '0.8rem' }}
          className={ClassNames(`form-control form-control-md  ${w}`, { 'is-invalid': submitting && error && (value != '' || !value)})}
          type={type ? type : 'text'}
          max={type == 'date' ? "9999-12-31" : ''}
          maxLength={maxlength}
          pattern={pattern}
          value={value}
          name={name}
          placeholder={!value && placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
          aria-label=".form-control-md example"
        /> */}
      </div>
      
      {/* {type == 'tel' && error && <span className=" text-danger fs-4">{error}</span>}
      {type != 'tel' && (value == '' || !value) && submitting && error && <span className=" text-danger fs-4">{error}</span>} */}

      {((type == 'tel' || type=='number') && error && submitting) ? <span className=" text-danger fs-4">{error}</span> : ((value == '' || !value) && submitting && error && <span className=" text-danger fs-4">{error}</span>)}
    </>
  );
};

export default TextFieldInput;
