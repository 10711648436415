import React, { useContext } from 'react'
import {AiOutlineCheck} from 'react-icons/ai'
import { color } from '../services/Methods'
import { UserValContext } from '../context/UserContextProvider'

const Number = ({number,value}) => {
    const numberStyle = {padding:'5px 7px',fontSize:'18px'}

    const {button_color,dot_color} = useContext(UserValContext)

    return (

        <span
            className="me-2 badge border border-user-secondary rounded-circle text-dark"
            style={{  height:'28px' , width: '28px' , display: 'flex', justifyContent: 'center',alignItems: 'center',backgroundColor: value ? dot_color : '', padding: '2px' }}
        >
            {value ? <AiOutlineCheck style={{ color: 'white', fontSize: '20px'}} /> : <div style={numberStyle}>{number}</div>}
        </span>
    )
}

export default Number