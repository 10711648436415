import React from 'react'

export const AdminLoginBg = () => {
    return (
        <>
            <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
                <div className="bg-overlay" />
                <div className="shape">
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
                        <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z" />
                    </svg>
                </div>
            </div>
        </>
    )
}
export const AdminLoginText = () => {
    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="text-center mt-sm-5 mb-4 text-white-50">
                        <div>
                            <div className="d-inline-block auth-logo">
                                <img src="/assets/images/logo-orange.png" height={55} className={`logo-image-color`}/>
                            </div>
                        </div>
                        <p className="mt-3 fs-17 fw-medium font-monospace">Welcome to the Insure the Future</p>
                        {/* <p className="mt-3 fs-15 fw-medium">Premium Admin &amp; Dashboard Template</p> */}
                    </div>
                </div>
            </div>
        </>
    )
}
