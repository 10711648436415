import React, { useContext, useState } from "react";
import Header from "./Header";
import { useLocation } from "react-router";
import ManualStart from "../forms/car/js/ManualStart";
import {
  COVERAGE_SELECTION,
  DRIVER_DETAIL,
  HOME_DETAIL,
  HOME_OWNER,
  HOME_START,
  MANUAL_START,
  MANUAL_START_DETAIL,
  RESULT,
  THANKYOU,
  VEHICLE_DETAIL,
  VEHICLE_SELECT,
} from "../services/UserRoutes";
import ManualStartDetail from "../forms/car/js/ManualStartDetail";
import VehicleSelect from "../forms/car/js/VehicleSelect";
import VehicleDetail from "../forms/car/js/VehicleDetail";
import DriverDetails from "../forms/car/js/DriverDetails";
import Coverage from "../forms/car/js/Coverage";
import MultiStep from "../step/MultiStep";
import HomeStart from "../forms/home/HomeStart";
import HomeDetail from "../forms/home/HomeDetail";
import HomeOwner from "../forms/home/HomeOwner";
import Result from "../forms/car/js/Result";
import ThankYouPage from "./ThankYouPage";
import { UserValContext } from "../context/UserContextProvider";
import MultiStepLoader from "../step/MultiStepLoader";

const Home = () => {
  const [pages, setPages] = useState([
    { path: MANUAL_START, comp: <ManualStart /> },
    { path: MANUAL_START_DETAIL, comp: <ManualStartDetail /> },
    { path: VEHICLE_SELECT, comp: <VehicleSelect /> },
    { path: VEHICLE_DETAIL, comp: <VehicleDetail /> },
    { path: DRIVER_DETAIL, comp: <DriverDetails /> },
    { path: COVERAGE_SELECTION, comp: <Coverage /> },
    { path: HOME_START, comp: <HomeStart /> },
    { path: HOME_DETAIL, comp: <HomeDetail /> },
    { path: HOME_OWNER, comp: <HomeOwner /> },
    { path: RESULT, comp: <Result /> },
    { path: THANKYOU, comp: <ThankYouPage /> },
  ]);

  const { pathname } = useLocation();
  const { bg_color } = useContext(UserValContext)

  return (
    <div style={{ backgroundColor: bg_color, minHeight: '100vh', height: '100%' }}>
      <Header />
      <div className="container-fluid container-content" style={{ paddingTop: '120px',paddingBottom:'20px' }}>
        {/* <div className="row">
          {(pathname == RESULT || pathname == THANKYOU) ? null : <div className="col-6 col-lg-2 d-lg-block d-none" style={{ marginTop: '100px' }}><MultiStep /></div>}
          <div className={`col-12 pt-3 ${pathname == THANKYOU ? 'col-lg-12' : 'col-lg-10'}`}>
            {pages.map((val, index) => <span key={index} className="user_form">{(pathname == val.path ? val.comp : null)}</span>)}
          </div>
        </div> */}
        <div className="row">
          {(pathname == RESULT || pathname == THANKYOU) ? null : <div className="col-12 d-lg-flex d-none justify-content-center align-items-center" style={{ marginTop: '60px' }}>
            <MultiStepLoader />
          </div>}
        </div>
        <div className="row justify-content-center align-items-center" style={{width:'inherit'}}>
          <div className={`gy-lg-5 pt-lg-5 pt-2 ${pathname == THANKYOU ? 'col-lg-12' : 'col-lg-7'}`}>
            {pages.map((val, index) => <span key={index} className="user_form">{(pathname == val.path ? val.comp : null)}</span>)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
