import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import InputButton from '../Input Function/InputButton'
import InputText from '../Input Function/InputText'
import { getAdminRole, getAdminToken, getLoggedInAdmin } from '../services/AdminLocalStorage'
import * as yup from 'yup'
import InputLabel from '../Input Function/InputLabel'
import InputSelectBox from '../Input Function/InputSelectBox'
import { ACTIVE_CAMPAIGN, ALL_BRANDS, ALL_INSUR_COMP, ALL_LEAD, ALL_MODELS, ALL_TRIMNAME, ALL_VEHICLE, FORM_QUESTION, FORM_SUB_QUESTION, FORM_SUB_VALUE, FORM_VALUE } from '../services/AdminRoutePath'
import InputLodingButton from '../Input Function/InputLodingButton'
import { ADD_ACTIVE_CAMPAIGN, ADD_BRAND, ADD_INSUR_COMP, ADD_MODEL, ADD_TRIMNAME, ADD_VEHICLES, curdData, deleteBrand, deleteInsurance, deleteModel, deleteTrimName, deleteVehicle, editActiveCampaign, editBrand, editInsurComp, editModel, editTrimName, editVehicle, EDIT_ACTIVE_CAMPAIGN, EDIT_BRAND, EDIT_FORM_QUESTION, EDIT_FORM_SUB_QUESTION, EDIT_FORM_SUB_VALUE, EDIT_FORM_VALUE, EDIT_INSUR_COMP, EDIT_MODEL, EDIT_TRIM_NAME, EDIT_VEHICLE, getActiveCampaignList, getBrandsList, getDataList, getDatas, getInsurCompsList, getModelsList, getTrimNameList, getVehiclesList, GET_ACTIVE_CAMPAIGN_LIST, GET_BRANDS, GET_BRANDS_LIST, GET_FORM_PAGE, GET_FORM_QUESTION, GET_FORM_QUESTIONS, GET_FORM_SUB_QUESTION, GET_FORM_SUB_QUESTIONS, GET_FORM_SUB_VALUE, GET_FORM_VALUE, GET_INSUR_COMPS_LIST, GET_MODELS_LIST, GET_TRIMNAME_LIST, GET_VEHICLES_LIST } from '../../redux/action/AdminAction'
import '../css/AdminModel.css'
import Modal from 'react-bootstrap/Modal';
import { AdminValAuthContext } from '../context/AdminAuthProvider'
import ViewInsurance from '../pages/ViewInsurance'
import DeleteModel from './DeleteModel'

const AddModel = ({ heading, action, setAction, show, setShow, editItem, deleteId, currentPage, setCurrentPage, viewItem }) => {
    // console.log(editItem,"-**--*-*-*-*-**********-*-----------")
    const [loading, setLoading] = useState(false);
    // const [show, setShow] = useState("");
    const [Error, setError] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const val = useContext(AdminValAuthContext);
    let load = false;
    const [preview, setPreview] = useState()
    const [compPreview, setCompPreview] = useState()
    const { pathname } = useLocation();
    const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png", "image/svg", "image/webp"]
    useEffect(() => {
        if (!load && action == "add" || "edit") {
            switch (pathname) {
                case ALL_MODELS:
                    dispatch(getDatas(setLoading, `admin/get-brands`, GET_BRANDS));
                    break;
                case ALL_TRIMNAME:
                    dispatch(getDatas(setLoading, `admin/get-brands`, GET_BRANDS));
                    break;
                case ALL_VEHICLE:
                    dispatch(getDatas(setLoading, `admin/get-brands`, GET_BRANDS));
                    break;
                case FORM_VALUE:
                    dispatch(getDatas(setLoading, `admin/get-form-pages`, GET_FORM_PAGE));
                    break;
                case FORM_SUB_QUESTION:
                    dispatch(getDatas(setLoading, `admin/get-form-pages`, GET_FORM_PAGE));
                    break;
                case FORM_SUB_VALUE:
                    dispatch(getDatas(setLoading, `admin/get-form-pages`, GET_FORM_PAGE));
                    break;
                default:
                    break;
            }
        }
        return () => { load = true }
    }, [])
    useEffect(() => {
        if (action == 'edit') {
            switch (pathname) {
                case ALL_BRANDS:
                    setFieldValue("brandName", editItem?.brand_name)
                    setFieldValue('image', editItem?.image)
                    break;
                case ALL_INSUR_COMP:
                    setFieldValue("companyName", editItem?.company_name)
                    setFieldValue('companyimage', editItem?.image)
                    break;
                case ALL_MODELS:
                    setFieldValue("modelName", editItem?.model_name)
                    setFieldValue('brandName', {
                        label: editItem?.brand_name, value: editItem?.brand_id
                    })
                    break;
                case ALL_TRIMNAME:
                    setFieldValue('modelName', {
                        label: editItem?.model_name, value: editItem?.model_id
                    })
                    setFieldValue('brandName', {
                        label: editItem?.brand_name, value: editItem?.brand_id
                    })
                    setFieldValue("trimName", editItem?.name)
                    break;
                case ALL_VEHICLE:
                    setFieldValue('modelName', {
                        label: editItem?.model_name, value: editItem?.model_id
                    })
                    setFieldValue('brandName', {
                        label: editItem?.brand_name, value: editItem?.brand_id
                    })
                    setFieldValue('trimName', {
                        label: editItem?.name, value: editItem?.trim_id
                    })
                    setFieldValue('year', {
                        label: editItem?.year, value: editItem?.year
                    })
                    setFieldValue("engineName", editItem?.engine_name)
                    setFieldValue("engineCode", editItem?.engine_code)
                    setFieldValue('transmissionCode', {
                        label: editItem?.transmission_code, value: editItem?.transmission_code
                    })
                    break;
                case ACTIVE_CAMPAIGN:
                    setFieldValue("acName", editItem?.name)
                    setFieldValue('apiUrl', editItem?.api_url)
                    setFieldValue('apiKey', editItem?.api_key)
                    break;
                case FORM_QUESTION:
                    setFieldValue("ques", editItem?.q_title)
                    setFieldValue('desc', editItem?.q_desc)
                    setFieldValue('info', editItem?.q_info)
                    break;
                case FORM_VALUE:
                    setFieldValue('page_id', {
                        label: editItem?.page_title, value: editItem?.q_page
                    })
                    setFieldValue('ques_id', {
                        label: editItem?.q_title, value: editItem?.q_id
                    })
                    setFieldValue('val', editItem?.q_value)
                    setFieldValue('val_type', {
                        label: editItem?.qv_type, value: editItem?.qv_type
                    })
                    break;
                case FORM_SUB_QUESTION:
                    setFieldValue('page_id', {
                        label: editItem?.page_title, value: editItem?.q_page
                    })
                    setFieldValue('ques_id', {
                        label: editItem?.q_title, value: editItem?.q_id
                    })
                    setFieldValue('q_v_id', {
                        label: editItem?.q_value, value: editItem?.q_v_id
                    })
                    setFieldValue("ques", editItem?.s_title)
                    setFieldValue('desc', editItem?.s_desc)
                    setFieldValue('info', editItem?.s_info)
                    break;
                case FORM_SUB_VALUE:
                    setFieldValue('page_id', {
                        label: editItem?.page_title, value: editItem?.q_page
                    })
                    setFieldValue('ques_id', {
                        label: editItem?.q_title, value: editItem?.q_id
                    })
                    setFieldValue('q_v_id', {
                        label: editItem?.q_value, value: editItem?.q_v_id
                    })
                    setFieldValue('sq_id', {
                        label: editItem?.s_title, value: editItem?.sq_id
                    })
                    setFieldValue("ques", editItem?.sv_title)
                    setFieldValue('desc', editItem?.sv_desc)
                    setFieldValue('val_type', {
                        label: editItem?.sv_type, value: editItem?.sv_type
                    })
                    break;
                default:
                    break;
            }
        }
    }, [editItem])

    const handleFileSelect = (e) => {
        if (pathname == ALL_BRANDS) {
            setFieldValue('image', e.target.files[0]);
            if (e.target.files[0]) {
                const objectUrl = URL.createObjectURL(e.target.files[0])
                setPreview(objectUrl)
            }
        }
        else {
            setFieldValue('companyimage', e.target.files[0]);
            if (e.target.files[0]) {
                const objectUrl = URL.createObjectURL(e.target.files[0])
                setCompPreview(objectUrl)
            }
        }

    }
    const { values, errors, handleBlur, handleChange, handleSubmit, touched, setFieldValue } = useFormik({
        initialValues:
            pathname == ALL_BRANDS ? {
                brandName: "",
                image: ""
            } :
                pathname == ALL_INSUR_COMP ? {
                    companyName: "",
                    companyimage: ""
                } :
                    pathname == ALL_MODELS ? {
                        modelName: "",
                        brandName: "",

                    } :
                        pathname == ALL_TRIMNAME ? {
                            modelName: "",
                            brandName: "",
                            trimName: ""
                        } :
                            pathname == ALL_VEHICLE ? {
                                modelName: "",
                                brandName: "",
                                trimName: "",
                                year: "",
                                engineCode: "",
                                engineName: "",
                                transmissionCode: ""
                            } : pathname == ACTIVE_CAMPAIGN ? {
                                acName: "",
                                apiUrl: "",
                                apiKey: ""
                            } : pathname == FORM_QUESTION ? {
                                ques: "",
                                desc: "",
                                info: ""
                            } : pathname == FORM_VALUE ? {
                                page_id:"",
                                ques_id: "",
                                val: "",
                                val_type: ""
                            } : pathname == FORM_SUB_QUESTION ? {
                                page_id:"",
                                ques_id: "",
                                q_v_id: "",
                                ques: "",
                                desc: "",
                                info: ""
                            } : pathname == FORM_SUB_VALUE ? {
                                page_id:"",
                                ques_id: "",
                                q_v_id: "",
                                sq_id: "",
                                ques: "",
                                desc: "",
                                val_type: ""
                            } : ""
        ,
        validationSchema: pathname == ALL_BRANDS ? yup.object({
            brandName: yup.string().required("Brand name is required"),
            image: yup.mixed().required('Image is required').test("fileType",
            "You can choose only image file",
            (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type)))
                .test(
                    "fileSize",
                    "Upload 40px X 40px image for best result.",
                    (file) => file && file.size <= 6400
                )
                
        })
            : pathname == ALL_INSUR_COMP ? yup.object({
                companyName: yup.string().required("Company name is required"),
                companyimage: yup.mixed().required('Image is required') .test("fileType",
                "You can choose only image file",
                (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type)))
                    .test(
                        "fileSize",
                        "Upload 40px X 40px image for best result.",
                        (file) => file && file.size <= 6400
                    )
                   
            }) :
                pathname == ALL_MODELS ? yup.object({
                    modelName: yup.string().required("Model name is required"),
                    brandName: yup.object().required("Brand name is required"),
                }) :
                    pathname == ALL_TRIMNAME ? yup.object({
                        modelName: yup.object().required("Model name is required"),
                        brandName: yup.object().required("Brand name is required"),
                        trimName: yup.string().required("Trim name is required"),
                    }) :
                        pathname == ALL_VEHICLE ? yup.object({
                            modelName: yup.object().required("Model name is required"),
                            brandName: yup.object().required("Brand name is required"),
                            trimName: yup.object().required("Trim name is required"),
                            year: yup.object().required("Year is required"),
                            // year: yup.string().typeError('Year specify in digits').min(1981).max(new Date().getFullYear()).required("Year is required"),
                            engineName: yup.string().required("Engine name is required"),
                            engineCode: yup.string().required("Engine code is required"),
                            transmissionCode: yup.object().required("Transmission code is required"),
                        }) : pathname == ACTIVE_CAMPAIGN ? yup.object({
                            acName: yup.string().required("Account name is required"),
                            apiUrl: yup.string().required("API url is required"),
                            apiKey: yup.string().required("API key is required"),
                        }) : pathname == FORM_QUESTION ? yup.object({
                            ques: yup.string().required("Question is required"),
                        }) : pathname == FORM_VALUE ? yup.object({
                            page_id: yup.object().required("Form Page is required"),
                            ques_id: yup.object().required("Question is required"),
                            val: yup.string().required("Question value is required"),
                            val_type: yup.object().required("Value type is required"),
                        }) : pathname == FORM_SUB_QUESTION ? yup.object({
                            page_id: yup.object().required("Form Page is required"),
                            ques_id: yup.object().required("Question is required"),
                            q_v_id: yup.object().required("Question value is required"),
                            ques: yup.string().required("Sub question is required"),
                        }) : pathname == FORM_SUB_VALUE ? yup.object({
                            page_id: yup.object().required("Form Page is required"),
                            ques_id: yup.object().required("Question is required"),
                            q_v_id: yup.object().required("Question value is required"),
                            sq_id: yup.object().required("Sub question is required"),
                            ques: yup.string().required("Sub value is required"),
                            val_type: yup.object().required("Sub value type is required"),
                        }) : "",

        onSubmit: (values, { resetForm }) => {
            const formdata = new FormData();
            switch (pathname) {
                case ALL_BRANDS:
                    formdata.append('brand_name', values.brandName);
                    formdata.append('image', values.image);
                    break;
                case ALL_INSUR_COMP:
                    formdata.append('company_name', values.companyName);
                    formdata.append('image', values.companyimage);
                    break;
                case ALL_MODELS:
                    formdata.append('model_name', values.modelName);
                    formdata.append('brand_id', values.brandName?.value);
                    break;
                case ALL_TRIMNAME:
                    formdata.append('name', values.trimName);
                    formdata.append('brand_id', values.brandName?.value);
                    formdata.append('model_id', values.modelName?.value);
                    break;
                case ALL_VEHICLE:
                    formdata.append('brand_id', values.brandName?.value);
                    formdata.append('model_id', values.modelName?.value);
                    formdata.append('trim_id', values.trimName?.value);
                    formdata.append('year', values.year?.value);
                    formdata.append('engine_name', values.engineName);
                    formdata.append('engine_code', values.engineCode);
                    formdata.append('transmission_code', values.transmissionCode?.value);
                    break;
                case ACTIVE_CAMPAIGN:
                    formdata.append('name', values.acName);
                    formdata.append('api_url', values.apiUrl);
                    formdata.append('api_key', values.apiKey);
                    break;
                case FORM_QUESTION:
                    formdata.append('q_title', values.ques);
                    formdata.append('q_desc', values.desc ? values.desc : null);
                    formdata.append('q_info', values.info ? values.info : null);
                    break;
                case FORM_VALUE:
                    formdata.append('q_page', values.page_id?.value);
                    formdata.append('q_id', values.ques_id?.value);
                    formdata.append('q_value', values.val);
                    formdata.append('qv_type', values.val_type?.value);
                    break;
                case FORM_SUB_QUESTION:
                    formdata.append('q_page', values.page_id?.value);
                    formdata.append('q_id', values.ques_id?.value);
                    formdata.append('q_v_id', values.q_v_id?.value);
                    formdata.append('s_title', values.ques);
                    formdata.append('s_desc', values.desc ? values.desc : null);
                    formdata.append('s_info', values.info ? values.info : null);
                    break;
                case FORM_SUB_VALUE:
                    formdata.append('q_page', values.page_id?.value);
                    formdata.append('q_id', values.ques_id?.value);
                    formdata.append('q_v_id', values.q_v_id?.value);
                    formdata.append('sq_id', values.sq_id?.value);
                    formdata.append('sv_title', values.ques);
                    formdata.append('sv_desc', values.desc ? values.desc : null);
                    formdata.append('sv_type', values.val_type?.value);
                    break;
                default:
                    break;
            }
            if (action == 'add') {
                switch (pathname) {
                    case ALL_BRANDS:
                        dispatch(curdData(formdata, setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage, getDataList, 1, "Brand added sucessfully!", ADD_BRAND, 'admin/add-brand', '', `admin/get-brands-list/?page=1`, GET_BRANDS_LIST));
                        break;
                    case ALL_INSUR_COMP:
                        dispatch(curdData(formdata, setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage, getDataList, 1, "Insurance company added sucessfully!", ADD_INSUR_COMP, 'admin/add-insur-comp', '', `admin/get-insur-comp-list/?page=1`, GET_INSUR_COMPS_LIST));
                        break;
                    case ALL_MODELS:
                        dispatch(curdData(formdata, setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage, getDataList, 1, "Model added sucessfully!", ADD_MODEL, 'admin/add-model', '', `admin/get-models-list/?page=1`, GET_MODELS_LIST));
                        break;
                    case ALL_TRIMNAME:
                        dispatch(curdData(formdata, setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage, getDataList, 1, "Trim name added sucessfully!", ADD_TRIMNAME, 'admin/add-trimname', '', `admin/get-trimNames-list/?page=1`, GET_TRIMNAME_LIST));
                        break;
                    case ALL_VEHICLE:
                        dispatch(curdData(formdata, setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage, getDataList, 1, "Vehicle added sucessfully!", ADD_VEHICLES, 'admin/add-vehicle', '', `admin/get-vehicles-list/?page=1`, GET_VEHICLES_LIST));
                        break;
                    case ACTIVE_CAMPAIGN:
                        dispatch(curdData(formdata, setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage, getDataList, 1, "Account added sucessfully!", ADD_ACTIVE_CAMPAIGN, 'admin/add-active-campaign', '', `admin/get-active-campaign-users/?page=1`, GET_ACTIVE_CAMPAIGN_LIST));
                        break;
                    default:
                        break;
                }
            }
            else if (action == 'edit') {
                switch (pathname) {
                    case ALL_BRANDS:
                        dispatch(curdData(formdata, setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage, getDataList, currentPage, "Brand updated sucessfully!", EDIT_BRAND, `admin/update-brand/${editItem?.id}`, setCurrentPage, `admin/get-brands-list/?page=${currentPage}`, GET_BRANDS_LIST));
                        break;
                    case ALL_INSUR_COMP:
                        dispatch(curdData(formdata, setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage, getDataList, currentPage, "Insurance company updated sucessfully!", EDIT_INSUR_COMP, `admin/update-insur-comp/${editItem?.id}`, setCurrentPage, `admin/get-insur-comp-list/?page=${currentPage}`, GET_INSUR_COMPS_LIST));
                        break;
                    case ALL_MODELS:
                        dispatch(curdData(formdata, setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage, getDataList, currentPage, "Model updated sucessfully!", EDIT_MODEL, `admin/update-model/${editItem?.id}`, setCurrentPage, `admin/get-models-list/?page=${currentPage}`, GET_MODELS_LIST));
                        break;
                    case ALL_TRIMNAME:
                        dispatch(curdData(formdata, setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage, getDataList, currentPage, "Trim name updated sucessfully!", EDIT_TRIM_NAME, `admin/update-trimname/${editItem?.id}`, setCurrentPage, `admin/get-trimNames-list/?page=${currentPage}`, GET_TRIMNAME_LIST));
                        break;
                    case ALL_VEHICLE:
                        dispatch(curdData(formdata, setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage, getDataList, currentPage, "Vehicle updated sucessfully!", EDIT_VEHICLE, `admin/update-vehicle/${editItem?.id}`, setCurrentPage, `admin/get-vehicles-list/?page=${currentPage}`, GET_VEHICLES_LIST));
                        break;
                    case ACTIVE_CAMPAIGN:
                        dispatch(curdData(formdata, setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage, getDataList, currentPage, "Account updated sucessfully!", EDIT_ACTIVE_CAMPAIGN, `admin/update-active-campaign/${editItem?.id}`, setCurrentPage, `admin/get-active-campaign-users/?page=${currentPage}`, GET_ACTIVE_CAMPAIGN_LIST));
                        break;
                    case FORM_QUESTION:
                        dispatch(curdData(formdata, setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage, getDataList, currentPage, "Form question updated sucessfully!", EDIT_FORM_QUESTION, `admin/update-form-question/${editItem?.id}`, setCurrentPage, `admin/get-form-question/?page=${currentPage}`, GET_FORM_QUESTION));
                        break;
                    case FORM_VALUE:
                        dispatch(curdData(formdata, setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage, getDataList, currentPage, "Form value updated sucessfully!", EDIT_FORM_VALUE, `admin/update-form-value/${editItem?.id}`, setCurrentPage, `admin/get-form-value/?page=${currentPage}`, GET_FORM_VALUE));
                        break;
                    case FORM_SUB_QUESTION:
                        dispatch(curdData(formdata, setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage, getDataList, currentPage, "Form sub question updated sucessfully!", EDIT_FORM_SUB_QUESTION, `admin/update-form-sub-question/${editItem?.id}`, setCurrentPage, `admin/get-form-sub-question/?page=${currentPage}`, GET_FORM_SUB_QUESTION));
                        break;
                    case FORM_SUB_VALUE:
                        dispatch(curdData(formdata, setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage, getDataList, currentPage, "Form sub question updated sucessfully!", EDIT_FORM_SUB_VALUE, `admin/update-form-sub-value/${editItem?.id}`, setCurrentPage, `admin/get-form-sub-value/?page=${currentPage}`, GET_FORM_SUB_VALUE));
                        break;
                    default:
                        break;
                }
            }
            else {
                return
            }
            setLoading(true);
            resetForm({ values: '' });
        }
    })

    const handleClose = () => {
        setShow(false);
        setAction('');
        val?.setHideSelectModel(true);
        val?.setHideSelectTrim(true);
        val?.setHideSelectFromPage(true);
        // val?.setShowSidebar(true)
    }
    const brandList = useSelector((state) => state.AdminReducer.brandList);
    const brand = brandList?.map((b) => {
        return { value: b?.id, label: b?.brand_name }
    })
    const page_list = useSelector((state) => state.AdminReducer.formPage);
    const FormPage = page_list?.map((b) => {
        return { value: b?.id, label: b?.page_title }
    })
    const val_type = [
        { value: "Radio", label: "Radio" },
        { value: "Textfield", label: "Textfield" },
        { value: "Checkbox", label: "Checkbox" },
        { value: "Selectbox", label: "Selectbox" },
        { value: "Autocomplete", label: "Autocomplete" },
        { value: "Custom Monthfield", label: "Custom Monthfield" },
        { value: "Custom Date", label: "Custom Date" },
        { value: "Currencyfield", label: "Currencyfield" },
        { value: "Ticket", label: "Ticket" },
        { value: "Violations", label: "Violations" },

    ]

    return (
        <>
            <div className='admin-model'>
                <Modal
                    size={action == "view" ? "xl" : "md"}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={show}
                    scrollable={action == "view" ? true : false}
                    backdrop="static"
                    keyboard={false}
                    className='fade'
                >
                    {action == 'delete' ? <DeleteModel setShow={setShow} loading={loading} setLoading={setLoading} deleteId={deleteId} setAction={setAction} setCurrentPage={setCurrentPage} currentPage={currentPage} /> : action == 'view' ?
                        <ViewInsurance viewItem={viewItem} setShow={setShow} setAction={setAction} />
                        :
                        <div className="modal-content border-0">
                            <div className="modal-header p-3 bg-soft-green">
                                <h5 className="modal-title text-capitalize" id="createTaskLabel">{action} {heading == "Active Campaign" ? "Account" : heading}</h5>
                                <InputButton type="button" className="btn-close" databsdismiss="modal" event={() => { handleClose() }} />
                            </div>
                            <div className="modal-body">
                                {pathname == ACTIVE_CAMPAIGN ? <p>To get API credentials. Login into account to go settings and developer. You will find URL and Key. <span className="question-box"><a href="/assets/images/active-detail.png" target="_blank" style={{ color: 'rgb(255, 255, 255)' }}>?</a></span></p> : null}
                                <form onSubmit={handleSubmit}>
                                    {
                                        heading == "Brand" ? <><div className="mb-3">
                                            <InputLabel className="form-label" name="Brand Name" />
                                            <InputText
                                                name="brandName"
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter brand name"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                value={values?.brandName}
                                                error={errors.brandName}
                                                touch={touched.brandName} />
                                        </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Brand Logo" />
                                                <InputText
                                                    name="image"
                                                    type="file"
                                                    className="form-control"
                                                    handleChange={handleFileSelect}
                                                    handleBlur={handleBlur}
                                                    value={values.image}
                                                    error={errors.image}
                                                    touch={touched.image} />
                                            </div>
                                            {preview ? <div className="avatar-sm bg-light rounded p-1 ms-2">
                                                <img src={preview} className="img-fluid d-block" />
                                            </div> : !preview && action == "edit" && editItem?.image ? <div className="avatar-sm bg-light rounded p-1 ms-2">
                                                <img src={`${process.env.REACT_APP_BRAND_IMAGE_URL}${editItem?.image}`} className="img-fluid d-block" />
                                            </div> : null}
                                        </> : heading == "Company" ? <><div className="mb-3">
                                            <InputLabel className="form-label" name="Company Name" />
                                            <InputText
                                                name="companyName"
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter company name"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                value={values?.companyName}
                                                error={errors.companyName}
                                                touch={touched.companyName} />
                                        </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Company Logo" />
                                                <InputText
                                                    name="companyimage"
                                                    type="file"
                                                    className="form-control"
                                                    handleChange={handleFileSelect}
                                                    handleBlur={handleBlur}
                                                    value={values.companyimage}
                                                    error={errors.companyimage}
                                                    touch={touched.companyimage} />
                                            </div>
                                            {compPreview ? <div className="avatar-sm bg-light rounded p-1 ms-2">
                                                <img src={compPreview} className="img-fluid d-block" />
                                            </div> : !compPreview && action == "edit" && editItem?.image ? <div className="avatar-sm bg-light rounded p-1 ms-2">
                                                <img src={`${process.env.REACT_APP_COMPANY_IMAGE_URL}${editItem?.image}`} className="img-fluid d-block" />
                                            </div> : null}
                                        </> : heading == "Model" ? <>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Brand Name" />
                                                <InputSelectBox
                                                    name="brandName"
                                                    className="form-select py-0 ps-0 select-control "
                                                    placeholder={action == 'edit' ? editItem?.brand_name : "Select..."}
                                                    setFieldValue={setFieldValue}
                                                    handleBlur={handleBlur}
                                                    brand={brand}
                                                    value={values.brandName}
                                                    error={errors.brandName}
                                                    touch={touched.brandName}
                                                    action={action} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Model Name" />
                                                <InputText
                                                    name="modelName"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter model name"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.modelName}
                                                    error={errors.modelName}
                                                    touch={touched.modelName} />
                                            </div>
                                        </> : heading == "Trim Name" ? <>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Brand Name" />
                                                <InputSelectBox
                                                    name="brandName"
                                                    className="form-select py-0 ps-0 select-control "
                                                    placeholder={action == 'edit' ? editItem?.brand_name : "Select..."}
                                                    setFieldValue={setFieldValue}
                                                    handleBlur={handleBlur}
                                                    brand={brand}
                                                    value={values.brandName}
                                                    error={errors.brandName}
                                                    touch={touched.brandName}
                                                    action={action}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Model Name" />
                                                <InputSelectBox
                                                    name="modelName"
                                                    className="form-select py-0 ps-0 select-control "
                                                    placeholder={action == 'edit' ? editItem?.model_name : "Select..."}
                                                    setFieldValue={setFieldValue}
                                                    handleBlur={handleBlur}
                                                    value={values.modelName}
                                                    error={errors.modelName}
                                                    touch={touched.modelName}
                                                    action={action}
                                                    editItem={editItem} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Trim Name" />
                                                <InputText
                                                    name="trimName"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter trim name"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.trimName}
                                                    error={errors.trimName}
                                                    touch={touched.trimName} />
                                            </div>
                                        </> : heading == "Vehicle" ? <>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Brand Name" />
                                                <InputSelectBox
                                                    name="brandName"
                                                    className="form-select py-0 ps-0 select-control "
                                                    placeholder="Select..."
                                                    setFieldValue={setFieldValue}
                                                    handleBlur={handleBlur}
                                                    value={values.brandName}
                                                    error={errors.brandName}
                                                    touch={touched.brandName}
                                                    brand={brand}
                                                    action={action} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Model Name" />
                                                <InputSelectBox
                                                    name="modelName"
                                                    className="form-select py-0 ps-0 select-control "
                                                    placeholder="Select..."
                                                    setFieldValue={setFieldValue}
                                                    handleBlur={handleBlur}
                                                    value={values.modelName}
                                                    error={errors.modelName}
                                                    touch={touched.modelName}
                                                    action={action} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Trim Name" />
                                                <InputSelectBox
                                                    name="trimName"
                                                    className="form-select py-0 ps-0 select-control "
                                                    placeholder="Select..."
                                                    setFieldValue={setFieldValue}
                                                    handleBlur={handleBlur}
                                                    value={values.trimName}
                                                    error={errors.trimName}
                                                    touch={touched.trimName}
                                                    action={action} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Year" />
                                                <InputSelectBox
                                                    name="year"
                                                    className="form-select py-0 ps-0 select-control "
                                                    placeholder="Select..."
                                                    setFieldValue={setFieldValue}
                                                    handleBlur={handleBlur}
                                                    value={values.year}
                                                    error={errors.year}
                                                    touch={touched.year} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Engine Name" />
                                                <InputText
                                                    name="engineName"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter engine name"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.engineName}
                                                    error={errors.engineName}
                                                    touch={touched.engineName} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Engine Code" />
                                                <InputText
                                                    name="engineCode"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter engine name"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.engineCode}
                                                    error={errors.engineCode}
                                                    touch={touched.engineCode} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Transmission Code" />
                                                <InputSelectBox
                                                    name="transmissionCode"
                                                    className="form-select py-0 ps-0 select-control "
                                                    placeholder="Select..."
                                                    setFieldValue={setFieldValue}
                                                    handleBlur={handleBlur}
                                                    value={values.transmissionCode}
                                                    error={errors.transmissionCode}
                                                    touch={touched.transmissionCode} />
                                            </div>
                                        </> : heading == "Active Campaign" ? <>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Account name" />
                                                <InputText
                                                    name="acName"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter account name"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.acName}
                                                    error={errors.acName}
                                                    touch={touched.acName} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="API url" />
                                                <InputText
                                                    name="apiUrl"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter API url"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.apiUrl}
                                                    error={errors.apiUrl}
                                                    touch={touched.apiUrl} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="API key" />
                                                <InputText
                                                    name="apiKey"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter API key"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.apiKey}
                                                    error={errors.apiKey}
                                                    touch={touched.apiKey} />
                                            </div>
                                        </> : heading == "Question" ? <>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Title" />
                                                <InputText
                                                    name="ques"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter title"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.ques}
                                                    error={errors.ques}
                                                    touch={touched.ques} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Description" />
                                                <InputText
                                                    name="desc"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter description"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.desc}
                                                    error={errors.desc}
                                                    touch={touched.desc} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Information" />
                                                <InputText
                                                    name="info"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter information"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.info}
                                                    error={errors.info}
                                                    touch={touched.info} />
                                            </div>
                                        </> : heading == "Value" ? <>
                                        <div className="mb-3">
                                                <InputLabel className="form-label" name="Question" />
                                                <InputSelectBox
                                                    name="page_id"
                                                    className="form-select py-0 ps-0 select-control "
                                                    placeholder="Select..."
                                                    setFieldValue={setFieldValue}
                                                    handleBlur={handleBlur}
                                                    opt={FormPage}
                                                    value={values.page_id}
                                                    error={errors.page_id}
                                                    touch={touched.page_id}
                                                    action={action} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Question" />
                                                <InputSelectBox
                                                    name="ques_id"
                                                    className="form-select py-0 ps-0 select-control "
                                                    // placeholder={action == 'edit' ? editItem?.q_title : "Select..."}
                                                    setFieldValue={setFieldValue}
                                                    handleBlur={handleBlur}                                                 
                                                    value={values.ques_id}
                                                    error={errors.ques_id}
                                                    touch={touched.ques_id}
                                                    action={action} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Value" />
                                                <InputText
                                                    name="val"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Value"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.val}
                                                    error={errors.val}
                                                    touch={touched.val} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Value Type" />
                                                <InputSelectBox
                                                    name="val_type"
                                                    className="form-select py-0 ps-0 select-control "
                                                    placeholder="Select..."
                                                    setFieldValue={setFieldValue}
                                                    handleBlur={handleBlur}
                                                    opt={val_type}
                                                    value={values.val_type}
                                                    error={errors.val_type}
                                                    touch={touched.val_type}
                                                    action={action} />
                                            </div>
                                        </> : heading == "Sub Question" ? <>
                                        <div className="mb-3">
                                                <InputLabel className="form-label" name="Question" />
                                                <InputSelectBox
                                                    name="page_id"
                                                    className="form-select py-0 ps-0 select-control "
                                                    placeholder="Select..."
                                                    setFieldValue={setFieldValue}
                                                    handleBlur={handleBlur}
                                                    opt={FormPage}
                                                    value={values.page_id}
                                                    error={errors.page_id}
                                                    touch={touched.page_id}
                                                    action={action} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Question" />
                                                <InputSelectBox
                                                    name="ques_id"
                                                    className="form-select py-0 ps-0 select-control "
                                                    placeholder="Select..."
                                                    setFieldValue={setFieldValue}
                                                    handleBlur={handleBlur}                                                
                                                    value={values.ques_id}
                                                    error={errors.ques_id}
                                                    touch={touched.ques_id}
                                                    action={action} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Question Value" />
                                                <InputSelectBox
                                                    name="q_v_id"
                                                    className="form-select py-0 ps-0 select-control "
                                                    placeholder={action == 'edit' ? editItem?.q_value : "Select..."}
                                                    setFieldValue={setFieldValue}
                                                    handleBlur={handleBlur}
                                                    value={values.q_v_id}
                                                    error={errors.q_v_id}
                                                    touch={touched.q_v_id}
                                                    action={action} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Title" />
                                                <InputText
                                                    name="ques"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter title"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.ques}
                                                    error={errors.ques}
                                                    touch={touched.ques} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Description" />
                                                <InputText
                                                    name="desc"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter description"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.desc}
                                                    error={errors.desc}
                                                    touch={touched.desc} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Information" />
                                                <InputText
                                                    name="info"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter information"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.info}
                                                    error={errors.info}
                                                    touch={touched.info} />
                                            </div>
                                        </> :heading == "Sub Value" ? <>
                                        <div className="mb-3">
                                                <InputLabel className="form-label" name="Question" />
                                                <InputSelectBox
                                                    name="page_id"
                                                    className="form-select py-0 ps-0 select-control "
                                                    placeholder="Select..."
                                                    setFieldValue={setFieldValue}
                                                    handleBlur={handleBlur}
                                                    opt={FormPage}
                                                    value={values.page_id}
                                                    error={errors.page_id}
                                                    touch={touched.page_id}
                                                    action={action} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Question" />
                                                <InputSelectBox
                                                    name="ques_id"
                                                    className="form-select py-0 ps-0 select-control "
                                                    placeholder="Select..."
                                                    setFieldValue={setFieldValue}
                                                    handleBlur={handleBlur}
                                                    value={values.ques_id}
                                                    error={errors.ques_id}
                                                    touch={touched.ques_id}
                                                    action={action} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Question Value" />
                                                <InputSelectBox
                                                    name="q_v_id"
                                                    className="form-select py-0 ps-0 select-control "
                                                    placeholder={action == 'edit' ? editItem?.q_value : "Select..."}
                                                    setFieldValue={setFieldValue}
                                                    handleBlur={handleBlur}
                                                    value={values.q_v_id}
                                                    error={errors.q_v_id}
                                                    touch={touched.q_v_id}
                                                    action={action} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Sub Question" />
                                                <InputSelectBox
                                                    name="sq_id"
                                                    className="form-select py-0 ps-0 select-control "
                                                    placeholder={action == 'edit' ? editItem?.s_title : "Select..."}
                                                    setFieldValue={setFieldValue}
                                                    handleBlur={handleBlur}
                                                    value={values.sq_id}
                                                    error={errors.sq_id}
                                                    touch={touched.sq_id}
                                                    action={action} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Title" />
                                                <InputText
                                                    name="ques"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter title"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.ques}
                                                    error={errors.ques}
                                                    touch={touched.ques} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Description" />
                                                <InputText
                                                    name="desc"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter description"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.desc}
                                                    error={errors.desc}
                                                    touch={touched.desc} />
                                            </div>
                                            <div className="mb-3">
                                                <InputLabel className="form-label" name="Value Type" />
                                                <InputSelectBox
                                                    name="val_type"
                                                    className="form-select py-0 ps-0 select-control "
                                                    placeholder="Select..."
                                                    setFieldValue={setFieldValue}
                                                    handleBlur={handleBlur}
                                                    opt={val_type}
                                                    value={values.val_type}
                                                    error={errors.val_type}
                                                    touch={touched.val_type}
                                                    action={action} />
                                            </div>
                                        </> : null

                                    }

                                    <div className="hstack gap-2 justify-content-end">
                                        <InputButton type="button" className="btn btn-ghost-success" databsdismiss="modal" icon="ri-close-fill align-bottom" name="Close" event={() => { handleClose() }} />
                                        {
                                            loading ? <InputLodingButton name={action == 'add' ? "Adding.." : action == 'edit' ? "Editing..." : null} className="btn btn-primary btn-load" databsdismiss="model" /> : <InputButton className="btn btn-primary text-capitalize" type="submit" name={`${action} ${heading == "Active Campaign" ? "Account" : heading}`} />
                                        }
                                    </div>
                                </form>

                            </div>
                        </div>
                    }
                </Modal>
            </div>

        </>
    )
}

export default React.memo(AddModel)