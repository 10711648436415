import React, { useEffect, useState } from 'react'
import InputButton from '../../Input Function/InputButton'
import InputLabel from '../../Input Function/InputLabel'
import InputText from '../../Input Function/InputText'
import { AdminLoginBg, AdminLoginText } from './AdminLoginBg'
import { useFormik } from 'formik'
import * as yup from 'yup'
import InputLodingButton from '../../Input Function/InputLodingButton'
import { adminLogin } from '../../../redux/action/AdminAction'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { ADMIN_LOGIN, DASHBOARD } from '../../services/AdminRoutePath'
import { getAdminRole, getAdminToken, getLoggedInAdmin } from '../../services/AdminLocalStorage'
// import '../../css/AdminLogin.css'
const AdminLogin = () => {
  const [loading, setLoading] = useState(false);
  const [Error, setError] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let adminToken=getAdminToken()
  let role=getAdminRole()
  let admin=getLoggedInAdmin()
  const { values, errors, handleBlur, handleChange, handleSubmit, touched } = useFormik({
    initialValues: {
      email: "",
      // username: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup.string().email('Invalid Email address!').required("Email is required!"),
      // username: yup.string().required('Username is required!'),
      password: yup.string().min(6, 'Password must be 6 character length!').required('Password is required!'),
    }),

    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      dispatch(adminLogin({ email: values.email, password: values.password, role: "admin" }, setLoading, navigate, setError));
      resetForm({ values: '' });
    }
  })

  useEffect(()=>{
    if(admin && adminToken)
    {
      navigate(DASHBOARD)
    }
    else
    {
      navigate(ADMIN_LOGIN)
    }
  },[])
  return (
    <>
   
      <div className="auth-page-wrapper pt-5">
        <AdminLoginBg />
        <div className="auth-page-content">
          <div className="container">
            <AdminLoginText />
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card mt-4">
                  <div className="card-body p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-yellow">Welcome Back !</h5>
                      <p className="text-muted">Sign in to continue to Insure The Future.</p>
                    </div>
                    <div className="p-2 mb-4">
                      {Error && <div class="alert alert-danger alert-borderless mb-1" role="alert">{Error}</div>}
                      <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <InputLabel className="form-label" name="Email Address" />
                          <InputText
                            name="email"
                            type="text"
                            className="form-control"
                            placeholder="Enter email address"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.email}
                            error={errors.email}
                            touch={touched.email} />
                        </div>
                        <div className="mb-3">
                          <InputLabel className="form-label" name="Password" />
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <InputText
                              name="password"
                              type="password"
                              className="form-control pe-5 password-input"
                              placeholder="Enter password"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={values.password}
                              error={errors.password}
                              touch={touched.password} />
                            {/* <InputButton className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" icon="ri-eye-off-fill align-middle" /> */}
                          </div>
                          <div className="float-end">
                            <a href="#" className="text-muted">Forgot password?</a>
                          </div>
                        </div>
                        <div className="form-check">
                          <InputText className="form-check-input" type="checkbox" />
                          <InputLabel className="form-check-label" name="Remember me" />
                        </div>
                        <div className="mt-4">
                          {
                            loading ? <InputLodingButton name="Signing..." className="btn btn-primary btn-load w-100" /> : <InputButton className="btn btn-primary w-100" type="submit" name="Sign In" />
                          }
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/* <div className="mt-4 text-center">
                  <p className="mb-0">Don't have an account ? <a href="#" className="fw-semibold text-primary text-decoration-underline"> Signup </a> </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
  
    </>
  )
}

export default AdminLogin