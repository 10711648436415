import React, { useContext, useEffect, useState } from 'react'
import Select, { components } from "react-select";
import '../css/SearchSelect.css'
import { useLocation, useNavigate } from 'react-router';
import { BRAND_IMAGE_PATH, INSURANCE_COMPANY_IMAGE_PATH, VEHICLE_SELECT } from '../../services/UserRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { getUserMakeList, getUserModelList, getUserTrimList } from '../../../redux/action/UserAction';
import { UserValContext } from '../../context/UserContextProvider';
import classNames from 'classnames';
import { backend_url, color } from '../../services/Methods';

const SearchSelect = ({ w, mr, label, count, opt, name, handleChange, value, error, touched, isSearchable, id, handleBlur, setFieldValue, placeholder, isDisabled, options, type }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    let makeList = useSelector((state) => state?.UserReducer?.userMakeList);
    let modelList = useSelector((state) => state?.UserReducer?.userModelList);
    let trimList = useSelector((state) => state?.UserReducer?.userTrimList);

    const [vehicleMakeList, setVehicleMakeList] = useState([]);
    const [vehicleModelList, setVehicleModelList] = useState([]);
    const [vehicleTrimList, setVehicleTrimList] = useState([]);

    const { setVehicleModel, vehicleModel, setVehicleMake, vehicleMake, setVehicleYear, vehicleYear, submitting, setSubmitting } = useContext(UserValContext)

    useEffect(() => {
        switch (type) {
            case 'vehicle_make':
                setVehicleMakeList(makeList?.map(({ image, brand_name, id }) => {
                    return { value: brand_name, id: id, label: brand_name, image: image ? `${BRAND_IMAGE_PATH}${image}` : ' ' }
                }))
                break;
            case 'vehicle_model':
                setVehicleModelList(modelList?.map(({ model_name, id }) => {
                    return { value: model_name, id: id, label: model_name }
                }))
                break;
            case 'vehicle_trim':
                setVehicleTrimList(trimList?.map(({ name, id }) => {
                    return { value: name, id: id, label: name }
                }))
                break;
            default:
                break;
        }

    }, [makeList, modelList, trimList])

    const { Option } = components;

    const IconOption = props => {
        return (
            <Option {...props}>
                {props.data.image.includes(`${backend_url}images/brand/`) ?
                    <img
                        src={props.data.image}
                        style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }}
                        alt={props.data.label}
                    />
                    : null}

                {props.data.label}
            </Option>
        )

    };


    const options1 = opt ? opt : [{ value: 'Color', label: 'Yellow' }, { value: 'Fruit', label: 'Apple' }, { value: 'Tool', label: 'Spanner' }]
    const styles = {
        fontSize: '30px',
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
               fontSize:'18px'
            }
        },
        option: (provided, state) => ({
            ...provided,
            "&:hover": {
                backgroundColor: "rgba(10, 179, 156, 0.1)",
                color: color,
                cursor: "pointer"
            },
            fontWeight: state.isSelected ? "bold" : "normal",
            textAlign: "start",
            color: "black",
            backgroundColor: state.data.color,
            fontSize: '17px'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: state.data.color,
            fontSize: '17px'
        }),
    };

    const handleValue = (data) => {
        setFieldValue(name, data)
        if (pathname == VEHICLE_SELECT) {
            switch (type) {
                case 'vehicle_year':
                    setVehicleYear(data.value)
                    dispatch(getUserMakeList(data.value))
                    setVehicleModelList([])
                    setFieldValue(`vehicle${count}1`, '')
                    setFieldValue(`vehicle${count}2`, '')
                    setFieldValue(`vehicle${count}3`, '')
                    break;
                case 'vehicle_make':
                    setVehicleMake(data.id)
                    dispatch(getUserModelList({
                        "year": vehicleYear,
                        "brand_id": data.id
                    }))
                    setVehicleTrimList([])
                    setFieldValue(`vehicle${count}2`, '')
                    setFieldValue(`vehicle${count}3`, '')
                    break;
                case 'vehicle_model':
                    setVehicleModel(data.id)
                    dispatch(getUserTrimList({ "model_id": data.id, "brand_id": vehicleMake, "year": vehicleYear }))
                    setFieldValue(`vehicle${count}3`, '')
                    break;
                default:
                    break;
            }
        }
    }

    const icon = props => {
        return (
            <Option {...props}>
                {props?.data?.image?.includes(INSURANCE_COMPANY_IMAGE_PATH) ?
                    <img
                        src={props?.data?.image}
                        style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }}
                        alt={props?.data?.label}
                    />
                    : null}

                {props.data.label}
            </Option>
        )

    };
    return (
        <>
            <div className='user-select'>
                {
                    type == 'vehicle_year' && <Select onFocus={() => setSubmitting(false)}
                        className={classNames(` ${w} ${isDisabled ? 'pe-none opacity-50' : ''} p-0 form-select`, { 'is-invalid': error && touched && submitting })}
                        styles={styles}
                        isSearchable={isSearchable}
                        placeholder={placeholder}
                        id={id}
                        value={value}
                        name={name}
                        options={options || options1}
                        onChange={handleValue}
                        onBlur={handleBlur}
                    />
                }
                {
                    type == 'vehicle_make' && <Select onFocus={() => setSubmitting(false)}
                        className={classNames(` ${w} ${isDisabled ? 'pe-none opacity-50' : ''} p-0 form-select`, { 'is-invalid': error && touched && submitting })}
                        styles={styles}
                        isSearchable={isSearchable}
                        placeholder={placeholder}
                        id={id}
                        value={value}
                        name={name}
                        options={vehicleMakeList}
                        onChange={handleValue}
                        onBlur={handleBlur}
                        components={{ Option: IconOption }}
                    />
                }
                {
                    type == 'vehicle_model' && <Select onFocus={() => setSubmitting(false)}
                        className={classNames(` ${w} ${isDisabled ? 'pe-none opacity-50' : ''} p-0 form-select`, { 'is-invalid': error && touched && submitting })}
                        styles={styles}
                        isSearchable={isSearchable}
                        placeholder={placeholder}
                        id={id}
                        value={value}
                        name={name}
                        options={vehicleModelList}
                        onChange={handleValue}
                        onBlur={handleBlur}
                    />
                }
                {
                    type == 'vehicle_trim' && <Select onFocus={() => setSubmitting(false)}
                        className={classNames(` ${w} ${isDisabled ? 'pe-none opacity-50' : ''} p-0 form-select`, { 'is-invalid': error && touched && submitting })}
                        styles={styles}
                        isSearchable={isSearchable}
                        placeholder={placeholder}
                        id={id}
                        value={value}
                        name={name}
                        options={vehicleTrimList}
                        onChange={handleValue}
                        onBlur={handleBlur}
                    />
                }
                {
                    !type && <Select onFocus={() => setSubmitting(false)}
                        className={classNames(` ${w} ${isDisabled ? 'pe-none opacity-50' : ''} p-0 form-select`, { 'is-invalid': error && touched && submitting && !value })}
                        styles={styles}
                        isSearchable={isSearchable}
                        placeholder={placeholder}
                        id={id}
                        value={value}
                        name={name}
                        options={opt || options1}
                        onChange={handleValue}
                        onBlur={handleBlur}
                        components={{ Option: icon }}
                    />
                }

            </div>

            {!value && error && <span className='text-danger fs-4'>{submitting && error}</span>}
        </>
    )
}

export default SearchSelect