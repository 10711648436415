import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getInsuranceDetail } from '../../redux/action/AdminAction';
import InputButton from '../Input Function/InputButton';
import moment from 'moment'
import { AdminValAuthContext } from '../context/AdminAuthProvider';
const ViewInsurance = ({ viewItem, setShow, setAction }) => {
    const dispatch = useDispatch();
    const val = useContext(AdminValAuthContext)
    let load = false;
    useEffect(() => {
        if (!load) {
            dispatch(getInsuranceDetail(viewItem?.id))
        }     
        return () => { load = true }
    }, [viewItem])
    const list = useSelector((state) => state.AdminReducer.insuranceList);
    console.log(list)
    const obj = [
        { title: "User details", user_data: list?.user_obj, "tbhead": [ { key: "ID", width: "5%" }, { key: "First Name", width: "" }, { key: "Last Name", width: "" }, { key: "Birthdate", width: "" }, { key: "Email", width: "" },{ key: "Unit", width: "" }, { key: "Address", width: "" }, { key: "City", width: "" }, { key: "State", width: "" }, { key: "Zipcode", width: "" },{ key: "Date", width: "" }] },
        { title: "Insurance details", insur_data: list?.insur_obj, "tbhead": [{ key: "Id", width: "5%" }, { key: "Current Insurance", width: "" }, { key: "Insured", width: "" }, { key: "Living home", width: "" }, { key: "Auto + Home", width: "" }, { key: "Take insurance", width: "" }, { key: "Current Policy", width: "" }] },
        { title: "Vehicle details", vehicle_data: list?.vehicle_obj, "tbhead": [{ key: "Id", width: "5%" }, { key: "Year", width: "" }, { key: "Make", width: "" }, { key: "Model", width: "" }, { key: "Trim name", width: "" }, { key: "Own lease", width: "" }, { key: "Mostly used", width: "" }, { key: "Miles frequency", width: "" }, { key: "Miles drive", width: "" }] },
        { title: "Self driver details", me_data: list?.me_driver, "tbhead": [{ key: "Id", width: "5%" }, { key: "Gender", width: "" }, { key: "Marital type", width: "" }, { key: "Creadit score", width: "" }, { key: "Eduction", width: "" }, { key: "Continous insured time", width: "" }, { key: "Current company", width: "" }, { key: "injury", width: "" }, { key: "email", width: "" }, { key: "Discount apply", width: "" }, { key: "Accident & ticket", width: "" }] },
        { title: "Spouse and additional driver details", sa_data: list?.sa_driver, "tbhead": [{ key: "Id", width: "5%" }, { key: "Spouse/Additional", width: "" },{ key: "First name", width: "" }, { key: "Last name", width: "" }, { key: "Birthdate", width: "" },{ key: "Gender", width: "" },{ key: "Relationship", width: "" },{ key: "Marital status", width: "" }] },
        { title: "Accidents or tickets details", at_data: list?.at_driver, "tbhead": [{ key: "Id", width: "5%" }, { key: "My faults", width: "" }, { key: "Not my faults", width: "" }, { key: "Clamis", width: "" }, { key: "Ticket", width: "" }, { key: "Low tickets", width: "" }, { key: "High tickets", width: "" }, { key: "Major violations", width: "" }, { key: "Minor violations", width: "" }, { key: "DUI & DWI", width: "" }, { key: "Others", width: "" }] },
        { title: "Home details", home_data: list?.home_obj, "tbhead": [{ key: "Id", width: "5%" },{ key: "Home type", width: "" },  { key: "Unit", width: "" }, { key: "Address", width: "" }, { key: "City", width: "" }, { key: "State", width: "" }, { key: "Zipcode", width: "" },{ key: "Own home", width: "" }, { key: "Buy year", width: "" }, { key: "Built year", width: "" },{ key: "Foundation type", width: "" }, { key: "Last replace year", width: "" }, { key: "Squre footage", width: "" },{ key: "Rebuild cost", width: "" }, { key: "Closest fire station", width: "" }] },
    ]
    const handleClose = () => {
        setShow(false);
        setAction('');
        val?.setHideSelectModel(true);
        val?.setHideSelectTrim(true);
    }
    return (
        <>

            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-capitalize" id="createTaskLabel">View Detail</h5>
                    <InputButton type="button" className="btn-close" databsdismiss="modal" event={() => {handleClose() }} />
                </div>
                <div className="modal-body p-3">
                    <div className='container'>
                        <div className='row'>
                            {obj && obj?.map((o, index) => {
                                return <div className='col-12 mb-3' key={index}>
                                    <div className="card ribbon-box border shadow-none mb-lg-0">
                                        <div className="card-body">
                                            <div className="ribbon ribbon-yellow ribbon-shape">{o?.title}</div>
                                            <div className="ribbon-content text-muted mt-4">

                                                <div className="table-responsive table-card mt-4">
                                                    <table className="table align-middle table-nowrap mb-0" id="customerTable">
                                                        <thead className="table-orange">
                                                            <tr>
                                                                {o?.tbhead?.map((t, index) => {
                                                                    return <th className="" key={index} style={{ width: t?.width }}>{t?.key}</th>
                                                                })}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {o?.title == "User details" ?
                                                               <tr>
                                                               <td>1</td>
                                                               <td>{o?.user_data?.firstname}</td>
                                                               <td>{o?.user_data?.lastname}</td>
                                                               <td>{moment(o?.user_data?.birthdate).format("MM/DD/YYYY")}</td>
                                                               <td>{o?.user_data?.email}</td>                                                            
                                                               <td>{o?.user_data?.unit ? o?.user_data?.unit : "-"}</td>
                                                               <td>{o?.user_data?.address}</td>
                                                               <td>{o?.user_data?.city}</td>
                                                               <td>{o?.user_data?.state}</td>
                                                               <td>{o?.user_data?.zipcode}</td> 
                                                               <td>{moment(o?.user_data?.created_at).format("MM/DD/YYYY HH:mm:ss")}</td>                                                            
                                                           </tr>
                                                                : null}
                                                            {o?.title == "Insurance details" ?
                                                                <tr>
                                                                    <td>1</td>
                                                                    <td>{o?.insur_data?.is_curr_insurance == 1 ? "Yes" : "No"}</td>
                                                                    <td>{o?.insur_data?.insured ? o?.insur_data?.insured : "-"}</td>
                                                                    <td>{o?.insur_data?.living_home ? o?.insur_data?.living_home : "-"}</td>
                                                                    <td>{o?.insur_data?.is_auto_home == 1 ? "Yes" : "No"}</td>
                                                                    <td>{o?.insur_data?.take_insurance ? o?.insur_data?.take_insurance : "-"}</td>
                                                                    <td>{o?.insur_data?.life_event_policy ? o?.insur_data?.life_event_policy : "-"}</td>
                                                                    {/* <td>
                                                                <div className="d-flex gap-2 ">
                                                                    <InputButton className="btn btn-sm btn-success edit-item-btn" name="Edit" event={() => { alert(JSON.stringify(o.insur_data)) }}></InputButton>
                                                                </div>
                                                            </td> */}
                                                                </tr>
                                                                : null}
                                                            {o?.title == "Vehicle details" ?
                                                                o?.vehicle_data?.map((i, index) => {
                                                                    return <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{i?.year}</td>
                                                                        <td>{i?.make}</td>
                                                                        <td>{i?.model}</td>
                                                                        <td>{i?.trime_name}</td>
                                                                        <td>{i?.own_lease}</td>
                                                                        <td>{i?.mostly_used}</td>
                                                                        <td>{i?.miles_feq}</td>
                                                                        <td>{i?.miles_drive}</td>
                                                                    </tr>
                                                                })
                                                                : null}
                                                            {o?.title == "Self driver details" ?
                                                                <tr>
                                                                    <td>1</td>
                                                                    <td>{o?.me_data?.gender}</td>
                                                                    <td>{o?.me_data?.marital_type}</td>
                                                                    <td>{o?.me_data?.creadit_score}</td>
                                                                    <td>{o?.me_data?.eduction}</td>
                                                                    <td>{o?.me_data?.conti_insured_time}</td>
                                                                    <td>{o?.me_data?.current_company}</td>
                                                                    <td>{o?.me_data?.injury}</td>
                                                                    <td>{o?.me_data?.email}</td>
                                                                    <td>{o?.me_data?.discount_apply}</td>
                                                                    <td>{o?.me_data?.accident_ticket == 1 ? "Yes" : "No"}</td>
                                                                </tr>
                                                                : null}
                                                            {o?.title == "Spouse and additional driver details" ?
                                                                o?.sa_data?.length != 0 ? o?.sa_data?.map((i, index) => {
                                                                    return <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{i?.type ? i?.type == 0 ?"Spouse":"Additional":"-"}</td>
                                                                        <td>{i?.firstname ? i?.firstname :"-"}</td>
                                                                        <td>{i?.lastname ? i?.lastname : "-"}</td>
                                                                        <td>{i?.birthdate ? moment(i?.birthdate).format('YYYY-MM-DD'):"-"}</td>
                                                                        <td>{i?.gender ? i?.gender:"-"}</td>
                                                                        <td>{i?.relationship ? i?.relationship : "-"}</td>
                                                                        <td>{i?.marital_status ? i?.marital_status : "-"}</td>                                                                      
                                                                    </tr>
                                                                }):<tr><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td>                                                                      
                                                            </tr>
                                                                : null}
                                                                  {o?.title == "Accidents or tickets details" ?
                                                                <tr>
                                                                    <td>1</td>
                                                                    <td>{o?.at_data?.my_faults ? o?.at_data?.my_faults :"0"}</td>
                                                                    <td>{o?.at_data?.not_my_faults ?o?.at_data?.not_my_faults :"0"}</td>
                                                                    <td>{o?.at_data?.clamis?o?.at_data?.clamis : "0"}</td>
                                                                    <td>{o?.at_data?.ticket?o?.at_data?.ticket:"0"}</td>
                                                                    <td>{o?.at_data?.low_tickets?o?.at_data?.low_tickets:"0"}</td>
                                                                    <td>{o?.at_data?.high_tickets?o?.at_data?.high_tickets:"0"}</td>
                                                                    <td>{o?.at_data?.major_violations?o?.at_data?.major_violations:"0"}</td>
                                                                    <td>{o?.at_data?.minor_violations?o?.at_data?.minor_violations:"0"}</td>
                                                                    <td>{o?.at_data?.DUI_DWI?o?.at_data?.DUI_DWI:"0"}</td>
                                                                    <td>{o?.at_data?.others?o?.at_data?.others:"0"}</td>
                                                                </tr>
                                                                : null}
                                                               {o?.title == "Home details" ?
                                                                <tr>
                                                                    <td>1</td>
                                                                    <td>{o?.home_data?.home_type ? o?.home_data?.home_type : "-"}</td>
                                                                    <td>{o?.home_data?.unit?o?.home_data?.unit : "-"}</td>
                                                                    <td>{o?.home_data?.address?o?.home_data?.address:"-"}</td>
                                                                    <td>{o?.home_data?.city?o?.home_data?.city:"-"}</td>
                                                                    <td>{o?.home_data?.state?o?.home_data?.state:"-"}</td>
                                                                    <td>{o?.home_data?.zipcode?o?.home_data?.zipcode:"-"}</td>
                                                                    <td>{o?.home_data?.own_home?o?.home_data?.own_home:"-"}</td>
                                                                    <td>{o?.home_data?.buy_year?o?.home_data?.buy_year:"-"}</td>
                                                                    <td>{o?.home_data?.built_year?o?.home_data?.built_year:"-"}</td>
                                                                    <td>{o?.home_data?.foundation_type?o?.home_data?.foundation_type:"-"}</td>
                                                                    <td>{o?.home_data?.last_replace_year?o?.home_data?.last_replace_year:"-"}</td>
                                                                    <td>{o?.home_data?.squre_footage?o?.home_data?.squre_footage:"-"}</td>
                                                                    <td>{o?.home_data?.rebuild_cost?o?.home_data?.rebuild_cost:"-"}</td>
                                                                    <td>{o?.home_data?.closest_fire_station?o?.home_data?.closest_fire_station:"-"}</td>
                                                                </tr>
                                                                : null}
                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                            })}

                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-end mt-3 mb-1">
                        <InputButton type="button" className="btn w-sm btn-light" databsdismiss="modal" name="Close" event={() => { handleClose() }} />
                        <InputButton className="btn w-sm btn-success" type="button" name="Ok" event={() => { handleClose() }} />

                    </div>
                </div>
            </div>



        </>
    )
}

export default ViewInsurance