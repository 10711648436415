import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import RadioInput from "../../../comp/js/RadioInput";
import ButtonInput from "../../../comp/js/ButtonInput";
import "../css/ManualStart.css";
import TextFieldInput from "../../../comp/js/TextFieldInput";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {  HOME_START, INSURANCE_COMPANY_IMAGE_PATH, VEHICLE_DETAIL } from "../../../services/UserRoutes";
import CheckboxInput from "../../../comp/js/CheckboxInput";
import AnotherDriver from "./AnotherDriver";
import { BsInfoCircle } from "react-icons/bs";
import { AiOutlineCheck } from "react-icons/ai";
import { sub } from "date-fns/fp";
import { useDispatch, useSelector } from "react-redux";
import { getInsuranceCompany, saveDriverDetail, saveInsuranceDetail, saveManualStartDetail, storeDriverDetail } from "../../../../redux/action/UserAction";
import SearchSelect from '../../../comp/js/SearchSelect';
import Avatar from 'react-avatar';
import { differenceInYears, parse } from "date-fns"
import { color,que_col,que_value_col, requiredFeild, showError } from "../../../services/Methods";
import { UserValContext } from "../../../context/UserContextProvider";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const DriverDetails = () => {

  const { submitting,dot_color ,setSubmitting,button_color} = useContext(UserValContext)

  const dispatch = useDispatch();
  const {pathname} = useLocation()
  let pageLoaded = false
  useEffect(() => {
    if (!pageLoaded) {
      dispatch(getInsuranceCompany())
      pageLoaded = true
    }
  }, [pageLoaded])
  let insuranceCompany = useSelector((state) => state?.UserReducer?.userInsuranceCompany)?.map((company, index) => {
    return { value: company?.company_name, label: company?.company_name, image: company?.image ? `${INSURANCE_COMPANY_IMAGE_PATH}${company?.image}` : ' ' }
  });

  let details = useSelector((state) => state?.persistedReducer?.userDriverDetail);
  let { userManualStart, userManualStartDetail, userVehicleSelect, userVehicleDetail } = useSelector((state) => state?.persistedReducer)


  const [validation, setValidation] = useState(yup.string().required(requiredFeild));

  const [valueObj, setValueObj] = useState(Object.keys(details).length > 0 ? details : { status: false, injury: '', conti_insured_time: '', accidents: "", gender: "", marital_status: "", credit_score: "", education: "", email: userManualStartDetail?.email ? userManualStartDetail?.email :'', discount: ['Employed full time', 'Set up auto pay', 'Go paperless'], another_driver1: "No", current_company: '' });

  const [validationObj, setValidationObj] = useState({
    accidents: yup.string().required(requiredFeild),
    gender: yup.string().required(requiredFeild),
    marital_status: yup.string().required(requiredFeild),
    credit_score: yup.string().required(requiredFeild),
    education: yup.string().required(requiredFeild),
    conti_insured_time: yup.string().required(requiredFeild),
    injury: yup.string().required(requiredFeild),
    current_company: yup.object().required(requiredFeild),
    email: yup.string().email("Invalid Email address").required("Email is required"), // discount: yup.string().required(requiredFeild), another_driver1: yup.string().required(requiredFeild),
  });

  const numberStyle = { padding: "5px 7px", fontSize: '18px' };

  const [ticketFlag, setTicketFlag] = useState(false);
  const [violationCount, setViolationCount] = useState(0);
  const [object, setObjects] = useState([]);
  // const [status, setStatus] = useState(false);

  const ticketButtonStyle = { backgroundColor: dot_color, height: "40px", width: "40px", };
  const desStyle = { fontSize: "13px", fontWeight: "200", color: "#606569", marginTop: "5px", };

  const [drivers, setDrivers] = useState(Object.keys(details).length > 0 && details?.drivers ? details?.drivers : []);
  const [tickets, setTickets] = useState(details?.tickets ? details.tickets : [{ name: "Accidents that were my fault", value: 0, des: "" }, { name: "Accidents that were not my fault", value: 0, des: "" }, { name: "Claims", value: 0, des: "(Weather, vandalism, uninsured motorist damage, etc.)", }, { name: "Tickets/violations", value: 0, des: "" }]);

  const [violations, setViolations] = useState(details?.violations ? details?.violations : [{ name: "Speeding tickets", value: 0, des: "(5-24 MPH over limit)" }, { name: "Speeding tickets", value: 0, des: "(25+ MPH over limit)" }, { name: "Major violation", value: 0, des: "(Driving with suspended license, reckless driving, etc.)", }, { name: "Minor violation", value: 0, des: "(Failure to stop at red light, failure to use headlights, etc.)" }, { name: "DUI/DWI", des: "", value: 0 }, { name: "Other", des: "", value: 0 },]);

  const [flag, setFlag] = useState(tickets[3]?.value < 1 ? false : true);

  const navigate = useNavigate();

  let insur_obj = { is_curr_insurance: userManualStart?.car_insurance == 'Yes' ? 1 : 0, insured: userManualStart?.insured == '' ? null : userManualStart?.insured, living_home: userManualStart?.rent_home, is_auto_home: userManualStart?.auto_home == 'Yes' ? 1 : 0, take_insurance: userManualStart?.car_insurance, life_event_policy: userManualStart?.life_event == '' ? null : userManualStart?.life_event?.value }

  let vehicle_obj = Array.from({ length: userVehicleSelect?.checkArray?.length + 1 }, (v, i) => i + 1).map((v, index) => {
    return { year: userVehicleSelect[`vehicle${v}0`]?.value, make: userVehicleSelect[`vehicle${v}1`]?.value, model: userVehicleSelect[`vehicle${v}2`]?.value, trime_name: userVehicleSelect[`vehicle${v}3`]?.value, own_lease: userVehicleDetail[`own_vehicle${v}`], mostly_used: userVehicleDetail[`use_vehicle${v}`], miles_feq: userVehicleDetail[`frequency${v}`]?.value, miles_drive: userVehicleDetail[`mileage${v}`] }
  });

  let at_driver = { my_faults: tickets[0]?.value, not_my_faults: tickets[1]?.value, clamis: tickets[2]?.value, ticket: tickets[3]?.value, low_tickets: violations[0]?.value, high_tickets: violations[2]?.value, major_violations: violations[3]?.value, minor_violations: violations[4]?.value, DUI_DWI: violations[5]?.value, others: violations[6]?.value }

  const {
    setValues,
    values,
    setFieldValue,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldError
  } = useFormik({
    initialValues: valueObj,
    validationSchema: yup.object().shape(validationObj),
    onSubmit: (values, { resetForm }) => {

      let user_obj = { address: userManualStartDetail?.address, unit: userManualStartDetail?.unit == '' ? null : userManualStartDetail?.unit, city: userManualStartDetail?.city, state: userManualStartDetail?.state?.value, zipcode: userManualStartDetail?.zipcode, firstname: userManualStartDetail?.fname, lastname: userManualStartDetail?.lname, birthdate: moment(userManualStartDetail?.bdate, "MM/DD/YYYY").format("YYYY-MM-DD"), email: values?.email }

      let me_driver = { gender: values?.gender, marital_type: values?.marital_status, creadit_score: values?.credit_score, eduction: values?.education, conti_insured_time: values?.conti_insured_time, current_company: values?.current_company?.value, injury: values?.injury, email: values?.email, discount_apply: values?.discount, accident_ticket: values?.accidents == 'Yes' ? 1 : 0 }

      let sa_driver = drivers?.length > 0 && drivers.map((d, index) => {
        return { type: values?.status && d==1 ? 0 : 1, firstname: values[`fname${d + 1}`], lastname: values[`lname${d + 1}`], birthdate: moment(values[`bdate${d + 1}`], "MM/DD/YYYY").format("YYYY-MM-DD"), gender: values[`gender${d + 1}`], relationship: values[`relationship${d + 1}`], marital_status: values[`marital_status${d + 1}`] }
      })

      let insurance_obj = { user_obj, insur_obj, vehicle_obj, home_obj: {}, driver_obj: { me_driver, sa_driver: sa_driver?.length > 0 ? sa_driver : [], at_driver } }

      let old_email = userManualStartDetail?.email ? userManualStartDetail?.email:null;
      let driverDetailsVal ={ ...values, drivers: drivers, tickets: tickets, violations: violations, insurance_obj }
      
      // dispatch(saveDriverDetail(driverDetailsVal));
      
      //  if email changes in driver page , to update 
      // dispatch(saveManualStartDetail({...userManualStartDetail,email:values?.email}))
      
      // dispatch(saveInsuranceDetail(insurance_obj,toast,navigate))
      // if (values) navigate(COVERAGE_SELECTION);
      
      if(userManualStart?.auto_home == 'Yes'){
        if (values) navigate(HOME_START);
        dispatch(saveDriverDetail(driverDetailsVal));
      }
      else {
        dispatch(storeDriverDetail(old_email,{vehicle_obj,home_obj:{},driver_obj:{me_driver,sa_driver:sa_driver?.length > 0 ? sa_driver :[],at_driver}},navigate,driverDetailsVal,toast,userManualStartDetail,pathname,userManualStart?.auto_home == 'Not right now' ? 'coverage' :null))
        // dispatch(saveInsuranceDetail(insurance_obj,toast,navigate))
        // if (values) navigate(COVERAGE_SELECTION);hhh
      }
    },
  });


  const handleAnotherDriver = (value, name, count, spouse) => {
    setFieldValue(name, value);
    // setFieldValue(`another_driver${count + 1}`, "No");

    // if (value == "Yes" && drivers.includes(count)) return;
    if (value == "Yes" && drivers.includes(count) && count != 1) return
    else if (value == "No" && drivers.includes(count)) {
      setDrivers(drivers.slice(0, -1));
      handleRemoveDriver(count)
    } else if (value == "Yes" && drivers.length <= 4) {
      setFieldValue(`another_driver${count + 1}`, "No");
      let val = valueObj;
      let schema = validationObj;

      if (spouse != 'spouse') {
        val[`another_driver${count + 1}`] = "No";
        val[`marital_status${count + 1}`] = "";
        val[`relationship${count + 1}`] = "";
      }

      val[`gender${count + 1}`] = "";
      val[`fname${count + 1}`] = "";
      val[`lname${count + 1}`] = "";
      val[`bdate${count + 1}`] = "";

      schema[`gender${count + 1}`] = validation;
      schema[`lname${count + 1}`] = validation;
      schema[`fname${count + 1}`] = validation;
      schema[`bdate${count + 1}`] = yup.date().required(requiredFeild).max(sub({ years: 16 }, new Date()), "User must be 16 years old");

      if (spouse != 'spouse') {
        schema[`marital_status${count + 1}`] = validation;
        schema[`relationship${count + 1}`] = validation;
      }
      setDrivers((drivers) => [...drivers, count]);

      setValidationObj(schema);
      setValueObj(val);
    }
  };
  const updateVal = (name,val) =>{
    setValues(valueObj)
  }
  const handleRemoveDriver = (count, remove) => {

    let schema = validationObj;
    let val = valueObj;

    if (drivers.length == 1 && values?.status == true) {
      setFieldValue('another_driver1', 'No')
      setFieldValue('status', false);
      let array = drivers
      array.shift(1)
      setDrivers(array)
      let n = `another_driver${count}`;
      delete val[n];
      // delete schema[n]

      delete val[`gender${count}`];
      delete val[`marital_status${count}`];
      // delete val[`email${count}`];
      delete val[`relationship${count}`];
      delete val[`fname${count}`];
      delete val[`lname${count}`];
      delete val[`bdate${count}`];

      delete schema[`gender${count}`];
      delete schema[`marital_status${count}`];
      delete schema[`fname${count}`];
      delete schema[`lname${count}`];
      delete schema[`bdate${count}`];
      delete schema[`relationship${count}`];

    }
    else {
      let length = drivers.length + 1;
      setDrivers(drivers.slice(0, -1));
      if (remove == 'remove') {
        setFieldValue(`another_driver${count}`, "No");
      }

      let n = `another_driver${length}`;

      delete val[n];
      // delete schema[n]

      delete val[`gender${length}`];
      delete val[`marital_status${length}`];
      // delete val[`email${length}`];
      delete val[`relationship${length}`];
      delete val[`fname${length}`];
      delete val[`lname${length}`];
      delete val[`bdate${length}`];

      delete schema[`gender${length}`];
      delete schema[`marital_status${length}`];
      delete schema[`fname${length}`];
      delete schema[`lname${length}`];
      // delete schema[`email${length}`];
      delete schema[`bdate${length}`];
      delete schema[`relationship${length}`];
    }
    setValueObj({...values,...val});
    setValidationObj(schema);
  };

  const handleDecrement = (index, name, value) => {
    object.map((o) => {
      Array.from({ length: o.value }, (_, i) => i).map(() =>
        setViolations(
          violations.map(({ name, des, value }, id) => {
            if (id == o.id && value > 0) {
              setObjects(
                object.map(({ id, value }) => {
                  if (id == o.id && value > 0) return { id, value: value - 1 };
                  else return { id, value };
                })
              );
              return { name, des, value: value - 1 };
            } else {
              return { name, des, value: value };
            }
          })
        )
      );
    });
  };

  const decrement = (index, name, value) => {
    if (name == "tickets") {
      if (index == 3 && value <= 1) setFlag(false);
      if (index == 3 && value == 1) {
        setViolationCount(0);
        setViolations(
          violations.map(({ name, des, value }, index) => {
            return { name, des, value: 0 };
          })
        );
        // removeEffect(6)
        setObjects([]);
      } else if (index == 3 && value > 0) {
        handleDecrement(index, name, value);
      }
      setTickets(
        tickets.map(({ name, des, value }, ind) => {
          if (index == ind && value > 0) return { value: value - 1, des, name };
          else return { name, des, value };
        })
      );
    } else if (name == "violations") {
      setTickets(tickets.map((item, ind) => {
        if (ind == 3){
          if(item?.value <= 1) setFlag(false)
          return { ...item, value: item?.value - 1 };
        }
        else return item;
      }));
      setViolations(
        violations.map(({ name, des, value }, ind) => {
          if (index == ind && value > 0) return { value: value - 1, des, name };
          else return { name, des, value };
        })
      );
    }
  };

  const increment = (index, name, val) => {
    if (name == "tickets") {
      setTickets(
        tickets.map(({ value, des, name }, ind) => {
          if (index == 3) setFlag(true);
          if (index == ind && value < 9) return { value: value + 1, des, name };
          else return { value, des, name };
        })
      );
    } else if (name == "violations") {
      setViolationCount((violationCount) => violationCount + 1);
      setViolations(
        violations.map(({ name, des, value }, ind) => {
          setObjects([...object, { id: index, value: val + 1 }]);

          if (index == ind && value < 9) return { value: value + 1, des, name };
          else return { name, des, value };
        })
      );
      setTickets(
        tickets.map(({ value, des, name }, ind) => {
          if (ind == 3) return { value: violationCount + 1, des, name };
          else return { value, des, name };
        })
      );
    }
  };

  const renderTickets = () => {
    return (
      <>
        <div className="row g-2 mt-3">
          <div className={`${que_col} col-12`}>
            <div className="d-flex">
              <div className="w-100">
                <h3>How many of these did you have?</h3>
              </div>
            </div>
          </div>
          <div className={`${que_value_col} col-12 border-bottom`}>
            {tickets &&
              tickets.map(({ value, name, des }, index) => {
                return (
                  <>
                    <div className="d-flex justify-content-between my-3 ">
                      <h5>
                        {name}
                        <p className="text-start w-w-100" style={desStyle}>
                          {des}
                        </p>
                      </h5>
                      <div className="d-flex justify-content-between align-items-center">
                        <span
                          className={`rounded-circle text-white fw-bolder fs-18 cursor-pointer d-flex justify-content-center align-items-center ${value < 1 ? "pe-none opacity-50" : ""
                            }`}
                          style={ticketButtonStyle}
                          onClick={
                            value <= 9 || (index == 3 && value >= 0)
                              ? () => decrement(index, "tickets", value)
                              : ""
                          }
                        >
                          -
                        </span>
                        <span className="cursor-pointer fw-normal fs-5 mx-2">
                          {value}
                        </span>
                        <span
                          className={`rounded-circle text-white fw-bolder fs-18 cursor-pointer d-flex justify-content-center align-items-center ${(index == 3 && value >= 1) || value >= 9
                            ? "pe-none opacity-50"
                            : ""
                            }`}
                          style={ticketButtonStyle}
                          onClick={
                            (index == 3 && value >= 1) || value >= 9
                              ? ""
                              : () => increment(index, "tickets", value)
                          }
                        >
                          +
                        </span>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </>
    );
  };

  const renderViolations = () => {
    return (
      <>
        <div className="row g-2 mt-3">
          <div className={`${que_col} col-12`}>
            <div className="d-flex">
              <div className="w-100">
                <h3>How many of each violation?</h3>
              </div>
            </div>
          </div>
          <div className={`${que_value_col} col-12 border-bottom`}>
            {violations &&
              violations.map(({ value, name, des }, index) => {
                return (
                  <>
                    <div className="d-flex justify-content-between my-3 ">
                      <h5>
                        {name}
                        <p className="text-start w-w-100" style={desStyle}>
                          {des}
                        </p>
                      </h5>
                      <div className="d-flex justify-content-between align-items-center">
                        <span
                          className={`rounded-circle text-white fw-bolder fs-18 cursor-pointer d-flex justify-content-center align-items-center ${value < 1 ? "pe-none opacity-50" : ""
                            }`}
                          style={ticketButtonStyle}
                          onClick={
                            value <= 9
                              ? () => decrement(index, "violations", value)
                              : ""
                          }
                        >
                          -
                        </span>
                        <span className="cursor-pointer fw-normal fs-5 mx-2">
                          {value}
                        </span>
                        <span
                          className={`rounded-circle text-white fw-bolder fs-18 cursor-pointer d-flex justify-content-center align-items-center ${value >= 9 ? "pe-none opacity-50" : ""
                            }`}
                          style={ticketButtonStyle}
                          onClick={
                            value >= 9
                              ? ""
                              : () => increment(index, "violations", value)
                          }
                        >
                          +
                        </span>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (values?.accidents == "Yes") setTicketFlag(true);
    else {
      setTicketFlag(false);
      setFlag(false);
      setTickets(
        tickets.map(({ name, des, value }) => {
          return { name, des, value: 0 };
        })
      );
      setViolations(
        violations.map(({ name, des, value }) => {
          return { name, des, value: 0 };
        })
      );
    }

    if (values?.marital_status == 'Married') {
      setFieldValue('status', true)
      if (drivers.includes(1)) return
      else handleAnotherDriver('Yes', "another_driver1", 1, 'spouse')
    }
    else if (values?.marital_status != 'Married') {
      if (values?.status == true && drivers.length > 0) handleRemoveDriver(2)
      setFieldValue('status', false)
    }

  }, [values?.marital_status,values?.accidents]);

  const handleDiscount = (value, name) => {
    if (values?.discount && values?.discount?.includes(value))
      setFieldValue(
        name,
        values?.discount?.filter((d) => d != value)
      );
    else setFieldValue(name, [...values?.discount, value]);
  };
  // 2000-01-23

  const calculateDate = () => {
    const date = parse(userManualStartDetail?.bdate, "mm/dd/yyyy", new Date())
    const age = differenceInYears(new Date(), date)
    return age
  }

  return (
    <>
      <div
        className="container-fluid w-100 pt-3 g-3 mb-5"
        style={{ paddingTop: "20px" }}
      >
        <div className="row g-2 mb-4">
          <h2>Tell us about your drivers</h2>
        </div>
        <div className="col-4 text-start text-color-gray">
          {drivers?.length > 0 && `(Driver 1 of ${drivers?.length})`}
        </div>
        <div className="mb-3 pb-3">
          {
            userManualStartDetail?.fname && <h3 className="mt-1">
              <Avatar size="30" color={button_color} round={true} name={`${userManualStartDetail?.fname}  ${userManualStartDetail?.lname}`} /><span className="ms-2 fs-19">{`${userManualStartDetail?.fname}  ${userManualStartDetail?.lname} -- ${calculateDate()} yrs`}</span>
            </h3>
          }
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row g-2 mt-3">
            <div className={`${que_col} col-12`}>
              <div className="d-flex">
                <span
                  className="me-2 badge border border-user-secondary rounded-circle text-dark"
                  style={{ height: "28px", width: "28px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: values?.gender ? dot_color : "", padding: "2px" }}
                >
                  {values?.gender ? (
                    <AiOutlineCheck
                      style={{ color: "white", fontSize: "20px" }}
                    />
                  ) : (
                    <div style={numberStyle}>1</div>
                  )}
                </span>
                <div className="w-100">
                  <h3>What’s your gender?</h3>
                  <span className="text-start w-w-100">
                    Please select the gender listed on your driver’s license.
                  </span>
                </div>
              </div>
            </div>
            <div className={`${que_value_col} col-12`}>
              <div
                className="btn-group d-flex flex-column gap-3"
                role="group"
                aria-label="Basic radio toggle button group"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.gender}
              >
                <RadioInput name={"gender"} value={"Male"} w={"w-100"} val={values?.gender} error={errors.gender} touch={touched.gender} checked={values?.gender == "Male" ? true : false} />
                <RadioInput value={"Female"} name={"gender"} w={"w-100"} val={values?.gender} error={errors.gender} touch={touched.gender} checked={values?.gender == "Female" ? true : false}
                />
                <RadioInput value={"Non Binary"} name={"gender"} w={"w-100"} val={values?.gender} error={errors.gender} touch={touched.gender} checked={values?.gender == "Non Binary" ? true : false}
                />
              </div>
              {errors.gender && (<span className="text-danger fs-4">{showError(errors.gender, submitting)}</span>)}
            </div>
          </div>
          <div className="row g-2 mt-3">
            <div className={`${que_col} col-12`}>
              <div className="d-flex">
                <span
                  className="me-2 badge border border-user-secondary rounded-circle text-dark"
                  style={{
                    height: "28px",
                    width: "28px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: values?.marital_status ? dot_color : "",
                    padding: "2px",
                  }}
                >
                  {values?.marital_status ? (
                    <AiOutlineCheck
                      style={{ color: "white", fontSize: "20px" }}
                    />
                  ) : (
                    <div style={numberStyle}>2</div>
                  )}
                </span>
                <div className="w-100">
                  <h3>What’s your marital status?</h3>
                </div>
              </div>
            </div>
            <div className={`${que_value_col} col-12`}>
              <div
                className="btn-group d-flex flex-column gap-3"
                role="group"
                aria-label="Basic radio toggle button group"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.marital_status}
              >
                <RadioInput value={"Single"} name={"marital_status"} w={"w-100"} val={values?.marital_status} error={errors.marital_status} touch={touched.marital_status} checked={values?.marital_status == "Single" ? true : false} />
                <RadioInput value={"Married"} name={"marital_status"} w={"w-100"} val={values?.marital_status} error={errors.marital_status} touch={touched.marital_status} checked={values?.marital_status == "Married" ? true : false} />
                <RadioInput value={"Divorced"} name={"marital_status"} w={"w-100"} val={values?.marital_status} error={errors.marital_status} touch={touched.marital_status} checked={values?.marital_status == "Divorced" ? true : false} />
                <RadioInput value={"Widowed"} name={"marital_status"} w={"w-100"} val={values?.marital_status} error={errors.marital_status} touch={touched.marital_status} checked={values?.marital_status == "Widowed" ? true : false} />
              </div>
              {errors.marital_status && (<span className="text-danger fs-4">{showError(errors.marital_status, submitting)}</span>)}
            </div>
          </div>
          <div className="row g-2 mt-3">
            <div className={`${que_col} col-12`}>
              <div className="d-flex">
                <span
                  className="me-2 badge border border-user-secondary rounded-circle text-dark"
                  style={{
                    height: "fit-content",
                    backgroundColor: values?.credit_score ? dot_color : "",
                    padding: "2px",
                  }}
                >
                  {values?.credit_score ? (
                    <AiOutlineCheck
                      style={{ color: "white", fontSize: "20px" }}
                    />
                  ) : (
                    <div style={numberStyle}>3</div>
                  )}
                </span>
                <div className="w-100">
                  <h3>What’s your credit score?</h3>
                  <span className="text-start w-w-100">
                    Select "Good" if you are unsure.
                  </span>
                </div>
              </div>
            </div>
            <div className={`${que_value_col} col-12`}>
              <div
                className="btn-group d-flex flex-column gap-3"
                role="group"
                aria-label="Basic radio toggle button group"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.credit_score}
                error={errors.credit_score}
                touch={touched.credit_score}
              >
                <RadioInput value={"Excellent (720+)"} name={"credit_score"} w={"w-100"} val={values?.credit_score} error={errors.credit_score} touch={touched.credit_score} checked={
                  values?.credit_score == "Excellent (720+)" ? true : false} />
                <RadioInput value={"Good (680-719)"} name={"credit_score"} w={"w-100"} val={values?.credit_score} error={errors.credit_score} touch={touched.credit_score} checked={
                  values?.credit_score == "Good (680-719)" ? true : false} />
                <RadioInput value={"Average (580-679)"} name={"credit_score"} w={"w-100"} val={values?.credit_score} error={errors.credit_score} touch={touched.credit_score} checked={
                  values?.credit_score == "Average (580-679)" ? true : false} />
                <RadioInput value={"Poor (Below 580)"} name={"credit_score"} w={"w-100"} val={values?.credit_score} error={errors.credit_score} touch={touched.credit_score} checked={
                  values?.credit_score == "Poor (Below 580)" ? true : false} />
              </div>
              {errors.credit_score && (<span className="text-danger fs-4">{showError(errors.credit_score, submitting)}</span>)}
            </div>
          </div>

          <div className="row g-2 mt-3">
            <div className={`${que_col} col-12`}>
              <div className="d-flex">
                <span
                  className="me-2 badge border border-user-secondary rounded-circle text-dark"
                  style={{
                    height: "28px",
                    width: "28px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: values?.education ? dot_color : "",
                    padding: "2px",
                  }}
                >
                  {values?.education ? (
                    <AiOutlineCheck
                      style={{ color: "white", fontSize: "20px" }}
                    />
                  ) : (
                    <div style={numberStyle}>4</div>
                  )}
                </span>
                <div className="w-100">
                  <h3>What’s the highest level of education you completed?</h3>
                </div>
              </div>
            </div>
            <div className={`${que_value_col} col-12`}>
              <div
                className="btn-group d-flex flex-column gap-3"
                role="group"
                aria-label="Basic radio toggle button group"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.education}
                error={errors.education}
                touch={touched.education}
              >
                <RadioInput value={"Doctoral degree"} name={"education"} w={"w-100"} val={values?.education} error={errors.education} touch={touched.education} checked={values?.education == "Doctoral degree" ? true : false} />
                <RadioInput value={"Master’s degree"} name={"education"} w={"w-100"} val={values?.education} error={errors.education} touch={touched.education} checked={values?.education == "Master’s degree" ? true : false} />
                <RadioInput value={"Bachelor’s degree"} name={"education"} w={"w-100"} val={values?.education} error={errors.education} touch={touched.education} checked={
                  values?.education == "Bachelor’s degree" ? true : false} />
                <RadioInput value={"High school diploma / GED"} name={"education"} w={"w-100"} val={values?.education} error={errors.education} touch={touched.education} checked={
                  values?.education == "High school diploma / GED" ? true : false} />
                <RadioInput value={"No diploma"} name={"education"} w={"w-100"} val={values?.education} error={errors.education} touch={touched.education} checked={values?.education == "No diploma" ? true : false} />
              </div>
              {errors.education && (<span className="text-danger fs-4">{showError(errors.education, submitting)}</span>)}
            </div>
          </div>

          <div className="row g-2 mt-3">
            <div className={`${que_col} col-12`}>
              <div className="d-flex">
                <span
                  className="me-2 badge border border-user-secondary rounded-circle text-dark"
                  style={{
                    height: "28px",
                    width: "28px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: values?.conti_insured_time ? dot_color : "",
                    padding: "2px",
                  }}
                >
                  {values?.conti_insured_time ? (
                    <AiOutlineCheck
                      style={{ color: "white", fontSize: "20px" }}
                    />
                  ) : (
                    <div style={numberStyle}>5</div>
                  )}
                </span>
                <div className="w-100">
                  <h3>How long have you been continuously insured?</h3>
                </div>
              </div>
            </div>
            <div className={`${que_value_col} col-12`}>
              <div
                className="btn-group d-flex flex-column gap-3"
                role="group"
                aria-label="Basic radio toggle button group"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.conti_insured_time}
                error={errors.conti_insured_time}
                touch={touched.conti_insured_time}
              >
                <RadioInput value={"More than 3 years"} name={"conti_insured_time"} w={"w-100"} val={values?.conti_insured_time} error={errors.conti_insured_time} touch={touched.conti_insured_time} checked={values?.conti_insured_time == "More than 3 years" ? true : false} />
                <RadioInput value={"1 to 3 years"} name={"conti_insured_time"} w={"w-100"} val={values?.conti_insured_time} error={errors.conti_insured_time} touch={touched.conti_insured_time} checked={values?.conti_insured_time == "1 to 3 years" ? true : false} />
                <RadioInput value={"6 to 12 months"} name={"conti_insured_time"} w={"w-100"} val={values?.conti_insured_time} error={errors.conti_insured_time} touch={touched.conti_insured_time} checked={values?.conti_insured_time == "6 to 12 months" ? true : false} />
                <RadioInput value={"Less than 6 months"} name={"conti_insured_time"} w={"w-100"} val={values?.conti_insured_time} error={errors.conti_insured_time} touch={touched.conti_insured_time} checked={values?.conti_insured_time == "Less than 6 months" ? true : false} />
                <RadioInput value={"No diploma"} name={"conti_insured_time"} w={"w-100"} val={values?.conti_insured_time} error={errors.conti_insured_time} touch={touched.conti_insured_time} checked={values?.conti_insured_time == "No diploma" ? true : false} />
              </div>
              {errors.conti_insured_time && (<span className="text-danger fs-4">{showError(errors.conti_insured_time, submitting)}</span>)}
            </div>
          </div>

          <div className="row g-2 mt-3">
            <div className={`${que_col} col-12`}>
              <div className="d-flex">
                <span
                  className="me-2 badge border border-user-secondary rounded-circle text-dark"
                  style={{ height: "28px", width: "28px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: values?.current_company ? dot_color : "", padding: "2px" }}
                >
                  {values?.current_company ? (
                    <AiOutlineCheck
                      style={{ color: "white", fontSize: "20px" }}
                    />
                  ) : (
                    <div style={numberStyle}>6</div>
                  )}
                </span>
                <div className="w-100">
                  <h3>Who is your current provider?</h3>
                  <span className="text-start w-w-100"> Select "Other" if your company is not on the list.</span>
                </div>
              </div>
            </div>
            <div className={`${que_value_col} col-12`}>
              <div
                className="btn-group d-flex flex-column gap-3"
                role="group"
                aria-label="Basic radio toggle button group"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.current_company}
                error={errors.current_company}
                touch={touched.current_company}
              >
                <SearchSelect placeholder={"Select current insurance company"} isDisabled={false} name={"current_company"} value={values?.current_company} setFieldValue={setFieldValue} error={errors.current_company} touched={touched.current_company} opt={insuranceCompany} />
              </div>
            </div>
          </div>
          <div className="row g-2 mt-3">
            <div className={`${que_col} col-12`}>
              <div className="d-flex">
                <span
                  className="me-2 badge border border-user-secondary rounded-circle text-dark"
                  style={{
                    height: "28px",
                    width: "28px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: values?.injury ? dot_color : "",
                    padding: "2px",
                  }}
                >
                  {values?.injury ? (
                    <AiOutlineCheck
                      style={{ color: "white", fontSize: "20px" }}
                    />
                  ) : (
                    <div style={numberStyle}>7</div>
                  )}
                </span>
                <div className="w-100">
                  <h3>Which bodily injury limits are closest to your current coverage?</h3>
                  <span className="text-start w-w-100"> Choosing the closest match helps us find the best protection for you.</span>
                </div>
              </div>
            </div>
            <div className={`${que_value_col} col-12`}>
              <div
                className="btn-group d-flex flex-column gap-3"
                role="group"
                aria-label="Basic radio toggle button group"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.injury}
                error={errors.injury}
                touch={touched.injury}
              >
                <RadioInput value={"$25k / $50k"} name={"injury"} w={"w-100"} val={values?.injury} error={errors.injury} touch={touched.injury} checked={values?.injury == "$25k / $50k" ? true : false} />
                <RadioInput value={"$50k / $100k"} name={"injury"} w={"w-100"} val={values?.injury} error={errors.injury} touch={touched.injury} checked={values?.injury == "$50k / $100k" ? true : false} />
                <RadioInput value={"$100k / $300k"} name={"injury"} w={"w-100"} val={values?.injury} error={errors.injury} touch={touched.injury} checked={values?.injury == "$100k / $300k" ? true : false} />
                <RadioInput value={"$250k / $500k"} name={"injury"} w={"w-100"} val={values?.injury} error={errors.injury} touch={touched.injury} checked={values?.injury == "$250k / $500k" ? true : false} />
                <RadioInput value={"I don’t know"} name={"injury"} w={"w-100"} val={values?.injury} error={errors.injury} touch={touched.injury} checked={values?.injury == "I don’t know" ? true : false} />
              </div>
              {errors.injury && (<span className="text-danger fs-4">{showError(errors.injury, submitting)}</span>)}
            </div>
          </div>

          <div className="row g-2 mt-3">
            <div className={`${que_col} col-12`}>
              <div className="d-flex">
                <span
                  className="me-2 badge border border-user-secondary rounded-circle text-dark"
                  style={{
                    height: "28px",
                    width: "28px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: values?.accidents ? dot_color : "",
                    padding: "2px",
                  }}
                >
                  {values?.accidents ? (
                    <AiOutlineCheck
                      style={{ color: "white", fontSize: "20px" }}
                    />
                  ) : (
                    <div style={numberStyle}>8</div>
                  )}
                </span>
                <div className="w-100">
                  <h3>Any accidents or tickets in the last 3 years?</h3>
                  <span
                    className="description fw-bold"
                    style={{ color: color }}
                  >
                    <BsInfoCircle className="fs-5 me-1" />
                    <a
                      href="#"
                      style={{ color: color }}
                      className="m-0 text-decoration-none"
                    >
                      Learn about why we ask for incident history
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className={`${que_value_col} col-12`}>
              <div
                className="btn-group d-flex flex-row gap-3"
                role="group"
                aria-label="Basic radio toggle button group"
                id="accidents"
                name="accidents"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.accidents}
              >
                <RadioInput value={"Yes"} name={"accidents"} w={"w-50"} val={values?.accidents} error={errors.accidents} touch={touched.accidents} checked={values?.accidents == "Yes" ? true : false} />
                <RadioInput value={"No"} name={"accidents"} w={"w-50"} val={values?.accidents} error={errors.accidents} touch={touched.accidents} checked={values?.accidents == "No" ? true : false} />
              </div>
              {errors.accidents && (<span className="text-danger fs-4">{showError(errors.accidents, submitting)}</span>)}
            </div>
          </div>
          {ticketFlag && renderTickets()}
          {flag && renderViolations()}

          <div className="row g-2 mt-3">
            <div className={`${que_col} col-12`}>
              <div className="d-flex">
                <span
                  className="me-2 badge border border-user-secondary rounded-circle text-dark"
                  style={{
                    height: "28px",
                    width: "28px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: values?.email ? dot_color : "",
                    padding: "2px",
                  }}
                >
                  {values?.email ? (
                    <AiOutlineCheck
                      style={{ color: "white", fontSize: "20px" }}
                    />
                  ) : (
                    <div style={numberStyle}>9</div>
                  )}
                </span>
                <div className="w-100">
                  <h3>
                    Where would you like to receive a copy of your quotes?
                  </h3>
                  <span className="text-start w-w-100">
                    Honestly, we won’t spam you.
                  </span>
                </div>
              </div>
            </div>
            <div className={`${que_value_col} col-12`}>
              <div
                className="btn-group d-flex flex-column gap-3"
                role="group"
                aria-label="Basic radio toggle button group"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.email}
              >
                <TextFieldInput
                  w={"w-100"}
                  placeholder={"Email"}
                  error={errors.email}
                  touched={touched.email}
                  name={"email"}
                  value={values?.email}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>
            </div>
          </div>
          <div className="row g-2 mt-3">
            <div className={`${que_col} col-12`}>
              <div className="d-flex">
                <span
                  className="me-2 badge border border-user-secondary rounded-circle text-dark"
                  style={{ height: '28px', width: '28px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: (values?.discount?.length > 0) ? dot_color : '', padding: '2px' }}
                >
                  {(values?.discount?.length > 0) ? <AiOutlineCheck style={{ color: 'white', fontSize: '20px' }} /> : <div style={numberStyle}>10</div>}
                </span>
                <div className="w-100">
                  <h3>Do any of these discounts apply?</h3>
                  <span className="text-start w-w-100">
                    Check all that apply (optional).
                  </span>
                </div>
              </div>
            </div>
            <div className={`${que_value_col} col-12`}>
              <div
                className="btn-group d-flex flex-column gap-3"
                role="group"
                aria-label="Basic radio toggle button group"
                onChange={(e) => handleDiscount(e.target.value, "discount")}
                onBlur={handleBlur}
                value={values?.discount}
              >
                <CheckboxInput checked={values?.discount?.includes("Employed full time") ? true : false} w={"w-100"} isDisabled={false} name={"Employed full time"} value={"Employed full time"} val={values?.discount?.includes("Employed full time") ? "Employed full time" : ""} />
                <CheckboxInput checked={values?.discount?.includes("Active military or veteran") ? true : false} w={"w-100"} isDisabled={false} name={"Active military or veteran"} value={"Active military or veteran"} val={values?.discount?.includes("Active military or veteran") ? "Active military or veteran" : ""} />
                <CheckboxInput w={"w-100"} isDisabled={false} name={"Pay in full at start of policy"} value={"Pay in full at start of policy"} val={values?.discount?.includes("Pay in full at start of policy") ? "Pay in full at start of policy" : ""} checked={values?.discount?.includes("Pay in full at start of policy") ? true : false} />
                <CheckboxInput w={"w-100"} isDisabled={false} name={"Set up auto pay"} value={"Set up auto pay"} val={values?.discount?.includes("Set up auto pay") ? "Set up auto pay" : ""} checked={values?.discount?.includes("Set up auto pay") ? true : false} />
                <CheckboxInput w={"w-100"} isDisabled={false} name={"Go paperless"} value={"Go paperless"} val={values?.discount?.includes("Go paperless") ? "Go paperless" : ""} checked={values?.discount?.includes("Go paperless") ? true : false} />
              </div>
            </div>
          </div>
          {
            values?.marital_status != 'Married' && <div className="row g-2 mt-3">
              <div className={`${que_col} col-12`}>
                <div className="d-flex">
                  <span
                    className="me-2 badge border border-user-secondary rounded-circle text-dark"
                    style={{ height: "28px", width: "28px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: values?.another_driver1 ? dot_color : "", padding: "2px"}}>
                    {values?.another_driver1 ? (<AiOutlineCheck style={{ color: "white", fontSize: "20px" }}/>) : (<div style={numberStyle}>10</div>)}
                  </span>
                  <div className="w-100">
                    <h3>Need to add another driver?</h3>
                    <span className="text-start w-w-100">
                      You can add up to 4 drivers.
                    </span>
                  </div>
                </div>
              </div>
              <div className={`${que_value_col} col-12`}>
                <div
                  className="btn-group d-flex flex-row gap-3"
                  role="group"
                  aria-label="Basic radio toggle button group"
                  id="another_driver1"
                  name="another_driver1"
                  onChange={(e) => handleAnotherDriver(e.target.value, "another_driver1", 1, setFieldValue)}
                  onBlur={handleBlur}
                  value={values?.another_driver1}
                >
                  <RadioInput
                    value={"Yes"}
                    name={"another_driver1"}
                    w={"w-50"}
                    val={values?.another_driver1}
                    checked={values?.another_driver1 == "Yes" ? true : false}
                    error={errors.another_driver1}
                    touch={touched.another_driver1}
                  />
                  <RadioInput
                    value={"No"}
                    name={"another_driver1"}
                    w={"w-50"}
                    val={values?.another_driver1}
                    checked={values?.another_driver1 == "No" ? true : false}
                    error={errors.another_driver1}
                    touch={touched.another_driver1}
                  />
                </div>
              </div>
            </div>
          }
          {drivers &&
            drivers.map((d, index) => {
              return (
                <>
                  <div className="row mt-5 g-1">
                  {/* col-lg-10  */}
                    <div className="col-12 ">
                      <div className="d-flex justify-content-between">
                        <span className="text-start text-color-gray">(Driver {index + 2} of {drivers?.length + 1})</span>
                        {
                          values?.status && d == 1 ? null : <span
                            className="cursor-pointer"
                            onClick={() => handleRemoveDriver(d, 'remove')}
                          >
                            X Remove driver
                          </span>
                        }
                      </div>
                    </div>
                    <div className="col-12 mt-1">
                      <h3>{values?.status && d == 1 ? 'Next, add your spouse' : ' Additional driver'}</h3>
                    </div>
                  </div>
                  <AnotherDriver
                    setFieldError={setFieldError}
                    status={values?.status}
                    count={index + 2}
                    drivers={drivers}
                    setDrivers={setDrivers}
                    handleAnotherDriver={handleAnotherDriver}
                    
                    name={`another_driver${index + 2}`}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                    radioValue={values[`another_driver${index + 2}`]}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </>
              );
            })}

          <div className="row">
          {/* col-lg-10 */}
            <div className=" col-12 d-flex justify-content-between">
              <NavLink
                className={'mt-lg-0 mt-4'}
                to={VEHICLE_DETAIL}
                style={{
                  color: "#4a4f53",
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Back
              </NavLink>
              <ButtonInput name={"Save & continue"} type="submit" errors={errors}/>
            </div>
          </div>
        </form>
        {/* <ToastContainer /> */}
      </div>
      <ToastContainer/>
    </>
  );
};

export default DriverDetails;
