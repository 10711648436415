import React, { useContext, useEffect, useLayoutEffect } from "react";
import '../css/RadioInput.css'
import ClassNames from 'classnames';
import { UserValContext } from "../../context/UserContextProvider";
import { useDispatch, useSelector } from "react-redux";
import { getColors } from "../../../redux/action/UserAction";

const RadioInput = ({ value, name, w, val, key, checked, error, touch, defaultChecked }) => {
  
  const {submitting,button_color} = useContext(UserValContext)
 
  return (
    <>
    {/*  */}
      <label className={ClassNames(`${w} user-radio-control p-3 label rounded ${val == value ? 'bg' : ''}`, { 'is-invalid': submitting && error})} style={{color:value == val &&'white'}}>
        <input
          className={ClassNames(" form-check-input me-4", { 'is-invalid': submitting && error  })}
          type="radio"
          value={value }
          name={name}
          id={`${value}${name}`}
          checked={checked}
        />
        {value}
      </label>
    </>
  );
};

export default RadioInput;
