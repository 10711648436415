import React from "react"
import { useFormik } from "formik"
import { BsInfoCircle } from 'react-icons/bs'
import { NavLink, useNavigate } from "react-router-dom"
import { DRIVER_DETAIL, RESULT, THANKYOU } from "../../../services/UserRoutes"
import CoverageCard from "./CoverageCard"
import { savePackageDetail } from "../../../../redux/action/UserAction"
import { useDispatch, useSelector } from 'react-redux'
import '../css/CoverageCard.css'
import { color } from "../../../services/Methods"

const Coverage = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  let { userPackageDetail } = useSelector((state) => state?.persistedReducer)

  const { values, setFieldValue, errors, handleBlur, handleChange, handleSubmit, touched, setValues, } = useFormik({
    initialValues: { package: userPackageDetail?.package ? userPackageDetail?.package : '' },
    onSubmit: (values, { resetForm }) => { },
  });

  const handleClick = (name) => {
    setFieldValue('package', name)
    dispatch(savePackageDetail({ package: name }))
    // navigate(RESULT)
    navigate(THANKYOU)
  }

  const styles = { width: '100%', height: 'auto', display: 'flex', flexWrap: 'nowrap', overflowX: 'auto', overflowY: 'hidden', scrollBehavior: 'smooth', WebkitOverflowScrolling: 'touch' }

  return (
    <>
      <div className="container-fluid w-100 pt-3 g-3 mb-5" style={{ paddingTop: '20px' }}>
        <div className="row g-2 mb-4">
          <h2 className="mb-0">Select your coverage package</h2>
          <span className="text-start w-100">One last step before seeing your quotes! You can always change your coverage package later.</span>
          <span className="description fw-bold mt-3" style={{ color: color }}><BsInfoCircle className='fs-5 me-1' /><a href='#' style={{ color: color }} className='m-0 text-decoration-none' >What does "Our pick" mean?</a></span>
        </div>
        <div className="row w-100">
        {/* col-xxl-10  */}
          <div className="col-12">
            <div style={styles} className="py-5">
              <div style={{ width: '500px' }} className="mx-3">
                <CoverageCard condition={'Minimum'} values={['$25K / $50K', '$10K', '$50K', 'None', 'None']} handleClick={handleClick} selectedPackage={values.package} />
              </div>
              <div style={{ width: '500px' }} className="mx-3">
                <CoverageCard condition={'Better'} values={['$50K / $100K', '$50K', '$50K', '$1K', '$1K']} handleClick={handleClick} selectedPackage={values.package} />
              </div>
              <div style={{ width: '500px' }} className="mx-3">
                <CoverageCard condition={'Best'} values={['$100K / $300K', '$50K', '$100K', '$1K', '$1K']} handleClick={handleClick} selectedPackage={values.package} />
              </div>
              {/* <div className="col-12">
            <img src={require('../../../../assets/logo-orange.png')} alt="image" style={{display:'block',marginTop:'100px',marginBottom:'100px'}}/>
          </div> */}
            </div>
          </div>
        </div>
        <div className="row justify-content-start">
          <div className="col-lg-8 col-12">
            <p className="mt-4 footer-text">By clicking "Save & continue," you consent to Insure the future saving the information you entered and sharing it with insurance carriers and other insurance professionals so you can get the most up-to-date quotes, no matter what device you're using. Additionally, carriers and insurance professionals may use this to obtain information about your credit history. You also agree to Insure the future's Privacy Policy and Terms of Service.</p>
          </div>
          <div className="col-8 d-flex justify-content-between">
            <NavLink to={DRIVER_DETAIL} style={{ color: '#4a4f53', fontWeight: '600', display: 'flex', alignItems: 'center' }}>Back</NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Coverage;
