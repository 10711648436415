import { combineReducers } from 'redux';
import AdminReducer from './AdminReducer';
import UserReducer from './UserReducer';
import UserStoreDetail from './UserStoreDetail'
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, UserStoreDetail)


const RootReducer = combineReducers({ AdminReducer,UserReducer,persistedReducer});

export default RootReducer;  