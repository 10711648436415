import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { COVERAGE_SELECTION, DRIVER_DETAIL, HOME_DETAIL, HOME_OWNER, HOME_START, MANUAL_START, MANUAL_START_DETAIL, RESULT, VEHICLE_DETAIL, VEHICLE_SELECT } from '../services/UserRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { getColors } from '../../redux/action/UserAction';
import { FORM_SETTING } from '../../admin/services/AdminRoutePath';
import { AdminValAuthContext } from '../../admin/context/AdminAuthProvider';
export const UserValContext = createContext();

const UserContextProvider = ({ children }) => {

    const dispatch = useDispatch()
    const { pathname } = useLocation();
    let { buttonColor, dColor, backColor,fColor } = useSelector((state) => state.UserReducer.colors)
    let {fontColor,btnColor,bgColor,dotColor,color,color1,color2,color3} = useContext(AdminValAuthContext)

    const [button_color,setButton_color]= useState(pathname == FORM_SETTING ? color1 ? `${color1?.hex}` :`${btnColor}` : buttonColor)
    const [font_color,setFont_color]=useState(pathname == FORM_SETTING ?  color ? `${color?.hex}` :`${fontColor}` :fColor)
    const [bg_color,setBg_color]= useState(pathname == FORM_SETTING ? color2 ? `${color2?.hex}` :`${bgColor}` : backColor)
    const [dot_color,setDot_color]= useState(pathname == FORM_SETTING? color3 ? `${color3?.hex}` :`${dotColor}` :dColor)
    
    useEffect(() => {
        dispatch(getColors())
        if (pathname == FORM_SETTING) {
            document.documentElement.style.setProperty('--user_orange', color1 ? `${color1?.hex}` :`${btnColor}`);
            document.documentElement.style.setProperty('--font_color', color ? `${color?.hex}` :`${fontColor}`);
            document.documentElement.style.setProperty('--bg_color',  color2 ? `${color2?.hex}` :`${bgColor}`); 
            document.documentElement.style.setProperty('--dot_color',  color3 ? `${color3?.hex}` :`${dotColor}`);  
        }
        else {
            if (button_color) document.documentElement.style.setProperty('--user_orange', button_color);
            if (font_color) document.documentElement.style.setProperty('--font_color', font_color);
            if (bg_color) document.documentElement.style.setProperty('--bg_color', bg_color);
            if (dot_color) document.documentElement.style.setProperty('--dot_color', dot_color);
        }
    }, [dispatch, button_color,pathname,color])

    useEffect(()=>{
        setFont_color( pathname == FORM_SETTING ?  color ? `${color?.hex}` :`${fontColor}` :fColor)
        setDot_color( pathname == FORM_SETTING? color3 ? `${color3?.hex}` :`${dotColor}` :dColor)
        setBg_color( pathname == FORM_SETTING ? color2 ? `${color2?.hex}` :`${bgColor}` : backColor)
        setButton_color( pathname == FORM_SETTING ? color1 ? `${color1?.hex}` :`${btnColor}` : buttonColor)
    },[pathname,color,color1,color2,color3,fColor,dColor,backColor,buttonColor])

    const [submitting, setSubmitting] = useState(false)
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [vehicleYear, setVehicleYear] = useState('');
    const [vehicleMake, setVehicleMake] = useState({});
    const [vehicleModel, setVehicleModel] = useState({});
    const [coverageStep, setCoverageStep] = useState(true)

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    useEffect(() => {
        if (pathname == MANUAL_START || pathname == MANUAL_START_DETAIL) setActiveStep(0)
        else if (pathname == VEHICLE_SELECT || pathname == VEHICLE_DETAIL) setActiveStep(1)
        else if (pathname == DRIVER_DETAIL) setActiveStep(2)
        else if (pathname == COVERAGE_SELECTION) setActiveStep(3)
        else if (pathname == RESULT) setActiveStep(4)
        else setActiveStep(0)

        switch (pathname) {
            case HOME_START:
                setActiveStep(0)
                break;
            case HOME_DETAIL:
                setActiveStep(1)
                break;
            case HOME_OWNER:
                setActiveStep(2)
                break;
            case RESULT:
                setActiveStep(3)
                break;

            default:
                break;
        }
    }, [pathname])

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return <UserValContext.Provider value={{ button_color, dot_color,font_color, bg_color, submitting, setSubmitting, activeStep, setActiveStep, skipped, setSkipped, handleNext, handleBack, setVehicleModel, vehicleModel, setVehicleMake, vehicleMake, setVehicleYear, vehicleYear, coverageStep, setCoverageStep }}>{children}</UserValContext.Provider>
}
export default UserContextProvider