import React from 'react'

const InputLodingButton = ({name,className}) => {
    return (
        <>
            <button type="button" className={className}>
                <span className="d-flex align-items-center justify-content-center">
                    <span className="spinner-border flex-shrink-0" role="status">
                        <span className="visually-hidden">{name}</span>
                    </span>
                    <span className="ms-1">
                        {name}
                    </span>
                </span>
            </button>
        </>
    )
}

export default InputLodingButton