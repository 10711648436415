import { storeAdminRole, storeAdminToken, storeLoggedInAdmin } from "../../admin/services/AdminLocalStorage";
import { DASHBOARD, ADMIN_LOGIN } from "../../admin/services/AdminRoutePath";
import { AdminClient } from "../client/AdminClient";
import { toast } from 'react-toastify';
import axios from "axios";
export const LOGIN = "LOGIN"
export const ADMIN_LOGOUT = "ADMIN_LOGOUT"
export const ADD_BRAND = "ADD_BRAND"
export const ADD_MODEL = "ADD_MODEL"
export const ADD_TRIMNAME = "ADD_TRIMNAME"
export const ADD_VEHICLES = "ADD_VEHICLES"
export const ADD_GOOGEL_SHEET = "ADD_GOOGEL_SHEET"
export const ADD_INSUR_COMP = "ADD_INSUR_COMP"
export const ADD_GOOGLESHEET_DATA = "ADD_GOOGLESHEET_DATA"
export const ADD_ACTIVE_CAMPAIGN = "ADD_ACTIVE_CAMPAIGN"
export const ADD_ACTIVE_CAMPAIGN_DATA = "ADD_ACTIVE_CAMPAIGN_DATA"

export const GET_BRANDS = "GET_BRANDS"
export const GET_MODELS = "GET_MODELS"
export const GET_TRIMNAME = "GET_TRIMNAME"
export const GET_VEHICLES = "GET_VEHICLES"
export const GET_BRAND_TO_MODEL = "GET_BRAND_TO_MODEL"
export const GET_MODEL_TRIMNAME = "GET_MODEL_TRIMNAME"
export const GET_BRANDS_LIST = "GET_BRANDS_LIST"
export const GET_MODELS_LIST = "GET_MODELS_LIST"
export const GET_TRIMNAME_LIST = "GET_TRIMNAME_LIST"
export const GET_VEHICLES_LIST = "GET_VEHICLES_LIST"
export const GET_USER_LIST = "GET_USER_LIST"
export const GET_INSURANCE_DETAIL = "GET_INSURANCE_DETAIL"
export const GET_USERS = "GET_USERS"
export const GET_GOOGEL_SHEET_USER = "GET_GOOGEL_SHEET_USER"
export const GET_GOOGLE_ALL_USERS = "GET_GOOGLE_ALL_USERS"
export const GET_INSUR_COMPS = "GET_INSUR_COMPS"
export const GET_INSUR_COMPS_LIST = "GET_INSUR_COMPS_LIST"
export const GET_GOOGLE_SHEETS = "GET_GOOGLE_SHEETS"
export const GET_ACTIVE_CAMPAIGN_LIST = "GET_ACTIVE_CAMPAIGN_LIST"
export const GET_ACTIVE_CAMPAIGN = "GET_ACTIVE_CAMPAIGN"
export const GET_GOOGEL_SHEET_ALL_AC = "GET_GOOGEL_SHEET_ALL_AC"
export const GET_FORM_PAGE = "GET_FORM_PAGE"
export const GET_FORM_QUESTION = "GET_FORM_QUESTION"
export const GET_FORM_VALUE = "GET_FORM_VALUE"
export const GET_FORM_SUB_QUESTION = "GET_FORM_SUB_QUESTION"
export const GET_FORM_SUB_VALUE = "GET_FORM_SUB_VALUE"
export const GET_FORM_COLOR = "GET_FORM_COLOR"
export const GET_FORM_QUESTIONS = "GET_FORM_QUESTIONS"
export const GET_FORM_VALUES = "GET_FORM_VALUES"
export const GET_FORM_SUB_QUESTIONS= "GET_FORM_SUB_QUESTIONS"
export const GET_QUE_TO_VAL= "GET_QUE_TO_VAL"
export const GET_VAL_TO_SQUE= "GET_VAL_TO_SQUE"
export const GET_PAGE_TO_QUE= "GET_PAGE_TO_QUE"

export const EDIT_BRAND = "EDIT_BRAND"
export const EDIT_MODEL = "EDIT_MODEL"
export const EDIT_TRIM_NAME = "EDIT_TRIM_NAME"
export const EDIT_VEHICLE = "EDIT_VEHICLE"
export const EDIT_INSUR_COMP = "EDIT_INSUR_COMP"
export const EDIT_ACTIVE_CAMPAIGN = "EDIT_ACTIVE_CAMPAIGN"
export const EDIT_FONT_COLOR = "EDIT_FONT_COLOR"
export const EDIT_LEAD_USER = "EDIT_LEAD_USER"
export const EDIT_FORM_QUESTION = "EDIT_FORM_QUESTION"
export const EDIT_FORM_VALUE = "EDIT_FORM_VALUE"
export const EDIT_FORM_SUB_QUESTION = "EDIT_FORM_SUB_QUESTION"
export const EDIT_FORM_SUB_VALUE = "EDIT_FORM_SUB_VALUE"
export const EDIT_ENGINE_NAME = "EDIT_ENGINE_NAME"
export const BULK_EDIT_TRIM = "BULK_EDIT_TRIM"

export const DELETE_BRAND = "DELETE_BRAND"
export const DELETE_MODEL = "DELETE_MODEL"
export const DELETE_TRIM_NAME = "DELETE_TRIM_NAME"
export const DELETE_VEHICLE = "DELETE_VEHICLE"
export const DELETE_INSURANCE = "DELETE_INSURANCE"
export const DELETE_GOOGLE_SHEET_Ac = "DELETE_GOOGLE_SHEET_Ac"
export const DELETE_INSUR_COMP = "DELETE_INSUR_COMP"
export const DELETE_ACTIVE_CAMPAIGN = "DELETE_ACTIVE_CAMPAIGN"


// -----------------------------------------login-----------------------------------------------------------------
export const adminLogin = (obj, setLoading, navigate, setError) => (dispatch) => {
  AdminClient.post('admin/login', obj).then((response) => {
    // console.log(response.data.data, '--------------login-----------------------')
    storeAdminToken(response.data.access_token);
    storeAdminRole(`${response.data.data.role}`);
    storeLoggedInAdmin(response.data.data);
    dispatch({
      type: LOGIN,
      payload: response.data.data
    })
    setLoading(false);
    navigate(DASHBOARD)
  }).catch((error) => {
    // console.log(error)
    setError(error.response.data.message)
    setLoading(false);
  })
}
export const adminLogout = (navigate, setLoading) => (dispatch) => {
  AdminClient.post('admin/logout').then((response) => {
    dispatch({
      type: ADMIN_LOGOUT,
      payload: response
    })
    setTimeout(() => {
      setLoading(false);
      localStorage.clear();
      navigate(ADMIN_LOGIN)
    }, 500);


  }).catch((error) => {
    console.log(error)
    setLoading(false);
  })
}
export const addGoogleSheetAccount = () => (dispatch) => {
  AdminClient.get('/auth/google').then((response) => {
    dispatch({
      type: ADD_GOOGEL_SHEET,
      payload: response.data.data
    })
  }).catch((error) => {
    console.log(error)
  })
}
export const curdData = (obj,setLoading, setShow, setAction, setHideSelectModel, setHideSelectTrim,setHideSelectFromPage,getFun,page,msg,type,url,setCurrentPage,getUrl,getType) => (dispatch) =>{
  AdminClient.post(url, obj).then((res) => {
    dispatch({
      type: type,
      payload: res.data
    })
    setTimeout(() => { setLoading(false); setShow(false); setAction(''); setHideSelectModel(true); setHideSelectTrim(true); setHideSelectFromPage(true); dispatch(getFun(setLoading,getUrl,getType));setCurrentPage(page) }, 1000)
    setTimeout(() => { toast.success(msg) }, 1500)
  }).catch((e) => { console.log(e); setTimeout(() => { setLoading(false); setAction(''); setHideSelectModel(true); setHideSelectTrim(true); setHideSelectFromPage(true); setShow(false); toast.error("This is an error!"); }, 1000) })
}

export const LeadEditData = (obj,setLoading, setShowEditLeads, setTitle,getFun,page,msg,type,url,setCurrentPage,getUrl,getType) => (dispatch) =>{
  AdminClient.post(url, obj).then((res) => {
    dispatch({
      type: type,
      payload: res.data
    })
    setTimeout(() => { setLoading(false); setShowEditLeads(false); setTitle('');  dispatch(getFun(setLoading,getUrl,getType));setCurrentPage(page) }, 1000)
    setTimeout(() => { toast.success(msg) }, 1500)
  }).catch((e) => { console.log(e); setTimeout(() => { setLoading(false); setTitle(''); setShowEditLeads(false); toast.error("This is an error!"); }, 1000) })
}
export const formColorData = (obj,setLoading,getFun,msg,type,url,getUrl,getType) => (dispatch) =>{
  AdminClient.post(url, obj).then((res) => {
    dispatch({
      type: type,
      payload: res.data
    })
    setTimeout(() => { setLoading(false); dispatch(getFun(setLoading,getUrl,getType));}, 1000)
    setTimeout(() => { toast.success(msg) }, 1500)
  }).catch((e) => { console.log(e); setTimeout(() => { setLoading(false); toast.error("This is an error!"); }, 1000) })
}
export const getDataList = (setLoading,getUrl,getType) => (dispatch) =>{
  AdminClient.get(getUrl).then((res) => {
    dispatch({
      type: getType,
      payload: res.data.data,
      path: res.data.path,
      totalItemCount: res.data.totalItemCount,
    })
    setLoading(false);
  }).catch((e) => { console.log(e); })
}
export const getDatas=(setLoading,url,type)=>(dispatch)=>{
  AdminClient.get(url).then((res) => {
    dispatch({
      type: type,
      payload: res.data.data,
      path: res.data.path,
    })
    setLoading(false);
  }).catch((e) => { console.log(e); })
}
export const getBrandToModel = (id) => (dispatch) => {
  AdminClient.get(`admin/get-brandtomodel/${id}`).then((res) => {
    dispatch({
      type: GET_BRAND_TO_MODEL,
      payload: res.data.data
    })
  }).catch((e) => { console.log(e); })
}
export const getModelToTrimName = (id) => (dispatch) => {
  AdminClient.get(`/admin/get-modeltotrimname/${id}`).then((res) => {
    dispatch({
      type: GET_MODEL_TRIMNAME,
      payload: res.data.data
    })
  }).catch((e) => { console.log(e); })
}
export const getInsuranceDetail = (id) => (dispatch) => {
  AdminClient.get(`/admin/get-insurance-detail/${id}`).then((res) => {
    dispatch({
      type: GET_INSURANCE_DETAIL,
      payload: res.data.data
    })
  }).catch((e) => { console.log(e); })
}
export const getQueToVal = (id) => (dispatch) => {
  AdminClient.get(`admin/get-quetoval/${id}`).then((res) => {
    dispatch({
      type: GET_QUE_TO_VAL,
      payload: res.data.data
    })
  }).catch((e) => { console.log(e); })
}
export const getValToSQue = (id) => (dispatch) => {
  AdminClient.get(`admin/get-valtosque/${id}`).then((res) => {
    dispatch({
      type: GET_VAL_TO_SQUE,
      payload: res.data.data
    })
  }).catch((e) => { console.log(e); })
}
export const getPageToQue = (id) => (dispatch) => {
  AdminClient.get(`admin/get-pagetoque/${id}`).then((res) => {
    dispatch({
      type: GET_PAGE_TO_QUE,
      payload: res.data.data
    })
  }).catch((e) => { console.log(e); })
}
/*-------------------------------------------------------google sheets ---------------------------------------------------------------------------------------*/
export const getGoogleSheets = (token) => (dispatch) => {
      axios.get("https://www.googleapis.com/drive/v3/files?q=mimeType = 'application/vnd.google-apps.spreadsheet'", {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }).then((res) => {
          dispatch({
            type: GET_GOOGLE_SHEETS,
            payload: res.data.files,
          })
        }).catch((e) => { console.log(e); })
}
export const addGoogleSheetData = (obj, setLoading, setShowGoogleSheet) => (dispatch) => {
  AdminClient.post('admin/add-google-sheet-data', obj).then((res) => {
    dispatch({
      type: ADD_GOOGLESHEET_DATA,
      payload: res.data
    })
    setTimeout(() => { setLoading(false); setShowGoogleSheet(false) }, 1000)
    setTimeout(() => { toast.success("Export data sucessfully!") }, 1500)
  }).catch((e) => { console.log(e); setTimeout(() => { setLoading(false); setShowGoogleSheet(false); toast.error("This is an error!"); }, 1000) })
}

/* ---------------------------------------------------------active campaign--------------------------------------------------------------------------*/
export const addActiveCampaignData = (obj, setLoading, setShowGoogleSheet) => (dispatch) => {
  AdminClient.post('admin/add-contact-active-campaign', obj).then((res) => {
    dispatch({
      type: ADD_ACTIVE_CAMPAIGN_DATA,
      payload: res.data
    })
    setTimeout(() => { setLoading(false); setShowGoogleSheet(false) }, 1000)
    setTimeout(() => { toast.success("Export data sucessfully!") }, 1500)
  }).catch((e) => { console.log(e); setTimeout(() => { setLoading(false); setShowGoogleSheet(false); toast.error("This is an error!"); }, 1000) })
}
