import logo from './logo.svg';
import './App.css';
import AdminHome from './admin/layouts/AdminHome';
import AdminAuthProvider from './admin/context/AdminAuthProvider';
import { Routes, Route } from "react-router-dom";
import { ACTIVE_CAMPAIGN, ADMIN, ADMIN_LOGIN, ALL_BRANDS, ALL_INSUR_COMP, ALL_LEAD, ALL_MODELS, ALL_TRIMNAME, ALL_VEHICLE, DASHBOARD, FORM_QUESTION, FORM_SETTING, FORM_SUB_QUESTION, FORM_SUB_VALUE, FORM_VALUE, GOOGLE_SHEET, PAGE_400 } from './admin/services/AdminRoutePath';
import AdminLogin from './admin/auth/login/AdminLogin';
import AdminProtected from './admin/protected/AdminProtected';
import { useState } from 'react';
import { COVERAGE_SELECTION, DRIVER_DETAIL, HOME_DETAIL, HOME_OWNER, HOME_START, MANUAL_START, MANUAL_START_DETAIL, RESULT, THANKYOU, VEHICLE_DETAIL, VEHICLE_SELECT } from './user/services/UserRoutes';
import UserContextProvider from './user/context/UserContextProvider';
import Home from './user/layout/Home';
import Page404 from './admin/layouts/Page404';
import Page400 from './admin/layouts/Page400';
import AdminPreviewForm from './admin/layouts/AdminPreviewForm';
// 
function App() {

  const [userPath, setUserPath] = useState([MANUAL_START, MANUAL_START_DETAIL, VEHICLE_SELECT, VEHICLE_DETAIL, DRIVER_DETAIL, COVERAGE_SELECTION,HOME_START,HOME_DETAIL,HOME_OWNER,RESULT,THANKYOU])

  return (
    <>
      <AdminAuthProvider>
        <UserContextProvider>
          <Routes>
            <Route path={ADMIN_LOGIN} element={<><AdminProtected Component={AdminLogin} /></>} />
            <Route path={ADMIN} element={<><AdminProtected Component={AdminLogin} /></>} />
            <Route path={ALL_BRANDS} element={<><AdminProtected Component={AdminHome} /></>} />
            <Route path={ALL_MODELS} element={<><AdminProtected Component={AdminHome} /></>} />
            <Route path={ALL_TRIMNAME} element={<><AdminProtected Component={AdminHome} /></>} />
            <Route path={ALL_VEHICLE} element={<><AdminProtected Component={AdminHome} /></>} />
            <Route path={ALL_LEAD} element={<><AdminProtected Component={AdminHome} /></>} />
            <Route path={ALL_INSUR_COMP} element={<><AdminProtected Component={AdminHome} /></>} />
            <Route path={GOOGLE_SHEET} element={<><AdminProtected Component={AdminHome} /></>} />
            {/* <Route path={ZAPIER} element={<><AdminProtected Component={AdminHome} /></>} /> */}
            <Route path={FORM_SETTING} element={<><AdminProtected Component={AdminHome} /></>} />
          
            <Route path={ACTIVE_CAMPAIGN} element={<><AdminProtected Component={AdminHome} /></>} />
            <Route path={FORM_QUESTION} element={<><AdminProtected Component={AdminHome} /></>} />
            <Route path={FORM_VALUE} element={<><AdminProtected Component={AdminHome} /></>} />
            <Route path={FORM_SUB_QUESTION} element={<><AdminProtected Component={AdminHome} /></>} />
            <Route path={FORM_SUB_VALUE} element={<><AdminProtected Component={AdminHome} /></>} />
            <Route path={DASHBOARD} element={<><AdminProtected Component={AdminHome} /></>} />
            {userPath.map((pathname, index) => <Route path={pathname} element={<Home />} key={index} />)}
            <Route path={PAGE_400} element={<Page400 />} />
            <Route path='*' element={<Page404 />} />
          
          </Routes>
        </UserContextProvider>
      </AdminAuthProvider>
    </>
  );
}

export default App;

