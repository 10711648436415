import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import RootReducer from "./reducer/RootReducer";
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'
import UserStoreDetail from "./reducer/UserStoreDetail";


const persistConfig = {
    key: 'root',
    storage,
}

const middlewares = [thunk];

const persistedReducer = persistReducer(persistConfig, UserStoreDetail)

export const store = createStore(RootReducer,compose(applyMiddleware(...middlewares), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : args => args))
export const persistor = persistStore(store)

// const Store = createStore(RootReducer, compose(applyMiddleware(...middlewares), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : args => args))

// export default Store



