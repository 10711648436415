import React, { useContext } from 'react'
import AdminContent from './AdminContent'
import AdminFooter from './AdminFooter'
import AdminHeader from './AdminHeader'
import AdminSidebar from './AdminSidebar'
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { ACTIVE_CAMPAIGN, ADMIN_LOGIN, ALL_BRANDS, ALL_INSUR_COMP, ALL_LEAD, ALL_MODELS, ALL_TRIMNAME, ALL_VEHICLE, DASHBOARD,  FORM_QUESTION, FORM_SETTING, FORM_SUB_QUESTION, FORM_SUB_VALUE, FORM_VALUE, GOOGLE_SHEET, ZAPIER } from '../services/AdminRoutePath'
import CurdPage from '../pages/CurdPage'
import { AdminValAuthContext } from '../context/AdminAuthProvider'

import Zapier from '../pages/Zapier'
import IntegrationExportPage from '../pages/IntegrationExportPage'
import { useEffect } from 'react'
import { sidebarToggle } from '../services/AdminLocalStorage'
import FormsColorChange from '../pages/FormsColorChange'
const AdminHome = () => {
    let location = useLocation();
    const val = useContext(AdminValAuthContext);
    let windowWidth;
    useEffect(() => {
        windowWidth = (window.innerWidth)
        let divId = document.getElementById('content')
        sidebarToggle(divId,windowWidth,val?.showSidebar)
      }, [windowWidth,val?.showSidebar])
    return (
        <>
            <div id="layout-wrapper" >
                {location.pathname != ADMIN_LOGIN ? <AdminSidebar /> : null}
                <div className="main-content" id={'content'} >
                    <div className="page-content">
                        {location.pathname != ADMIN_LOGIN ? <AdminHeader /> : null}

                        {location.pathname == DASHBOARD ? <><AdminContent /></> : null}
                        {location.pathname == ALL_BRANDS ? <><CurdPage path={ALL_BRANDS} /></> : null}
                        {location.pathname == ALL_MODELS ? <><CurdPage path={ALL_MODELS} /></> : null}
                        {location.pathname == ALL_TRIMNAME ? <><CurdPage path={ALL_TRIMNAME} /></> : null}
                        {location.pathname == ALL_VEHICLE ? <><CurdPage path={ALL_VEHICLE} /></> : null}
                        {location.pathname == ALL_LEAD ? <><CurdPage path={ALL_LEAD} /></> : null}
                        {location.pathname == ALL_INSUR_COMP ? <><CurdPage path={ALL_INSUR_COMP} /></> : null}
                        {location.pathname == FORM_QUESTION ? <><CurdPage path={FORM_QUESTION} /></> : null}
                        {location.pathname == FORM_SUB_QUESTION ? <><CurdPage path={FORM_SUB_QUESTION} /></> : null}
                        {location.pathname == FORM_VALUE ? <><CurdPage path={FORM_VALUE} /></> : null}
                        {location.pathname == FORM_SUB_VALUE ? <><CurdPage path={FORM_SUB_VALUE} /></> : null}
                        {location.pathname == GOOGLE_SHEET ? <><IntegrationExportPage path={GOOGLE_SHEET} /></> : null}
                        {location.pathname == ZAPIER ? <><Zapier/></> : null}
                        {location.pathname == FORM_SETTING ? <><FormsColorChange/></> : null}
                        {location.pathname == ACTIVE_CAMPAIGN ? <><IntegrationExportPage path={ACTIVE_CAMPAIGN} /></> : null}
                        {location.pathname != ADMIN_LOGIN ? <AdminFooter /> : null}

                    </div>
                </div>
            </div>
        

        </>
    )
}

export default AdminHome