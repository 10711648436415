export const ALL_BRANDS = '/admin/barnd/all-brands';
export const ALL_INSUR_COMP = '/admin/insur-comp/all-insur-comp';
export const ALL_MODELS = '/admin/model/all-models';
export const ALL_TRIMNAME = '/admin/trimname/all-trimnames';
export const ALL_VEHICLE= '/admin/vehicle/all-vehicles';
export const ALL_LEAD= '/admin/lead/all-leads';
export const DASHBOARD = '/admin/dashboard';
export const ADMIN_LOGIN = '/admin/login';
export const ADMIN = '/admin';
export const GOOGLE_SHEET = '/admin/integration/google-sheet';
export const ZAPIER = '/admin/integration/zapier';
export const ACTIVE_CAMPAIGN = '/admin/integration/active-campaign';
export const PAGE_400 = '/admin/400';
export const FORM_SETTING='/admin/form-setting'
export const FORM_QUESTION='/admin/edit-form-field/form-question'
export const FORM_VALUE='/admin/edit-form-field/form-value'
export const FORM_SUB_QUESTION='/admin/edit-form-field/form-sub-question'
export const FORM_SUB_VALUE='/admin/edit-form-field/form-sub-value'