import React from 'react'
import ClassNames from 'classnames';
const InputText = ({type,className,placeholder,name,error,touch,value,handleBlur, handleChange}) => {
    return (
        <>
        {
            type == 'file' ?   <input type={type} className={ClassNames(className, { 'is-invalid': error && touch })} placeholder={placeholder} name={name}  onChange={handleChange} onBlur={handleBlur}/>:  <input type={type} className={ClassNames(className, { 'is-invalid': error && touch })} placeholder={placeholder} name={name} value={value} onChange={handleChange} onBlur={handleBlur}/>
        }
        
         {error && touch ? (<span className='text-danger'>{error}</span>) : null}
        </>
    )
}

export default InputText