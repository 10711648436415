import { LOGIN, ADMIN_LOGOUT, GET_BRANDS, GET_BRANDS_LIST, GET_BRAND_TO_MODEL, GET_MODELS, GET_MODELS_LIST, GET_MODEL_TRIMNAME, GET_TRIMNAME, GET_TRIMNAME_LIST, GET_VEHICLES, GET_VEHICLES_LIST, GET_USER_LIST, GET_INSURANCE_DETAIL, GET_USERS, GET_GOOGEL_SHEET, GET_INSUR_COMPS, GET_INSUR_COMPS_LIST, GET_GOOGLE_ALL_USERS, GET_GOOGEL_SHEET_USER, GET_GOOGLE_SHEETS, GET_ACTIVE_CAMPAIGN, GET_ACTIVE_CAMPAIGN_LIST, GET_GOOGEL_SHEET_ALL_AC, GET_FORM_COLOR, GET_FORM_QUESTION, GET_FORM_VALUE, GET_FORM_SUB_QUESTION, GET_FORM_SUB_VALUE, GET_FORM_QUESTIONS, GET_FORM_VALUES, GET_FORM_SUB_QUESTIONS, GET_QUE_TO_VAL, GET_VAL_TO_SQUE, GET_FORM_PAGE, GET_PAGE_TO_QUE } from "../action/AdminAction"

const initialState = {
    brandList: [],
    insurCompList: [],
    brandPerPageList: [],
    insurCompPerPageList: [],
    modelPerPageList: [],
    trimNamePerPageList: [],
    vehiclePerPageList: [],
    modelList: [],
    trimNameList: [],
    vehicleList: [],
    brand_path: {},
    insur_comp_path: {},
    brandToModelList: [],
    modelToTrimNameList: [],
    userPerPageList: [],
    insuranceList: {},
    userList: [],
    brandTotalItemCount: 0,
    insurCompTotalItemCount: 0,
    modelTotalItemCount: 0,
    trimNameTotalItemCount: 0,
    vehicleTotalItemCount: 0,
    userItemCount: 0,
    brandLastPage: 0,
    insurCompLastPage: 0,
    googleSheetAc: [],
    googleSheetUserItemCount: 0,
    googleUserList: [],
    googleSheetList: [],
    googleAllAccount:[],
    activeCampaignList: [],
    activeCampaignPerPageList: [],
    activeCampaignTotalItemCount: 0,
    formColor:{},
    formQueList:[],
    formValList:[],
    formSQueList:[],
    formSValList:[],
    formQueTotalItemCount:0,
    formValTotalItemCount:0,
    formSQueTotalItemCount:0,
    formSValTotalItemCount:0,
    formPage:[],
    formQue:[],
    formVal:[],
    formSQue:[],
    queToValList:[],
    valToSQueList:[],
    pageToQueList:[],
}

const AdminReducer = function (state = initialState, action) {
    switch (action.type) {
        case LOGIN: {
            return {
                ...state,
            }
        }
        case ADMIN_LOGOUT: {
            return {
                ...state,
            }
        }
        case GET_GOOGEL_SHEET_USER: {
            return {
                ...state,
                googleSheetAc: [...action.payload],
                googleSheetUserItemCount: action.totalItemCount,
            }
        }
        case GET_GOOGLE_SHEETS: {
            return {
                ...state,
                googleSheetList: [...action.payload],

            }
        }
        case GET_GOOGEL_SHEET_ALL_AC: {
            return {
                ...state,
                googleAllAccount: [...action.payload],

            }
        }
        case GET_BRANDS: {
            return {
                ...state,
                brandList: [...action.payload],
                brand_path: action.path,
            }
        }
        case GET_GOOGLE_ALL_USERS: {
            return {
                ...state,
                googleUserList: [...action.payload],

            }
        }
        case GET_BRANDS_LIST: {
            return {
                ...state,
                brandPerPageList: [...action.payload],
                brand_path: action.path,
                brandLastPage: action.lastPage,
                brandTotalItemCount: action.totalItemCount,
            }
        }
        case GET_INSUR_COMPS: {
            return {
                ...state,
                insurCompList: [...action.payload],
                insur_comp_path: action.path,
            }
        }
        case GET_INSUR_COMPS_LIST: {
            return {
                ...state,
                insurCompPerPageList: [...action.payload],
                insur_comp_path: action.path,
                insurCompLastPage: action.lastPage,
                insurCompTotalItemCount: action.totalItemCount,
            }
        }
        case GET_MODELS: {
            return {
                ...state,
                modelList: [...action.payload],
            }
        }
        case GET_MODELS_LIST: {
            return {
                ...state,
                modelPerPageList: [...action.payload],
                modelTotalItemCount: action.totalItemCount,
            }
        }
        case GET_ACTIVE_CAMPAIGN: {
            return {
                ...state,
                activeCampaignList: [...action.payload],
            }
        }
        case GET_ACTIVE_CAMPAIGN_LIST: {
            return {
                ...state,
                activeCampaignPerPageList: [...action.payload],
                activeCampaignTotalItemCount: action.totalItemCount,
            }
        }
        case GET_TRIMNAME: {
            return {
                ...state,
                trimNameList: [...action.payload],
            }
        }
        case GET_TRIMNAME_LIST: {
            return {
                ...state,
                trimNamePerPageList: [...action.payload],
                trimNameTotalItemCount: action.totalItemCount,
            }
        }
        case GET_VEHICLES: {
            return {
                ...state,
                vehicleList: [...action.payload],
            }
        }
        case GET_VEHICLES_LIST: {
            return {
                ...state,
                vehiclePerPageList: [...action.payload],
                vehicleTotalItemCount: action.totalItemCount,
            }
        }
        case GET_BRAND_TO_MODEL: {
            return {
                ...state,
                brandToModelList: [...action.payload],
            }
        }
        case GET_MODEL_TRIMNAME: {
            return {
                ...state,
                modelToTrimNameList: [...action.payload],
            }
        }
        case GET_USER_LIST: {
            return {
                ...state,
                userPerPageList: [...action.payload],
                userItemCount: action.totalItemCount,
            }
        }
        case GET_QUE_TO_VAL: {
            return {
                ...state,
                queToValList: [...action.payload],
            }
        }
        case GET_VAL_TO_SQUE: {
            return {
                ...state,
                valToSQueList: [...action.payload],
            }
        }
        case GET_PAGE_TO_QUE: {
            return {
                ...state,
                pageToQueList: [...action.payload],
            }
        }
        case GET_INSURANCE_DETAIL: {
            return {
                ...state,
                insuranceList: action.payload,
            }
        }
        case GET_USERS: {
            return {
                ...state,
                userList: [...action.payload],
            }
        }
        case GET_FORM_COLOR: {
            return {
                ...state,
                formColor: action.payload,

            }
        }
        case GET_FORM_QUESTION: {
            return {
                ...state,
                formQueList: [...action.payload],
                formQueTotalItemCount: action.totalItemCount,
            }
        }
        case GET_FORM_VALUE: {
            return {
                ...state,
                formValList: [...action.payload],
                formValTotalItemCount: action.totalItemCount,
            }
        }
        case GET_FORM_SUB_QUESTION: {
            return {
                ...state,
                formSQueList: [...action.payload],
                formSQueTotalItemCount: action.totalItemCount,
            }
        }
        case GET_FORM_SUB_VALUE: {
            return {
                ...state,
                formSValList: [...action.payload],
                formSValTotalItemCount: action.totalItemCount,
            }
        }
        case GET_FORM_QUESTIONS: {
            return {
                ...state,
                formQue: action.payload,

            }
        }
        case GET_FORM_VALUES: {
            return {
                ...state,
                formVal: action.payload,

            }
        }
        case GET_FORM_SUB_QUESTIONS: {
            return {
                ...state,
                formSQue: action.payload,

            }
        }
        case GET_FORM_PAGE: {
            return {
                ...state,
                formPage: action.payload,

            }
        }
        default:
            return state
    }
}

export default AdminReducer