import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import "../car/css/ManualStart.css";
import { useNavigate } from "react-router";
import { AiOutlineCheck } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import RadioInput from "../../comp/js/RadioInput";
import ButtonInput from "../../comp/js/ButtonInput";
import { GOOGLE_API_KEY, HOME_DETAIL } from "../../services/UserRoutes";
import { saveHomeStart } from "../../../redux/action/UserAction";
import Number from "../../step/Number";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import TextFieldInput from "../../comp/js/TextFieldInput";
import SearchSelect from "../../comp/js/SearchSelect";
import classNames from "classnames";
import { color, options, que_col, que_value_col, requiredFeild } from "../../services/Methods";
import { UserValContext } from "../../context/UserContextProvider";

const HomeStart = () => {
  const dispatch = useDispatch();

  const { submitting, setSubmitting } = useContext(UserValContext);
  let { userManualStartDetail, userHomeStart } = useSelector((state) => state?.persistedReducer);
  const numberStyle = { padding: "5px 7px" };

  const naviagte = useNavigate();

  const { values, setFieldValue, errors, handleBlur, handleChange, handleSubmit, touched, setValues,
  } = useFormik({
    initialValues: {
      home_kind: userHomeStart?.home_kind ? userHomeStart?.home_kind : "",
      city: userManualStartDetail?.city ? userManualStartDetail?.city : "",
      state: userManualStartDetail?.state ? userManualStartDetail?.state : "",
      zipcode: userManualStartDetail?.zipcode ? userManualStartDetail?.zipcode : "",
      address: userManualStartDetail?.address ? userManualStartDetail.address : "",
    },
    validationSchema: yup.object().shape({
      home_kind: yup.string().required(requiredFeild),
      address: yup.string().required(requiredFeild), zipcode: yup.string().required(requiredFeild), city: yup.string().required(requiredFeild), state: yup.object().required(requiredFeild)
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(saveHomeStart(values));
      if (values) naviagte(HOME_DETAIL);
    },
  });

  const handleRadio = (value, name) => {
    setFieldValue(name, value);
  };

  const handleAddress = ({ formatted_address }) => {
    let address = formatted_address.split(',')
    setFieldValue('address', formatted_address.split(',').at(0))
    setFieldValue('city', address.at(-3))
    setFieldValue('state', { value: address.at(-2).slice(0, 3), label: address.at(-2).slice(0, 3) })
    setFieldValue('zipcode', address.at(-2).split(' ').at(-1))
  }

  return (
    <>
      <div
        className="container-fluid w-100 pt-5 g-3 mb-5 user-content"
        style={{ paddingTop: "20px" }}
      >
        <form onSubmit={handleSubmit}>
          <div className="row g-2 mb-4">
            <h2>Tell us about your home</h2>
            <h6>We'll only ask for the details insurance companies need.</h6>
          </div>
          <div className="row g-2 mt-3">
            <div className={`${que_col} col-12`}>
              <div className="d-flex">
                <Number number={1} value={values.home_kind} />
                <div className="w-100">
                  <h3>What kind of home is this?</h3>
                  <span className="text-start w-100">
                    Select the type of home that’s your primary residence.
                  </span>
                </div>
              </div>
            </div>
            <div className={`${que_value_col} col-12`}>
              <div
                className="btn-group d-flex flex-column gap-3"
                role="group"
                aria-label="Basic radio toggle button group"
                onChange={(e) => handleRadio(e.target.value, "home_kind")}
                onBlur={handleBlur}
                value={values.home_kind}
              >
                <RadioInput
                  value={"Single family home"}
                  name={'home_kind'}
                  w={"w-100"}
                  val={values.home_kind}
                  error={errors.home_kind}
                  touch={touched.home_kind}
                  checked={
                    values.home_kind == "Single family home" ? true : false
                  }
                />
                <RadioInput
                  value={"Condo"}
                  name={'home_kind'}
                  w={"w-100"}
                  val={values.home_kind}
                  error={errors.home_kind}
                  touch={touched.home_kind}
                  checked={
                    values.home_kind == "Condo" ? true : false
                  }
                />
                <RadioInput
                  value={"Rental"}
                  name={'home_kind'}
                  w={"w-100"}
                  val={values.home_kind}
                  error={errors.home_kind}
                  touch={touched.home_kind}
                  checked={values.home_kind == "Rental" ? true : false}
                />
                <RadioInput
                  value={"Mobile Home"}
                  name={'home_kind'}
                  w={"w-100"}
                  val={values.home_kind}
                  error={errors.home_kind}
                  touch={touched.home_kind}
                  checked={values.home_kind == "Mobile Home" ? true : false}
                />
              </div>
              {errors.home_kind && touched.home_kind && (<span className="text-danger">{errors.home_kind}</span>)}
            </div>
          </div>
          <div className="row g-2 mt-4">
            <div className={`${que_col} col-12`}>
              <div className="d-flex">
                <span
                  className="me-2 badge border border-user-secondary rounded-circle text-dark"
                  style={{ height: '28px', width: '28px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: values.address && values.city && values.state && values.zipcode ? color : '', padding: '2px' }}
                >
                  {(values.address && values.city && values.state && values.zipcode) ? <AiOutlineCheck style={{ color: 'white', fontSize: '20px' }} /> : <div style={numberStyle}>2</div>}
                </span>
                <div className="w-100 d-flex align-items-center">
                  <h3 className="mb-0">Where do you park your car overnight?</h3>
                </div>
              </div>
            </div>
            <div className={`${que_value_col} col-12`}>
              <div
                className="btn-group d-flex flex-row gap-3"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <div className="d-flex flex-column w-100">
                  <ReactGoogleAutocomplete
                    apiKey={GOOGLE_API_KEY}
                    options={{ types: ["geocode", "establishment"], componentRestrictions: { country: "us" } }}
                    types={["address"]}
                    onChange={(e) => setFieldValue('address', e.target.value)}
                    placeholder="Address"
                    onPlaceSelected={(place) => handleAddress(place)}
                    style={{ height: '52px', borderRadius: '4px' }}
                    className={classNames(`${!errors.address && 'user-autocomplete'} form-control form-control-md `, { 'is-invalid': submitting && errors.address })}
                    name={'address'}
                    value={values.address}
                  />
                  {errors.address && touched.address && <span className="text-danger">{errors.address}</span>}
                </div>
                <div className="d-flex flex-column w-100">
                  <TextFieldInput
                    w={"w-100"}
                    placeholder={"Unit #"}
                    name={"unit"}
                    value={values.unit}
                    error={errors.unit}
                    touched={touched.unit}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </div>
              </div>
              <div
                className="btn-group d-flex flex-row gap-3 mt-3"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <div className="d-flex flex-column w-100">
                  <TextFieldInput
                    w={"w-100"}
                    placeholder={"City"}
                    name={"city"}
                    value={values.city}
                    error={errors.city}
                    touched={touched.city}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </div>
                <div className="d-flex flex-column w-100">
                  <SearchSelect
                    w={"w-100"}
                    placeholder={"Select State"}
                    isSearchable={false}
                    name={"state"}
                    value={values.state}
                    error={errors.state}
                    touched={touched.state}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    opt={options}
                  />
                </div>
              </div>
              <div
                className="btn-group d-flex flex-column gap-3 mt-3"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <TextFieldInput
                  w={"w-100"}
                  placeholder={"Zipcode"}
                  name={"zipcode"}
                  value={values.zipcode}
                  error={errors.zipcode}
                  touched={touched.zipcode}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>
            </div>
          </div>
          <div className="row">
            {/* col-lg-10  */}
            <div className="col-12">
              <p className="mt-4 footer-text">By clicking "Save & continue," you consent to Insure the future savingthe information you entered and sharing it with insurancecarriers and other insurance professionals so you can get themost up-to-date quotes, no matter what device you're using.Additionally, carriers and insurance professionals may use thisto obtain information about your credit history. You also agreeto Insure the future's Privacy Policy and Terms of Service.
              </p>
            </div>
            {/* offset-lg-1 */}
            <div className="col-6 offset-3 w-75 w-lg-auto d-flex justify-content-end text-end">
              <ButtonInput name={"Save & continue"} type={"submit"} />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default HomeStart;
