import React, { useContext, useEffect, useState } from 'react'
import { FiPhoneCall } from 'react-icons/fi'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { RESULT } from '../services/UserRoutes';
import { color } from '../services/Methods';
import { UserValContext } from '../context/UserContextProvider';
import { FORM_SETTING } from '../../admin/services/AdminRoutePath';

const Header = () => {
  const styles = { width: 'fit-content' }
  const border = { borderRight: '2px solid #e1e0dc' }
  const { pathname } = useLocation()
  let { userManualStartDetail, userVehicleSelect, userDriverDetail } = useSelector((state) => state?.persistedReducer);
  
  const {button_color,font_color,bg_color,dot_color} = useContext(UserValContext)
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 992px)").matches
  )

  useEffect(() => {
    window
      .matchMedia("(min-width: 992px)")
      .addEventListener('change', e => setMatches(e.matches));
  }, []);

  return (
    <>
      <div className={`container-fluid pb-1 ${pathname != FORM_SETTING && 'position-fixed'}`} style={{ zIndex: 2, backgroundColor: bg_color}}>
        {/* <div >
          {matches && (<h1>Big Screen</h1>)}
          {!matches && (<h3>Small Screen</h3>)}
        </div> */}
        <div className="row justify-content-between p-3 align-items-baseline">
          <div className="col-3">
            <img src={require('../../assets/logo_green_orange2.png')} alt="image" style={{ height: '65px', width: '210px' }} />
          </div>
          <div className="col-9">
            <div className='justify-content-end d-flex'>
              {
                userManualStartDetail?.city && pathname == RESULT && <a href="#" className='text-decoration-none pe-2 mb-0 d-lg-block d-none' style={border}>
                  <h6 className='text-end text-dark text-decoration-underline' style={styles}>{`${userManualStartDetail?.city} , ${userManualStartDetail?.state?.value}`}</h6>
                </a>
              }
              {pathname == RESULT && <>
                {userVehicleSelect?.checkArray && <a href="#" className='text-decoration-none pe-2 mb-0 d-lg-block d-none' style={border}>
                  <h6 className='text-end text-dark ms-2 text-decoration-underline' style={styles}>{`${userVehicleSelect?.checkArray?.length + 1} vehicles`}</h6>
                </a>}
                {userDriverDetail?.drivers && <a href="#" className='text-decoration-none pe-2 mb-0 d-lg-block d-none' style={border}>
                  <h6 className='text-end text-dark ms-2 text-decoration-underline' style={styles}>{`${userDriverDetail?.drivers?.length + 1} drivers`}</h6>
                </a>}
              </>}
              <a href="#" className={`text-decoration-none pe-2 mb-0 `} style={{backgroundColor:!matches ? button_color :'',borderRadius:!matches ? '4px' :''}}>
                <h6 className='text-end ms-2 text-decoration-underline d-flex' style={styles}>
                  <FiPhoneCall className='me-1' style={{marginTop:!matches ? '9px':'',color:!matches ? 'white' : font_color}}/>
                  <span className='d-lg-block d-none' style={{color: font_color}}>
                    Speak to a licensed agent
                  </span>
                </h6>
              </a>
              {/* <a role="button" className="nav-phone-link d-inline-block overflow-hidden" data-cy="nav-phone-link"><span className="sr-only">Phone number</span><FiPhoneCall className='me-1' />
                <span className="phone-number-text" data-cy="phone-number-text">866.217.7056</span>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header