import React, { useState } from 'react'
import '../css/Result.css'
import ResultRadioCard from '../../../comp/js/ResultRadioCard'
import { AiOutlineUnorderedList, AiFillDollarCircle } from 'react-icons/ai'
import { BsFillShieldFill, BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { GoInfo } from 'react-icons/go'
import { vehicleCoverageOpt, policyCoverageOpt, listOpt } from '../../../services/Methods'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { MANUAL_START } from '../../../services/UserRoutes'

const Result = () => {

    let { userPackageDetail } = useSelector((state) => state?.persistedReducer)
    const [radioValue, setRadioValue] = useState(userPackageDetail?.package  ? userPackageDetail?.package : 'Minimum')
    const [aerrow1, setAerrow1] = useState(false)
    const [aerrow2, setAerrow2] = useState(false)
    const leftAerrow = () => <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"><path fill="currentColor" fillRule="evenodd" d="M21.7042017,11.29 L15.7042017,5.29 L14.2842017,6.71 L18.5842017,11 L1.99420168,11 L1.99420168,13 L18.5842017,13 L14.2842017,17.29 L15.7042017,18.71 L21.7042017,12.71 C21.8935143,12.5222334 22,12.2666375 22,12 C22,11.7333625 21.8935143,11.4777666 21.7042017,11.29 Z" transform="rotate(-180 11.997 12)" />
    </svg>
    let user = useSelector((state) => state?.persistedReducer?.userManualStartDetail);

    return (
        <div className='container-fluid' style={{ paddingTop: "20px" }}>
            <div className="row"><div className="col-12"><h2 className='mb-4'>{user?.fname} here are your personalized quotes</h2> </div></div>
            <div className="row mt-4">
                <div className="col-lg-3 col-4 d-md-block d-none">
                    <h4 className='text-dark'>Coverage Options</h4>
                    <ResultRadioCard values={['$25k/$50k', '$1,000']} condition={'Minimum'} id={'Minimum'} name={'Minimum'} radioValue={radioValue} setRadioValue={setRadioValue} />
                    <ResultRadioCard values={['$50k/$100k', '$1,000']} condition={'Better'} id={'Better'} name={'Better'} radioValue={radioValue} setRadioValue={setRadioValue} />
                    <ResultRadioCard values={['$100k/$300k', '$1,000']} condition={'Best'} id={'Best'} name={'Best'} radioValue={radioValue} setRadioValue={setRadioValue} />
                    <div className="custom-coverage-card"><div>
                        <div className="d-flex justify-content-between">
                            <h4 className="text-lg text-dark radio-title">Customize it</h4>
                            <div className="radio-control" /></div>
                        <div className="text-muted">You can choose your bodily injury limits and comprehensive/collision deductibles</div>
                    </div>
                        <div className="mt-5 d-md-none">
                            <button className="primary-button primary-button--purple primary-button--small primary-button--square primary-button--full-width primary-button--black" type="button">Select Coverage</button>
                        </div>
                    </div>
                    <hr className="my-5 d-none d-md-block" />
                    <h3 className="d-none d-md-block mb-1 text-dark">Need to edit something?</h3>
                    <div className="d-none d-md-block mb-5">
                        <NavLink to={MANUAL_START} className="mt-3 secondary-button secondary-button--gray secondary-button--md secondary-button--pill secondary-button--icon-left rateSelectBackButton">
                            {leftAerrow()}
                            <span>Edit Details</span>
                        </NavLink>
                    </div>
                </div>
                <div className="col-lg-9 col-md-8 col-12">
                    <div className='py-lg-5 py-3 px-lg-5 px-1 bg-white rounded-4'>
                        <div className='d-flex flex-row justify-content-between'>
                            <h3 className='w-100 d-flex justify-content-center align-items-center d-md-block'>Experian</h3>
                            <div className="accordion-item d-lg-block d-none">
                                <h2 className="accordion-header" id="headingOne">
                                    <button onClick={() => setAerrow1(!aerrow1)} className="accordion-button text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        {aerrow1 ? 'Close Details' : 'View Details'}
                                        {aerrow1 ? <BsChevronUp /> : <BsChevronDown />}
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body mb-3">
                                        <div className="card-body">
                                            <ul className="nav nav-tabs mb-3" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <a className="nav-link" data-bs-toggle="tab" href="#overview" role="tab" aria-selected="false" tabIndex={-1}>
                                                        <AiOutlineUnorderedList /> Overview
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a className="nav-link" data-bs-toggle="tab" href="#coverage" role="tab" aria-selected="false" tabIndex={-1}>
                                                        <BsFillShieldFill /> Coverage
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a className="nav-link" data-bs-toggle="tab" href="#pricing" role="tab" aria-selected="false" tabIndex={-1}>
                                                        <AiFillDollarCircle /> Pricing
                                                    </a>
                                                </li>
                                            </ul>
                                            <div className="tab-content mt-4">
                                                <div className="tab-pane" id="overview" role="tabpanel">
                                                    <ul className=" d-flex flex-column">{listOpt.map(({ text }, index) => <li key={index} className='my-1 fw-medium'>{text}</li>)}</ul>
                                                </div>
                                                <div className="tab-pane" id="coverage" role="tabpanel">
                                                    <div className="coverage-container">
                                                        <div className="policy-coverage">
                                                            <p className="tab-col-subheaders text-center fw-medium">Policy Coverage</p>
                                                            {
                                                                policyCoverageOpt.map(({ text, cost }, index) => <div className="row-wrap" key={index}>
                                                                    <div>
                                                                        <p className="coverage-key fw-medium">{text}</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-end">
                                                                        <div>
                                                                            <span className="text-dark fw-bolder mr-1">{cost}</span>
                                                                            <GoInfo className='ms-1 text-muted' style={{ fontSize: '18px' }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                )
                                                            }
                                                        </div>
                                                        <div className="vehicle-coverage">
                                                            <p className="tab-col-subheaders text-center fw-medium">Vehicle Coverage</p>
                                                            {
                                                                vehicleCoverageOpt.map(({ text, cost }, index) => <div className="row-wrap" key={index}>
                                                                    <div>
                                                                        <p className="coverage-key fw-medium">{text}</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-end">
                                                                        <div>
                                                                            <span className="text-dark fw-bolder mr-1">{cost}</span>
                                                                            <GoInfo className='text-muted ms-1' style={{ fontSize: '18px' }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="pricing" role="tabpanel">
                                                    <p className="mb-0 text-center fw-medium">
                                                        Detailed pricing information only available through Experian.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: 'fit-content', paddingLeft: '35px', borderLeft: '1px solid #e8e9e9', minWidth: '150px' }}>
                                <button className='btn-get-quote'>Get quote</button>
                            </div>
                        </div>
                    </div>
                    <div className='py-lg-5 py-3 px-lg-5 px-1 bg-white rounded-4 my-3'>
                        <div className='d-flex flex-row justify-content-between'>
                            <h3 className='w-100 d-flex justify-content-center align-items-center d-md-block pe-2 pe-lg-0'>
                                <img className="carrier-rectangular-logo w-100 me-0 me-lg-1" alt="Mercury Insurance logo" src={require('../../../../assets/mercury.png')} />
                            </h3>
                            <div className="accordion-item d-lg-block d-none">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button onClick={() => setAerrow2(!aerrow2)} className="accordion-button text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                        {aerrow2 ? 'Close Details' : 'View Details'}
                                        {aerrow2 ? <BsChevronUp /> : <BsChevronDown />}
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body mb-3">
                                        <div className="card-body">
                                            <ul className="nav nav-tabs mb-3" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <a className="nav-link" data-bs-toggle="tab" href="#overview2" role="tab" aria-selected="false" tabIndex={-1}>
                                                        <AiOutlineUnorderedList /> Overview
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a className="nav-link" data-bs-toggle="tab" href="#coverage2" role="tab" aria-selected="false" tabIndex={-1}>
                                                        <BsFillShieldFill /> Coverage
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a className="nav-link" data-bs-toggle="tab" href="#pricing2" role="tab" aria-selected="false" tabIndex={-1}>
                                                        <AiFillDollarCircle /> Pricing
                                                    </a>
                                                </li>
                                            </ul>
                                            <div className="tab-content mt-4">
                                                <div className="tab-pane" id="overview2" role="tabpanel">
                                                    <ul className=" d-flex flex-column">{listOpt.map(({ text }, index) => <li key={index} className='my-1 fw-medium'>{text}</li>)}</ul>
                                                </div>
                                                <div className="tab-pane" id="coverage2" role="tabpanel">
                                                    <div className="coverage-container">
                                                        <div className="policy-coverage">
                                                            <p className="tab-col-subheaders text-center fw-medium">Policy Coverage</p>
                                                            {
                                                                policyCoverageOpt.map(({ text, cost }, index) => <div className="row-wrap" key={index}>
                                                                    <div>
                                                                        <p className="coverage-key fw-medium">{text}</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-end">
                                                                        <div>
                                                                            <span className="text-dark fw-bolder mr-1">{cost}</span>
                                                                            <GoInfo className='ms-1 text-muted' style={{ fontSize: '18px' }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                )
                                                            }
                                                        </div>
                                                        <div className="vehicle-coverage">
                                                            <p className="tab-col-subheaders text-center fw-medium">Vehicle Coverage</p>
                                                            {
                                                                vehicleCoverageOpt.map(({ text, cost }, index) => <div className="row-wrap" key={index}>
                                                                    <div>
                                                                        <p className="coverage-key fw-medium">{text}</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-end">
                                                                        <div>
                                                                            <span className="text-dark fw-bolder mr-1">{cost}</span>
                                                                            <GoInfo className='text-muted ms-1' style={{ fontSize: '18px' }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="pricing2" role="tabpanel">
                                                    <p className="mb-0 text-center fw-medium">
                                                        Detailed pricing information only available through Experian.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: 'fit-content', paddingLeft: '35px', borderLeft: '1px solid #e8e9e9', minWidth: '150px' }}>
                                <button className='btn-get-quote'>Get quote</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Result