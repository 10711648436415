import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import SearchSelect from "../../../comp/js/SearchSelect";
import CheckboxInput from "../../../comp/js/CheckboxInput";
import { NavLink, useNavigate } from "react-router-dom";
import { MANUAL_START_DETAIL, VEHICLE_DETAIL } from "../../../services/UserRoutes";
import ButtonInput from "../../../comp/js/ButtonInput";
import { AiOutlineCheck } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { saveVehicleSelect } from "./../../../../redux/action/UserAction";
import { color, que_col,que_value_col,requiredFeild } from "../../../services/Methods";
import { UserValContext } from "../../../context/UserContextProvider";

const VehicleSelect = () => {

  const {button_color,dot_color} = useContext(UserValContext)
  const dispatch = useDispatch();
  let details = useSelector((state) => state?.persistedReducer?.userVehicleSelect);

  const numberStyle = { padding: "5px 7px" ,fontSize:'18px'};

  const navigate = useNavigate();

  const [yearArray, setYearArray] = useState([]);
  const [checkArray, setCheckArray] = useState(details != undefined && Object.keys(details)?.length > 0 && details?.checkArray ? details?.checkArray : []);
  const [schema, setSchema] = useState(yup.object().required(requiredFeild));
  const [validationObj, setValidationObj] = useState({ vehicle10: schema, vehicle11: schema, vehicle12: schema, vehicle13: schema });
  const [validation, setValidation] = useState(details != undefined && Object.keys(details).length > 0 ? details : { vehicle10: "", vehicle11: "", vehicle12: "", vehicle13: "", check1: false });

  const { values, setFieldValue, errors, handleBlur, handleChange, handleSubmit, touched, setValues } = useFormik({
    initialValues: validation,
    validationSchema: yup.object().shape(validationObj),
    onSubmit: (values, { resetForm }) => {
      dispatch(saveVehicleSelect({ ...values, checkArray: checkArray }));
      if (values) navigate(VEHICLE_DETAIL);
    },
  });

  const renderVehicle = (item) => {
    return (
      <div className="row gy-4 mt-3">
        <div className={`${que_col} col-12`}>
          <div className="d-flex">
            <span
              className="me-2 badge border border-user-secondary rounded-circle text-dark"
              style={{
                height: "28px", width: "28px", display: "flex", justifyContent: "center", alignItems: "center",
                backgroundColor:
                  values[`vehicle${item}0`] &&
                    values[`vehicle${item}1`] &&
                    values[`vehicle${item}2`] &&
                    values[`vehicle${item}3`]
                    ? dot_color
                    : "",
                padding: "2px",
              }}
            >
              {values[`vehicle${item}0`] &&
                values[`vehicle${item}1`] &&
                values[`vehicle${item}2`] &&
                values[`vehicle${item}3`] ? (
                <AiOutlineCheck style={{ color: "white", fontSize: "20px" }} />
              ) : (
                <div style={numberStyle}>{item+1}</div>
              )}
            </span>
            <h3>Let’s add your vehicle</h3>
          </div>
        </div>
        <div className={`${que_value_col} col-12`}>
          <div
            className="btn-group d-flex flex-column gap-3"
            role="group"
            aria-label="Basic radio toggle button group"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.shopping}
          >
            <SearchSelect
              count={item}
              placeholder={"Select vehicle year"}
              isDisabled={false}
              name={`vehicle${item}0`}
              value={values[`vehicle${item}0`]}
              setFieldValue={setFieldValue}
              error={errors[`vehicle${item}0`]}
              touched={touched[`vehicle${item}0`]}
              type={"vehicle_year"}
              options={year_final}
            />
            <SearchSelect
              count={item}
              placeholder={"Select vehicle make"}
              isDisabled={values[`vehicle${item}0`] ? false : true}
              name={`vehicle${item}1`}
              value={values[`vehicle${item}1`]}
              setFieldValue={setFieldValue}
              error={errors[`vehicle${item}1`]}
              touched={touched[`vehicle${item}1`]}
              type={"vehicle_make"}
            />
            <SearchSelect
              count={item}
              placeholder={"Select vehicle model"}
              isDisabled={values[`vehicle${item}1`] ? false : true}
              name={`vehicle${item}2`}
              value={values[`vehicle${item}2`]}
              setFieldValue={setFieldValue}
              error={errors[`vehicle${item}2`]}
              touched={touched[`vehicle${item}2`]}
              type={"vehicle_model"}
            />
            <SearchSelect
              count={item}
              placeholder={"Select vehicle trim"}
              isDisabled={values[`vehicle${item}2`] ? false : true}
              name={`vehicle${item}3`}
              value={values[`vehicle${item}3`]}
              setFieldValue={setFieldValue}
              error={errors[`vehicle${item}3`]}
              touched={touched[`vehicle${item}3`]}
              type={"vehicle_trim"}
            />
          </div>
        </div>
        {item <= 3 && (
          <>
            <div className={`${que_col} col-12`}>
              <div className="d-flex mt-5 mt-lg-0">
                <span
                  className="me-2 badge border border-user-secondary rounded-circle text-dark"
                  style={{  height: "28px", width: "28px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: values[`vehicle${item}0`] && values[`vehicle${item}1`] && values[`vehicle${item}2`] && values[`vehicle${item}3`] ? dot_color : "", padding: "2px" }}>
                  {values[`vehicle${item}0`] && values[`vehicle${item}1`] && values[`vehicle${item}2`] && values[`vehicle${item}3`] ? (<AiOutlineCheck style={{ color: "white", fontSize: "20px" }} />) : (<div style={numberStyle}>{item+2}</div>)}
                </span>
                <div className="w-100">
                  <h3>Need to add another vehicle?</h3>
                  <span className="text-start w-w-100">
                    You can add up to 4 vehicles.
                  </span>
                </div>
              </div>
            </div>
            <div className={`${que_value_col} col-12`}>
              <div
                className="btn-group d-flex flex-row gap-3"
                role="group"
                aria-label="Basic radio toggle button group"
                id={`check${item}`}
                name={`check${item}`}
                onChange={() => handleCheck(item + 1)}
                onBlur={handleBlur}
                value={values[item]}
              >
                <CheckboxInput
                  w={"w-100"}
                  checked={checkArray.includes(item + 1) ? true : false}
                  isDisabled={
                    values[`vehicle${item}0`] &&
                      values[`vehicle${item}1`] &&
                      values[`vehicle${item}2`] &&
                      values[`vehicle${item}3`]
                      ? false
                      : true
                  }
                  value={true}
                  val={checkArray.includes(item + 1) ? true : false}
                  nm={`vehicle${item}`}
                  name={`vehicle${item}`}
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const handleCheck = (id) => {
    if (checkArray.includes(id)) {
      setCheckArray(checkArray.slice(0, -1));

      let vobj = validationObj;
      let sobj = validation;

      Array.from({ length: 4 }, (_, i) => i).map((ind) => {
        delete vobj[`vehicle${id}${ind}`];
        delete sobj[`vehicle${id}${ind}`];
      });

      setValidation(sobj);
      setValidationObj(vobj);
    } else if (checkArray.length <= 3) {
      setCheckArray((checkArray) => [...checkArray, id]);
      let vobj = validationObj;
      let sobj = validation;

      Array.from({ length: 4 }, (_, i) => i).map((ind) => {
        vobj[`vehicle${id}${ind}`] = schema;
        sobj[`vehicle${id}${ind}`] = "";
      });

      // vobj[`check${id}`] = schema;
      sobj[`check${id}`] = false;

      setValidation(sobj);
      setValidationObj(vobj);
    } else return;
  };

  const year = [];
  useEffect(() => {
    for (var i = 1981; i <= new Date().getFullYear(); i++) {year.push(i)}
    setYearArray(year);
  }, []);
  const year_final = yearArray?.map((b) => {return { value: b, label: b }});

  return (
    <>
      <div className="container-fluid g-3 mb-5 pt-3" style={{ paddingTop: "20px" }}>
        <div className="row gy-4">
          <h2>Next, let‘s add your vehicles</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row gy-4 mt-3 ">
            <div className={`${que_col} col-12`}>
              <div className="d-flex">
                <span
                  className="me-2 badge border border-user-secondary rounded-circle text-dark"
                  style={{ height: "28px", width: "28px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: values[`vehicle10`] && values[`vehicle11`] && values[`vehicle12`] && values[`vehicle13`] ? dot_color : "", padding: "2px" }}>
                  {values[`vehicle10`] && values[`vehicle11`] && values[`vehicle12`] && values[`vehicle13`] ? (<AiOutlineCheck style={{ color: "white", fontSize: "20px" }} />) : (
                    <div style={numberStyle}>1</div>)}
                </span>
                <h3>Let’s add your vehicle</h3>
              </div>
            </div>
            <div className={`${que_value_col} col-12`}>
              <div
                className="btn-group d-flex flex-column gap-3"
                role="group"
                aria-label="Basic radio toggle button group"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.shopping}
              >
                <SearchSelect
                  count={1}
                  placeholder={"Select vehicle year"}
                  isDisabled={false}
                  name={"vehicle10"}
                  value={values.vehicle10}
                  setFieldValue={setFieldValue}
                  error={errors.vehicle10}
                  touched={touched.vehicle10}
                  options={year_final}
                  type={"vehicle_year"}
                />
                <SearchSelect
                  count={1}
                  placeholder={"Select vehicle make"}
                  isDisabled={values.vehicle10 ? false : true}
                  name={"vehicle11"}
                  value={values.vehicle11}
                  setFieldValue={setFieldValue}
                  error={errors.vehicle11}
                  touched={touched.vehicle11}
                  type={"vehicle_make"}
                />
                <SearchSelect
                  count={1}
                  placeholder={"Select vehicle model"}
                  isDisabled={values.vehicle11 ? false : true}
                  name={"vehicle12"}
                  value={values.vehicle12}
                  setFieldValue={setFieldValue}
                  error={errors.vehicle12}
                  touched={touched.vehicle12}
                  type={"vehicle_model"}
                />
                <SearchSelect
                  count={1}
                  placeholder={"Select vehicle trim"}
                  isDisabled={values.vehicle12 ? false : true}
                  name={"vehicle13"}
                  value={values.vehicle13}
                  setFieldValue={setFieldValue}
                  error={errors.vehicle13}
                  touched={touched.vehicle13}
                  type={"vehicle_trim"}
                />
              </div>
            </div>
            <div className={`${que_col} col-12`}>
              <div className="d-flex mt-5 mt-lg-0">
                <span
                  className="me-2 badge border border-user-secondary rounded-circle text-dark"
                  style={{  height: "28px", width: "28px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: values[`vehicle10`] && values[`vehicle11`] && values[`vehicle12`] && values[`vehicle13`] ? dot_color : "", padding: "2px" }}>
                  {values[`vehicle10`] && values[`vehicle11`] && values[`vehicle12`] && values[`vehicle13`] ? (<AiOutlineCheck style={{ color: "white", fontSize: "20px" }} />) : (<div style={numberStyle}>2</div>)}
                </span>
                <div className="w-100">
                  <h3>Need to add another vehicle?</h3>
                  <span className="text-start w-w-100">
                    You can add up to 4 vehicles.
                  </span>
                </div>
              </div>
            </div>
            <div className={`${que_value_col} col-12`}>
              <div
                className="btn-group d-flex flex-row gap-3"
                role="group"
                aria-label="Basic radio toggle button group"
                id="check1"
                name="check1"
                onChange={() => handleCheck(2)}
                onBlur={handleBlur}
                value={values.check1}
              >
                <CheckboxInput
                  w={"w-100"}
                  checked={checkArray.includes(2) ? true : false}
                  isDisabled={
                    values[`vehicle10`] &&
                      values[`vehicle11`] &&
                      values[`vehicle12`] &&
                      values[`vehicle13`]
                      ? false
                      : true
                  }
                  value={true}
                  val={checkArray.includes(2) ? true : false}
                  name={'vehicle10'}
                />
              </div>
            </div>
          </div>

          {checkArray.length > 0 && checkArray.map((item) => { return renderVehicle(item); })}

          <div className="row">
          {/* col-lg-10  */}
            <div className="gy-5 col-12 d-flex justify-content-between">
              <NavLink
                to={MANUAL_START_DETAIL}
                className={'mt-lg-0 mt-4'}
                style={{ color: "#4a4f53", fontWeight: "600", display: "flex", alignItems: "center", }}
              >
                Back
              </NavLink>
              <ButtonInput name={"Save & continue"} type="submit" errors={errors}/>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default VehicleSelect;
