import React, { useContext, useEffect, useState } from "react";
import RadioInput from "../../../comp/js/RadioInput";
import "../css/ManualStart.css";
import TextFieldInput from "../../../comp/js/TextFieldInput";
import { BsInfoCircle } from "react-icons/bs";
import { color, que_col, que_value_col, showError } from "../../../services/Methods";
import { UserValContext } from "../../../context/UserContextProvider";
import { parse,differenceInYears } from "date-fns";

const AnotherDriver = ({ status, count, drivers, setDrivers, setFieldError, handleAnotherDriver, name, values, touched, errors, handleBlur, handleChange, radioValue, setFieldValue}) => {

  const {submitting,button_color,setSubmitting,dot_color} = useContext(UserValContext)  

    const calculateAge = (dob) => {
      const date = parse(dob, "MM/dd/yyyy", new Date())      
        if (date == 'Invalid Date' && submitting) setFieldError(`bdate${count}`, 'Invalid Date !')
        const age = differenceInYears(new Date(), date)
        if (age < 16) setFieldError(`bdate${count}`, 'User must be 16 years old !')
      
    }
    useEffect(() => {
      if (!values[`bdate${count}`] || values[`bdate${count}`] == '' && submitting) setFieldError(`bdate${count}`,'This Field is required !')
      else if(values[`bdate${count}`]?.length == 10) calculateAge(values[`bdate${count}`])
      else setFieldError[`bdate${count}`]= 'This Field is required !'
    }, [values,errors,count,submitting])
    

  return (
    <>
      <div
        className="container-fluid w-100 pt-3 g-3 mb-3"
        style={{ paddingTop: "20px" }}

      >
        <div className="row g-2 mt-3">
          <div className={`${que_col} col-12`}>
            <div className="d-flex">
              <span
                className="me-2 badge border border-user-secondary rounded-circle text-dark"
                style={{height: "28px",width: "28px",display: "flex",justifyContent: "center",alignItems: "center",}}>
                1
              </span>
              <div className="w-100">
                <h3>{status && count == 2 ? 'Tell us a little about your spouse.' : 'Tell us a little about them'}</h3>
                <span className="description fw-bold" style={{ color: dot_color }}>
                  <BsInfoCircle className="fs-5 me-1" />
                  <a href="#" style={{ color: dot_color }} className="m-0 text-decoration-none">Learn about how your information is used</a>
                </span>
              </div>
            </div>
          </div>
          <div className={`${que_value_col} col-12`}>
            <div
              className="btn-group d-flex flex-column gap-3"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <TextFieldInput
                w={"w-100"}
                placeholder={"First name"}
                error={errors[`fname${count}`]}
                touched={touched[`fname${count}`]}
                name={`fname${count}`}
                value={values[`fname${count}`]}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <TextFieldInput
                w={"w-100"}
                placeholder={"Last name"}
                error={errors[`lname${count}`]}
                touched={touched[`lname${count}`]}
                name={`lname${count}`}
                value={values[`lname${count}`]}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <TextFieldInput
                 maxlength={10}
                 pattern="[0-9/]+"
                 type={'tel'}
                 w={"w-100"}
                 placeholder={"Birthdate"}
                 holder={'MM/DD/YYYY'}
                 // error={ dateError[`bdate${count}error`]}
                 error={errors[`bdate${count}`]}
                 value={values[`bdate${count}`]}
                 handleChange={handleChange}
                 touched={touched[`bdate${count}`]}
                 name={`bdate${count}`}
                 handleBlur={handleBlur}
                />
              {/* <TextFieldInput
                w={"w-100"}
                placeholder={"Birthdate"}
                error={errors[`bdate${count}`]}
                touched={touched[`bdate${count}`]}
                name={`bdate${count}`}
                value={values[`bdate${count}`]}
                handleChange={handleChange}
                handleBlur={handleBlur}
                type={"date"}
              /> */}
            </div>
          </div>
        </div>

        <div className="row g-2 mt-3">
          <div className={`${que_col} col-12`}>
            <div className="d-flex">
              <span
                className="me-2 badge border border-user-secondary rounded-circle text-dark"
                style={{
                  height: "28px",
                  width: "28px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                2
              </span>
              <div className="w-100">
                <h3>{status && count == 2? 'What’s your spouse’s gender?' : 'What’s your gender?'}</h3>
                <span className="text-start w-w-100">
                  Please select the gender listed on their driver’s license.
                </span>
              </div>
            </div>
          </div>
          <div className={`${que_value_col} col-12`}>
            <div
              className="btn-group d-flex flex-column gap-3"
              role="group"
              aria-label="Basic radio toggle button group"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[`gender${count}`]}
            >
              <RadioInput value={"Male"} name={`gender${count}`} w={"w-100"} val={values[`gender${count}`]} error={errors[`gender${count}`]} touch={touched[`gender${count}`]} checked={values[`gender${count}`] == "Male" ? true : false}/>
              <RadioInput value={"Female"} name={`gender${count}`} w={"w-100"} val={values[`gender${count}`]} error={errors[`gender${count}`]} touch={touched[`gender${count}`]} checked={values[`gender${count}`] == "Female" ? true : false}/>
              <RadioInput value={"Non Binary"} name={`gender${count}`} w={"w-100"} val={values[`gender${count}`]} error={errors[`gender${count}`]} touch={touched[`gender${count}`]} checked={values[`gender${count}`] == "Non Binary" ? true : false}/>
            </div>
            {errors[`gender${count}`] && (<span className="text-danger fs-4">{showError(errors[`gender${count}`],submitting)}</span>)}
          </div>
        </div>
        {
          ((status && count != 2) || (!status)) && <>

            <div className="row g-2 mt-3">
              <div className={`${que_col} col-12`}>
                <div className="d-flex">
                  <span
                    className="me-2 badge border border-user-secondary rounded-circle text-dark"
                    style={{ height: "28px", width: "28px", display: "flex", justifyContent: "center", alignItems: "center",}}>
                    3
                  </span>
                  <div className="w-100">
                    <h3>What’s their relationship to you?</h3>
                  </div>
                </div>
              </div>
              <div className={`${que_value_col} col-12`}>
                <div
                  className="btn-group d-flex flex-row gap-3"
                  role="group"
                  aria-label="Basic radio toggle button group"
                  id={`relationship${count}`}
                  name={`relationship${count}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values[`relationship${count}`]}
                >
                  <RadioInput value={"Child"} name={`relationship${count}`} w={"w-50"} val={values[`relationship${count}`]} error={errors[`relationship${count}`]} touch={touched[`relationship${count}`]} checked={values[`relationship${count}`] == "Child" ? true : false}/>
                  <RadioInput value={"Other"} name={`relationship${count}`} w={"w-50"} val={values[`relationship${count}`]} error={errors[`relationship${count}`]} touch={touched[`relationship${count}`]} checked={values[`relationship${count}`] == "Other" ? true : false}/>
                </div>
                {errors[`relationship${count}`] && (<span className="text-danger fs-4">{showError(errors[`relationship${count}`],submitting)}</span>
                )}
              </div>
            </div>

            <div className="row g-2 mt-3">
              <div className={`${que_col} col-12`}>
                <div className="d-flex">
                  <span
                    className="me-2 badge border border-user-secondary rounded-circle text-dark"
                    style={{ height: "28px", width: "28px", display: "flex", justifyContent: "center", alignItems: "center",}}>
                    4
                  </span>
                  <div className="w-100">
                    <h3>What’s your marital status?</h3>
                  </div>
                </div>
              </div>
              <div className={`${que_value_col} col-12`}>
                <div
                  className="btn-group d-flex flex-column gap-3"
                  role="group"
                  aria-label="Basic radio toggle button group"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values[`marital_status${count}`]}
                >
                  <RadioInput value={"Single"} name={`marital_status${count}`} w={"w-100"} val={values[`marital_status${count}`]} error={errors[`marital_status${count}`]} touch={touched[`marital_status${count}`]} checked={values[`marital_status${count}`] == "Single" ? true : false}/>
                  <RadioInput value={"Married"} name={`marital_status${count}`} w={"w-100"} val={values[`marital_status${count}`]} error={errors[`marital_status${count}`]} touch={touched[`marital_status${count}`]} checked={values[`marital_status${count}`] == "Married" ? true : false}/>
                  <RadioInput value={"Divorced"} name={`marital_status${count}`} w={"w-100"} val={values[`marital_status${count}`]} error={errors[`marital_status${count}`]} touch={touched[`marital_status${count}`]} checked={values[`marital_status${count}`] == "Divorced" ? true : false}/>
                  <RadioInput value={"Widowed"} name={`marital_status${count}`} w={"w-100"} val={values[`marital_status${count}`]} error={errors[`marital_status${count}`]} touch={touched[`marital_status${count}`]} checked={values[`marital_status${count}`] == "Widowed" ? true : false}/>
                </div>
                {errors[`marital_status${count}`] && (<span className="text-danger fs-4">{showError(errors[`marital_status${count}`],submitting)}</span>)}
              </div>
            </div>
          </>
        }

        {count < 4 && (
          <div className="row g-2 mt-3">
            <div className={`${que_col} col-12`}>
              <div className="d-flex">
                <span
                  className="me-2 badge border border-user-secondary rounded-circle text-dark"
                  style={{
                    height: "28px",
                    width: "28px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {status && count == 2? 3 : 5}
                </span>
                <div className="w-100">
                  <h3>Need to add another driver?</h3>
                  <span className="text-start w-w-100">
                    You can add up to 4 drivers.
                  </span>
                </div>
              </div>
            </div>
            <div className={`${que_value_col} col-12`}>
              <div
                className="btn-group d-flex flex-row gap-3"
                role="group"
                aria-label="Basic radio toggle button group"
                id={name}
                name={name}
                onChange={(e) =>handleAnotherDriver(e.target.value, name, count)}
                onBlur={handleBlur}
                value={radioValue}
              >
                <RadioInput
                  value={"Yes"}
                  name={name}
                  w={"w-50"}
                  val={radioValue}
                  checked={radioValue == "Yes" ? true : false}
                  error={errors[name]}
                  touch={touched[name]}
                />
                <RadioInput
                  value={"No"}
                  name={name}
                  w={"w-50"}
                  val={radioValue}
                  checked={radioValue == "No" ? true : false}
                  error={errors[name]}
                  touch={touched[name]}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AnotherDriver;
