import React, { useEffect } from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router'
import InputButton from '../Input Function/InputButton'
import AdminStater from '../layouts/AdminStater'
import AddModel from '../models/AddModel'
import { ACTIVE_CAMPAIGN, ALL_BRANDS, ALL_INSUR_COMP, ALL_LEAD, ALL_MODELS, ALL_TRIMNAME, ALL_VEHICLE, GOOGLE_SHEET, ZAPIER } from '../services/AdminRoutePath'
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux'
import { addGoogleSheetAccount, getActiveCampaignList, getBrands, getBrandsList, getData, getDataList, getGoogleSheetAccount, getInsurCompsList, getModels, getModelsList, getTrimName, getTrimNameList, getUserList, getVehicles, getVehiclesList, GET_ACTIVE_CAMPAIGN_LIST, GET_GOOGEL_SHEET_USER } from '../../redux/action/AdminAction'
import Pagination from "react-js-pagination";
import moment from 'moment'
import CsvLink from 'react-csv-export';
import "../css/GoogleSheet.css"
import { GoogleButton } from "react-google-oauth2";
import GoogleSheetModel from '../models/GoogleSheetModel'
import DeleteModel from '../models/DeleteModel'
import InputCheckBox from '../Input Function/InputCheckBox'
import { backend_url } from '../../user/services/Methods'
import BackDrop from '../Input Function/BackDrop'
import { Spinner } from 'react-bootstrap'
const IntegrationExportPage = ({ path }) => {
    const [heading, setHeading] = useState();
    const [loading, setLoading] = useState(false);
    const [action, setAction] = useState();
    const [tbhead, setTBHead] = useState([]);
    const [show, setShow] = useState(false);
    const [showGoogleSheet, setShowGoogleSheet] = useState(false);
    const [editItem, setEditItem] = useState();
    const [viewItem, setViewItem] = useState();
    const [deleteId, setdeleteId] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    const dispatch = useDispatch();
    const options = {
        clientId: process.env.REACT_APP_CLIENT_ID,
        redirectUri: process.env.REACT_APP_REDIRECT_URL,
        scopes: ['https://www.googleapis.com/auth/userinfo.profile',
            'https://www.googleapis.com/auth/userinfo.email', 'https://www.googleapis.com/auth/spreadsheets', 'https://www.googleapis.com/auth/drive.file', 'https://www.googleapis.com/auth/spreadsheets.readonly', 'https://www.googleapis.com/auth/drive.readonly'],
        includeGrantedScopes: true,
        accessType: "offline",
        responseType: "code",
        prompt: "consent",
    };
    let load = false;
    useEffect(() => {
        if (!load) {
            switch (path) {
                case GOOGLE_SHEET:
                    setHeading("Google Sheet")
                    setTBHead([{ key: "Id", width: "10%" }, { key: "Name", width: "" }, { key: "Access Token", width: "" }, { key: "Refresh Token", width: "" }, { key: "Action", width: "12%" }])
                    break
                case ACTIVE_CAMPAIGN:
                    setHeading("Active Campaign")
                    setTBHead([{ key: "Id", width: "10%" }, { key: "Name", width: "" }, { key: "API Url", width: "" }, { key: "API Key", width: "" }, { key: "Action", width: "12%" }])
                    break
                case ZAPIER:
                    setHeading("Zapier")
                    setTBHead([{ key: "Id", width: "10%" }, { key: "Status", width: "" }, { key: "Name", width: "" }, { key: "Connection", width: "" }, { key: "Action", width: "12%" }])
                    break
                default:
                    break
            }
        }
        return () => { load = true }
    }, [])

    const handleExportData = () => {
        setShowGoogleSheet(true)
    }
    const handleAddData = () => {
        setAction("add");
        setShow(true)
    }
    const handleEditData = (item) => {
        setAction("edit");
        setShow(true)
        setEditItem(item)
    }
    const handleDelete = (id) => {
        setAction("delete");
        setShow(true)
        setdeleteId(id)
    }
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    }    


    const googleSheetAc = useSelector((state) => state.AdminReducer.googleSheetAc);
    const googleSheetUserItemCount = useSelector((state) => state.AdminReducer.googleSheetUserItemCount);
    const activeCampaignPerPageList = useSelector((state) => state.AdminReducer.activeCampaignPerPageList);
    const activeCampaignTotalItemCount = useSelector((state) => state.AdminReducer.activeCampaignTotalItemCount);

    useEffect(() => {
        switch (path) {

            case GOOGLE_SHEET:
                setLoading(true)
                dispatch(getDataList(setLoading,`admin/get-google-sheet-users/?page=${currentPage === 0 ? 1 : currentPage}`,GET_GOOGEL_SHEET_USER));
                break
            case ACTIVE_CAMPAIGN:
                setLoading(true)
                dispatch(getDataList(setLoading,`admin/get-active-campaign-users/?page=${currentPage === 0 ? 1 : currentPage}`,GET_ACTIVE_CAMPAIGN_LIST));
                break
            default:
                break
        }

    }, [currentPage]);

    return (
        <>
       
            {show ? <AddModel heading={heading} setHeading={setHeading} action={action} setAction={setAction} show={show} setShow={setShow} editItem={editItem} setEditItem={setEditItem} deleteId={deleteId} setdeleteId={setdeleteId} currentPage={currentPage} setCurrentPage={setCurrentPage} viewItem={viewItem} setViewItem={setViewItem} /> : null}
            {showGoogleSheet ? <GoogleSheetModel heading={heading} showGoogleSheet={showGoogleSheet} setShowGoogleSheet={setShowGoogleSheet} /> : null}
            <div className="container-fluid">
                <AdminStater />
                <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
                    <div className="file-manager-content w-100 p-4 pb-0">

                        <div className="rounded mb-3">
                            <div className="row g-2 justify-content-end">
                                <div className="col-lg-auto ">
                                    {/* <InputButton className={path == GOOGLE_SHEET ? `btn btn-success createTask` : `btn btn-info createTask`} type="button" databstoggle="modal" databstarget="#createTask" icon="las la-arrow-down" name={`Export to ${heading}`} event={() => { handleExportData() }} /> */}
                                    {path == GOOGLE_SHEET ? <>
                                      
                                        <GoogleButton
                                            options={options}
                                            apiUrl={`${backend_url}api/auth/google`}
                                            className="btn btn-primary createTask ms-2"
                                        > + Add Account</GoogleButton></> : 
                                        <InputButton className="btn btn-primary createTask ms-2" type="button" databstoggle="modal" databstarget="#createTask" icon="ri-add-fill align-bottom" name={`Add ${heading == "Active Campaign" ? "Account" : heading}`} event={() => { handleAddData() }} />}
                                </div>
                            </div>
                        </div>
                        <div className="todo-content position-relative px-4 mx-n4" id="todo-content" style={{height: 'calc(100vh - 252px)'}}>
                            <div className="todo-task" id="todo-task">
                                <div className="table-responsive table-card mb-1 p-3">
                                    <table className="table align-middle table-nowrap" id="customerTable">
                                        <thead className="table-orange">
                                            <tr>
                                                {tbhead.map((t, index) => {
                                                    return <th className="" key={index} style={{ width: t?.width,fontSize:'17px' }}>{t?.key}</th>
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody className="list form-check-all" style={{fontSize:'16px'}}>
                                            {heading == "Google Sheet" ?
                                             loading?<tr className='border-0'><td colSpan={window.innerWidth > 500 ? 5 : 4} className='border-0'><div className='position-relative mt-2 mb-5'><Spinner animation="border" className='loader-top' /></div></td></tr>:
                                                googleSheetAc?.map((b, index) => {
                                                    return <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{b?.name}</td>
                                                        <td><div className='token-text'>{b?.access_token}</div></td>
                                                        <td><div className='token-text'>{b?.refresh_token}</div></td>
                                                        {/* <td><div className="form-check form-switch form-switch-md ps-2" dir="ltr">
                                                           <InputCheckBox type="checkbox" className={"form-check-input"} check = {b?.status}/>
                                                        </div>
                                                        </td> */}
                                                        <td>
                                                            <div className="d-flex gap-2 ">
                                                                <InputButton className="btn btn-sm btn-danger remove-item-btn" name="Remove" event={() => { handleDelete(b?.id) }}></InputButton>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }) : heading == "Active Campaign" ?
                                                loading?<tr className='border-0'><td colSpan={window.innerWidth > 500 ? 5 : 4} className='border-0'><div className='position-relative mt-2 mb-5'><Spinner animation="border" className='loader-top' /></div></td></tr>:
                                                    activeCampaignPerPageList?.map((b, index) => {
                                                        return <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{b?.name}</td>
                                                            <td><div className='token-text'>{b?.api_url}</div></td>
                                                            <td><div className='token-text'>{b?.api_key}</div></td>
                                                            <td>
                                                                <div className="d-flex gap-2 ">
                                                                    <InputButton className="btn btn-sm btn-success" name="Edit" event={() => { handleEditData(b) }}></InputButton>
                                                                    <InputButton className="btn btn-sm btn-danger remove-item-btn" name="Remove" event={() => { handleDelete(b?.id) }}></InputButton>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    }) : null
                                            }

                                        </tbody>
                                    </table>
                                    <div className="d-flex justify-content-end mt-2">
                                        <Pagination
                                            activePage={currentPage}
                                            itemsCountPerPage={10}
                                            totalItemsCount={path == GOOGLE_SHEET ? googleSheetUserItemCount : path == ACTIVE_CAMPAIGN ? activeCampaignTotalItemCount : 0}
                                            pageRangeDisplayed={5}
                                            innerClass="pagination listjs-pagination mb-0"
                                            activeClass="page-item-active"
                                            activeLinkClass="page-item-active"
                                            linkClass="page-item"
                                            onChange={(value) => { handlePageChange(value) }}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default IntegrationExportPage