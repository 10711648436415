import React, { useContext } from 'react'
import { useEffect } from 'react';
import { AdminValAuthContext } from '../context/AdminAuthProvider'
import { sidebarToggle } from '../services/AdminLocalStorage';

const AdminFooter = () => {
  const val = useContext(AdminValAuthContext);
  let windowWidth;
  useEffect(() => {
    windowWidth = (window.innerWidth)
    let divId = document.getElementById('footer')
    sidebarToggle(divId,windowWidth,val?.showSidebar)
  }, [windowWidth,val?.showSidebar])
  return (
    <>
      <footer className="footer" id={'footer'} >
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              © Insure the Future.
            </div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block">
                Design &amp; Developed By Insure the Future
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default AdminFooter