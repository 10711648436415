
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import InputButton from '../Input Function/InputButton';
import InputLodingButton from '../Input Function/InputLodingButton';
import InputText from '../Input Function/InputText';
import '../css/AdminGoogleModel.css'
import InputLabel from '../Input Function/InputLabel';
import InputSelectBox from '../Input Function/InputSelectBox';
import * as yup from 'yup'
import { ACTIVE_CAMPAIGN, GOOGLE_SHEET } from '../services/AdminRoutePath';
import { useLocation } from 'react-router';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { addActiveCampaignData, addGoogleSheetData,  getDatas, GET_ACTIVE_CAMPAIGN, GET_GOOGLE_ALL_USERS } from '../../redux/action/AdminAction';
const GoogleSheetModel = ({ heading, showEditLeads, setShowEditLeads }) => {
    const [loading, setLoading] = useState(false);
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const options = [
        { value: 'brand', label: 'Brand' },
        { value: 'model', label: 'Model' },
        { value: 'trimname', label: 'Trim name' },
        { value: 'vehicle', label: 'Vehicle' },
        { value: 'lead', label: 'Lead' },
        { value: 'insurcomp', label: 'Insurance company' },
    ]
    let load = false;
    useEffect(() => {
        if (!load) {
            switch (pathname) {
                case GOOGLE_SHEET:
                    dispatch(getDatas(setLoading,`admin/get-all-google-sheet-users`,GET_GOOGLE_ALL_USERS))
                    break;
                case ACTIVE_CAMPAIGN:
                    dispatch(getDatas(setLoading,'admin/get-active-campaign',GET_ACTIVE_CAMPAIGN))
                    break;
                default:
                    break;
            }


        }
        return () => { load = true }
    }, [])
    const handleClose = () => {
        setShowEditLeads(false);
    }
    const { values, errors, handleBlur, handleChange, handleSubmit, touched, setFieldValue } = useFormik({
        initialValues: pathname == GOOGLE_SHEET ? {
            gsheet: "",
            gaccount: "",
            gtable: ""
        } : pathname == ACTIVE_CAMPAIGN ? {
            acName: "",

        } : ""
        ,
        validationSchema: pathname == GOOGLE_SHEET ? yup.object({
            gtable: yup.object().required("Please select table name"),
            gaccount: yup.object().required("Please select account"),
            gsheet: yup.object().required("please select sheet"),
        }) : pathname == ACTIVE_CAMPAIGN ? yup.object({
            acName: yup.object().required("Please select account"),
        }) : "",

        onSubmit: (values, { resetForm }) => {
            console.log(values)
            const formdata = new FormData();
            switch (pathname) {
                case GOOGLE_SHEET:
                    formdata.append('tb_name', values.gtable.value);
                    formdata.append('r_token', values.gaccount.value);
                    formdata.append('google_sheet_id', values.gsheet.value);
                    break;
                case ACTIVE_CAMPAIGN:
                    formdata.append('api_url', values.acName.value.url);
                    formdata.append('api_key', values.acName.value.key);
                    break;
                default:
                    break;
            }
            switch (pathname) {
                case GOOGLE_SHEET:
                    dispatch(addGoogleSheetData(formdata, setLoading, setShowEditLeads));
                    break;
                case ACTIVE_CAMPAIGN:
                    dispatch(addActiveCampaignData(formdata, setLoading, setShowEditLeads));
                    break;
                default:
                    break;
            }
            setLoading(true);
            resetForm({ values: '' });
        }
    })
    const googleUserList = useSelector((state) => state.AdminReducer.googleUserList);
    const googleSheetList = useSelector((state) => state.AdminReducer.googleSheetList);
    const activeCampaignList = useSelector((state) => state.AdminReducer.activeCampaignList);

    const googleUserListOpt = googleUserList && googleUserList.map((i, index) => {
        return { value: i.access_token, label: i.name }
    })
    const googleSheetListOpt = googleSheetList && googleSheetList.map((i, index) => {
        return { value: i.id, label: i.name }
    })
    const activeCampaignListOpt = activeCampaignList && activeCampaignList.map((i, index) => {
        return { value: { "url": i.api_url, "key": i.api_key }, label: i.name }
    })
    return (
        <>
            <div className='admin-model'>
                <Modal
                    size={"md"}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={showEditLeads}
                    backdrop="static"
                    keyboard={false}
                    className='fade'
                >
                    <div className="modal-content border-0">
                        <div className="modal-header p-3 bg-soft-orange">
                            <h5 className="modal-title text-capitalize" id="createTaskLabel">Export data</h5>
                            <InputButton type="button" className="btn-close" databsdismiss="modal" event={() => { handleClose() }} />
                        </div>
                        <div className="modal-body">
                            <div className='google-logo-div'>{heading == "Google Sheet" ? <img src='/assets/images/google-sheets-blog-banner.png' className='google-sheet-logo'></img> : <img src='/assets/images/active-campaign.png' className='google-sheet-logo'></img>}</div>
                       
                            <form onSubmit={handleSubmit}>
                                {heading == 'Google Sheet' ?
                                    <><div className="mb-3">
                                        <InputLabel className="form-label" name="Select table name" />
                                        <InputSelectBox
                                            name="gtable"
                                            className="form-select py-0 ps-0 select-control "
                                            setFieldValue={setFieldValue}
                                            handleBlur={handleBlur}
                                            value={values.gtable}
                                            error={errors.gtable}
                                            touch={touched.gtable}
                                            opt={options}
                                        />
                                    </div>
                                        <div className="mb-3">
                                            <InputLabel className="form-label" name="Select account" />
                                            <InputSelectBox
                                                name="gaccount"
                                                className="form-select py-0 ps-0 select-control "
                                                setFieldValue={setFieldValue}
                                                handleBlur={handleBlur}
                                                value={values.gaccount}
                                                error={errors.gaccount}
                                                touch={touched.gaccount}
                                                opt={googleUserListOpt}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <InputLabel className="form-label" name="Select Sheet" />
                                            <InputSelectBox
                                                name="gsheet"
                                                className="form-select py-0 ps-0 select-control "
                                                setFieldValue={setFieldValue}
                                                handleBlur={handleBlur}
                                                value={values.gsheet}
                                                error={errors.gsheet}
                                                touch={touched.gsheet}
                                                opt={googleSheetListOpt}
                                            />
                                        </div>
                                    </> : heading == "Active Campaign" ? <>
                                        <div className="mb-3">
                                            <InputLabel className="form-label" name="Select account name" />
                                            <InputSelectBox
                                                name="acName"
                                                className="form-select py-0 ps-0 select-control "
                                                setFieldValue={setFieldValue}
                                                handleBlur={handleBlur}
                                                value={values.acName}
                                                error={errors.acName}
                                                touch={touched.acName}
                                                opt={activeCampaignListOpt}
                                            />
                                        </div>
                                    </> : null

                                }

                                <div className="hstack gap-2 justify-content-end">
                                    <InputButton type="button" className="btn btn-ghost-orange" databsdismiss="modal" icon="ri-close-fill align-bottom" name="Close" event={() => { handleClose() }} />
                                    {
                                        loading ? <InputLodingButton name={'Exporting...'} className="btn btn-primary btn-load" databsdismiss="model" /> : <InputButton className="btn btn-primary text-capitalize" type="submit" name={`Export`} />
                                    }
                                </div>
                            </form>

                        </div>
                    </div>

                </Modal>
            </div>
        </>
    )
}

export default GoogleSheetModel