import React from 'react'
import { useFormik } from "formik";
import { AiOutlineCheck } from "react-icons/ai";
import RadioPreview from '../preview functions/RadioPreview';
import { BsArrowRight } from 'react-icons/bs'
import MultiStep from '../../user/step/MultiStep';
import { FiPhoneCall } from 'react-icons/fi';
import { que_col, que_value_col } from '../../user/services/Methods';
import { useEffect } from 'react';
import { useState } from 'react';
import MultiStepLoader from '../../user/step/MultiStepLoader'
import ManualStart from '../../user/forms/car/js/ManualStart';
import Header from '../../user/layout/Header'
import { useContext } from 'react';
import { AdminValAuthContext } from '../context/AdminAuthProvider';

const AdminPreviewForm = ({ dcolor, bcolor, fcolor }) => {
    const styles = { width: 'fit-content' }
    const val=useContext(AdminValAuthContext)

    const { values, errors, handleBlur, handleChange, handleSubmit, touched } = useFormik({
        initialValues: {
            shopping: "I think I’m paying too much",
            rent_home: "I own my condo",
            auto_home: "",
            car_insurance: "Yes",
        }
    });

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 992px)").matches
    )

    useEffect(() => {
        window
            .matchMedia("(min-width: 992px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, []);

    console.log(val?.color2,val?.bgColor,'-------------------color2-bgcolr')
    
    return (
        <>
          
            <div style={{ backgroundColor:  val?.color2?.hex ? val?.color2?.hex:val?.bgColor, minHeight: '100vh', height: '100%' }}>
                <Header bcolor={bcolor}/>
                <div className="container-fluid container-content" style={{ paddingBottom: '20px' }}>
                    {/* <div className="row">
          {(pathname == RESULT || pathname == THANKYOU) ? null : <div className="col-6 col-lg-2 d-lg-block d-none" style={{ marginTop: '100px' }}><MultiStep /></div>}
          <div className={`col-12 pt-3 ${pathname == THANKYOU ? 'col-lg-12' : 'col-lg-10'}`}>
            {pages.map((val, index) => <span key={index} className="user_form">{(pathname == val.path ? val.comp : null)}</span>)}
          </div>
        </div> */}
                    <div className="row">
                        {<div className="col-12 d-lg-flex d-none justify-content-center align-items-center" style={{ marginTop: '60px' }}>
                            <MultiStepLoader />
                        </div>}
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <div className={`gy-5 pt-5 col-lg-7`}>
                            <span className="user_form"><ManualStart/></span>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default React.memo(AdminPreviewForm)
