import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { curdData, DELETE_ACTIVE_CAMPAIGN, DELETE_BRAND, DELETE_GOOGLE_SHEET_Ac, DELETE_INSURANCE, DELETE_INSUR_COMP, DELETE_MODEL, DELETE_TRIM_NAME, DELETE_VEHICLE, getDataList,GET_ACTIVE_CAMPAIGN_LIST, GET_BRANDS_LIST, GET_GOOGEL_SHEET_USER, GET_INSUR_COMPS_LIST, GET_MODELS_LIST, GET_TRIMNAME_LIST, GET_USER_LIST, GET_VEHICLES_LIST } from '../../redux/action/AdminAction'
import { AdminValAuthContext } from '../context/AdminAuthProvider'
import InputButton from '../Input Function/InputButton'
import InputLodingButton from '../Input Function/InputLodingButton'
import { ACTIVE_CAMPAIGN, ALL_BRANDS, ALL_INSUR_COMP, ALL_LEAD, ALL_MODELS, ALL_TRIMNAME, ALL_VEHICLE, GOOGLE_SHEET } from '../services/AdminRoutePath'

const DeleteModel = ({ setShow, loading, setLoading, deleteId, setAction, setCurrentPage, currentPage }) => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const val = useContext(AdminValAuthContext)
    const handleDelete = (id) => {
        switch (pathname) {
            case ALL_BRANDS:
                setLoading(true);
                dispatch(curdData('',setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage,getDataList,currentPage,"Brand deleted sucessfully!",DELETE_BRAND,`admin/delete-brand/${id}`,setCurrentPage,`admin/get-brands-list/?page=${currentPage}`,GET_BRANDS_LIST));
                break;
            case ALL_INSUR_COMP:
                setLoading(true);
                dispatch(curdData('',setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage,getDataList,currentPage,"Insurance company deleted sucessfully!",DELETE_INSUR_COMP,`admin/delete-insur-comp/${id}`,setCurrentPage,`admin/get-insur-comp-list/?page=${currentPage}`,GET_INSUR_COMPS_LIST));
                break;
            case ALL_MODELS:
                setLoading(true);
                dispatch(curdData('',setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage,getDataList,currentPage,"Model deleted sucessfully!",DELETE_MODEL,`admin/delete-model/${id}`,setCurrentPage,`admin/get-models-list/?page=${currentPage}`,GET_MODELS_LIST));
                break;
            case ALL_TRIMNAME:
                setLoading(true);
                dispatch(curdData('',setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage,getDataList,currentPage,"Trim name deleted sucessfully!",DELETE_TRIM_NAME,`admin/delete-trimname/${id}`,setCurrentPage,`admin/get-trimNames-list/?page=${currentPage}`,GET_TRIMNAME_LIST));
                break;
            case ALL_VEHICLE:
                setLoading(true);
                dispatch(curdData('',setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage,getDataList,currentPage,"Vehicle deleted sucessfully!",DELETE_VEHICLE,`admin/delete-vehicle/${id}`,setCurrentPage,`admin/get-vehicles-list/?page=${currentPage}`,GET_VEHICLES_LIST));
                break;
            case ALL_LEAD:
                setLoading(true);
                dispatch(curdData('',setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage,getDataList,currentPage,"Leads deleted sucessfully!",DELETE_INSURANCE,`admin/delete-insurance-detail/${id}`,setCurrentPage,`admin/get-user-detail/?page=${currentPage}`,GET_USER_LIST));
                break;
            case GOOGLE_SHEET:
                setLoading(true);
                dispatch(curdData('',setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage,getDataList,currentPage,"Account deleted sucessfully!",DELETE_GOOGLE_SHEET_Ac,`admin/delete-google-sheet/${id}`,setCurrentPage,`admin/get-google-sheet-users/?page=${currentPage}`,GET_GOOGEL_SHEET_USER));
                break;
            case ACTIVE_CAMPAIGN:
                setLoading(true);
                dispatch(curdData('',setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim,val?.setHideSelectFromPage,getDataList,currentPage,"Account deleted sucessfully!",DELETE_ACTIVE_CAMPAIGN,`admin/delete-active-campaign/${id}`,setCurrentPage,`admin/get-active-campaign-users/?page=${currentPage}`,GET_ACTIVE_CAMPAIGN_LIST));
                break;
            default:
                break;
        }
    }
    const handleClose = () => {
        setShow(false);
        setAction('');
        val?.setHideSelectModel(true);
        val?.setHideSelectTrim(true);
        val?.setHideSelectFromPage(true);
    }
    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <InputButton type="button" className="btn-close" databsdismiss="modal" event={() => { handleClose() }} />
                </div>
                <div className="modal-body p-3">
                    <div className="text-center">
                        <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#f7b84b,secondary:#f06548" style={{ width: 100, height: 100 }} />
                        <div className="mt-2 pt-1 fs-15 mx-4 mx-sm-5">
                            <h4>Are you sure ?</h4>
                            <p className="text-muted mx-4 mb-0">Are you sure you want to remove this record ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-3 mb-1">
                        <InputButton type="button" className="btn w-sm btn-light" databsdismiss="modal" name="Close" event={() => { handleClose() }} />
                        {
                            loading ? <InputLodingButton name="Deleting..." className="btn w-sm btn-danger btn-load" databsdismiss="model" /> : <InputButton className="btn w-sm btn-danger" type="button" name="Yes, Delete It!" event={() => { handleDelete(deleteId) }} />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteModel