export const options = [{ value: 'AK', label: 'AK' }, { value: 'AL', label: 'AL' }, { value: 'AR', label: 'AR' }, { value: 'AZ', label: 'AZ' }, { value: 'CA', label: 'CA' }, { value: 'CO', label: 'CO' }, { value: 'CT', label: 'CT' }, { value: 'DC', label: 'DC' }, { value: 'DE', label: 'DE' }, { value: 'FL', label: 'FL' }, { value: 'GA', label: 'GA' }, { value: 'HI', label: 'HI' }, { value: 'IA', label: 'IA' }, { value: 'ID', label: 'ID' }, { value: 'IL', label: 'IL' }, { value: 'IN', label: 'IN' }, { value: 'KS', label: 'KS' }, { value: 'KY', label: 'KY' }, { value: 'LA', label: 'LA' }, { value: 'MA', label: 'MA' }, { value: 'MD', label: 'MD' }, { value: 'ME', label: 'ME' }, { value: 'MI', label: 'MI' }, { value: 'MN', label: 'MN' }, { value: 'MO', label: 'MO' }, { value: 'MS', label: 'MS' }, { value: 'MT', label: 'MT' }, { value: 'NC', label: 'NC' }, { value: 'ND', label: 'ND' }, { value: 'NE', label: 'NE' }, { value: 'NH', label: 'NH' }, { value: 'NJ', label: 'NJ' }, { value: 'NM', label: 'NM' }, { value: 'NV', label: 'NV' }, { value: 'NY', label: 'NY' }, { value: 'OH', label: 'OH' }, { value: 'OK', label: 'OK' }, { value: 'OR', label: 'OR' }, { value: 'PA', label: 'PA' }, { value: 'RI', label: 'RI' }, { value: 'SC', label: 'SC' }, { value: 'SD', label: 'SD' }, { value: 'TN', label: 'TN' }, { value: 'TX', label: 'TX' }, { value: 'UT', label: 'UT' }, { value: 'VA', label: 'VA' }, { value: 'VT', label: 'VT' }, { value: 'WA', label: 'WA' }, { value: 'WI', label: 'WI' }, { value: 'WV', label: 'WV' }, { value: 'WY', label: 'WY' }
]

export const foundation_types = [
  { value: 'Slab', label: 'Slab' },
  { value: 'Crawl Space', label: 'Crawl Space' },
  { value: 'Basement', label: 'Basement' },
  { value: 'Pier and beam / pilling', label: 'Pier and beam / pilling' },
  { value: 'other', label: 'other' }
]
export const frequency = [{ value: 'Per day', label: 'Per day' }, { value: 'Per week', label: 'Per week' }, { value: 'Per month', label: 'Per month' },]
export const vehicleCoverageOpt = [
  { text: 'Collision (Deductible)', cost: '$1k ' },
  { text: 'Comprehensive (Deductible)', cost: '$1k' },
  { text: 'Roadside Assistance', cost: '$75' },
  { text: 'Rental Reimbursement', cost: '$30/$900' },
]
export const policyCoverageOpt = [
  { text: 'Bodily Injury Liability', cost: '$50k/$100k' },
  { text: 'Property Damage Liability', cost: '$50k ' },
  { text: 'Uninsured Motorist Bodily', cost: '$50k/$100k' },
  { text: 'Uninsured Motorist Property', cost: 'Not covered' },
  { text: 'Personal Injury Protection (PIP)', cost: '$50k' },
]

export const listOpt = [{ text: 'Get Multiple Quotes In Minutes.' }, { text: 'Compare 40+ Top Insurers.' }, { text: 'Put Your Policy To The Test.' }, { text: 'Using Experian® To Shop For New Car Insurance Could Save You $961 Per Year On Average.' }, { text: 'Get Car Insurance Quotes From Top Providers, All In One Place, In Just A Few Minutes.' }]

export const life_event_options = [
  { value: "I got married", label: "I got married" },
  { value: "I no longer have a spouse", label: "I no longer have a spouse" },
  { value: "I added a teen driver", label: "I added a teen driver" },
  {
    value: "I'm off my parent's policy",
    label: "I'm off my parent's policy",
  },
  { value: "I'm buying a house", label: "I'm buying a house" },
  {
    value: "I moved , or am moving soon",
    label: "I moved , or am moving soon",
  },
];

export const color = '#315c62'
export const requiredFeild = 'This field is required!'
export const backend_url = 'https://admin.insuringthefuture.com:4000/'

export const que_col = 'col-xl-5' // col-lg-4
export const que_value_col = 'col-xl-6 offset-xl-1' // col-lg-6
export const btn_col='col-xl-12' // col-lg-10


export const showError = (error, submitting) => {
  if (submitting) return error
}


