import React, { useEffect } from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router'
import InputButton from '../Input Function/InputButton'
import AdminStater from '../layouts/AdminStater'
import AddModel from '../models/AddModel'
import { ALL_BRANDS, ALL_INSUR_COMP, ALL_LEAD, ALL_MODELS, ALL_TRIMNAME, ALL_VEHICLE, FORM_QUESTION, FORM_SUB_QUESTION, FORM_SUB_VALUE, FORM_VALUE, GOOGLE_SHEET } from '../services/AdminRoutePath'
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux'
import { addGoogleSheetAccount, getBrands, getBrandsList, getDataList, getGoogleSheetAccount, getInsurCompsList, getModels, getModelsList, getTrimName, getTrimNameList, getUserList, getVehicles, getVehiclesList, GET_BRANDS_LIST, GET_FORM_QUESTION, GET_FORM_SUB_QUESTION, GET_FORM_SUB_VALUE, GET_FORM_VALUE, GET_GOOGEL_SHEET_USER, GET_INSUR_COMPS_LIST, GET_MODELS_LIST, GET_TRIMNAME_LIST, GET_USER_LIST, GET_VEHICLES_LIST, curdData, EDIT_ENGINE_NAME, BULK_EDIT_TRIM } from '../../redux/action/AdminAction'
import Pagination from "react-js-pagination";
import moment from 'moment'
import CsvLink from 'react-csv-export';
import "../css/GoogleSheet.css"
import { GoogleButton } from "react-google-oauth2";
import { backend_url } from '../../user/services/Methods'
import BackDrop from '../Input Function/BackDrop'
import { useContext } from 'react'
import { AdminValAuthContext } from '../context/AdminAuthProvider'
import { Spinner } from 'react-bootstrap'
import EditLeadsModel from '../models/EditLeadsModel'
import ReactGoogleAutocomplete from 'react-google-autocomplete'
import { GOOGLE_API_KEY } from '../../user/services/UserRoutes'
import swal from 'sweetalert';
import { id } from 'date-fns/locale'
import InputLodingButton from '../Input Function/InputLodingButton'
// require("bootstrap/less/bootstrap.less");

const CurdPage = ({ path }) => {
    const [heading, setHeading] = useState();
    const [action, setAction] = useState();
    const [tbhead, setTBHead] = useState([]);
    const [editItem, setEditItem] = useState();
    const [showEditLeads, setShowEditLeads] = useState();
    const [viewItem, setViewItem] = useState();
    const [deleteId, setdeleteId] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const val = useContext(AdminValAuthContext);
    const [title, setTitle] = useState();
    const [editLeads, setEditLeads] = useState();

    const dispatch = useDispatch();
    const options = {
        clientId: "709309640515-cirsp22jmevprcnii8bp4lpjgo51i612.apps.googleusercontent.com",
        redirectUri: `${backend_url}auth/google/callback`,
        scopes: ['https://www.googleapis.com/auth/userinfo.profile',
            'https://www.googleapis.com/auth/userinfo.email', 'https://www.googleapis.com/auth/spreadsheets', 'https://www.googleapis.com/auth/drive.file', 'https://www.googleapis.com/auth/spreadsheets.readonly', 'https://www.googleapis.com/auth/drive.readonly'],

        // scopes: ["openid", "profile", "email"],
        includeGrantedScopes: true,
        accessType: "offline",
        responseType: "code",
        prompt: "consent",
        // state: from,
    };
    let load = false;
    useEffect(() => {
        if (!load) {
            switch (path) {
                case ALL_BRANDS:
                    setHeading("Brand")
                    setTBHead([{ key: "ID", width: "10%" }, { key: "Name", width: "" }, { key: "Image", width: "" }, { key: "Action", width: "12%" }])
                    break
                case ALL_INSUR_COMP:
                    setHeading("Company")
                    setTBHead([{ key: "ID", width: "10%" }, { key: "Name", width: "" }, { key: "Image", width: "" }, { key: "Action", width: "12%" }])
                    break
                case ALL_MODELS:
                    setHeading("Model")
                    setTBHead([{ key: "ID", width: "10%" }, { key: "Brand Name", width: "" }, { key: "Model Name", width: "" }, { key: "Action", width: "12%" }])
                    break
                case ALL_TRIMNAME:
                    setHeading("Trim Name")
                    setTBHead([{ key: "ID", width: "10%" }, { key: "Trim Name", width: "", edit: "Bulk edit" }, { key: "Brand name", width: "" }, { key: "Model Name", width: "" }, { key: "Action", width: "12%" }])
                    break
                case ALL_VEHICLE:
                    setHeading("Vehicle")
                    setTBHead([{ key: "ID", width: "10%" }, { key: "Brand Name", width: "" }, { key: "Model Name", width: "" }, { key: "Trim Name", width: "" }, { key: "Year", width: "" }, { key: "Engine Name", width: "", edit: "Bulk edit" }, { key: "Engine code", width: "" }, { key: "Transmission Code", width: "" }, { key: "Action", width: "12%" }])
                    break
                case ALL_LEAD:
                    setHeading("Lead")
                    setTBHead([{ key: "ID", width: "5%" }, { key: "First Name", width: "" }, { key: "Last Name", width: "" }, { key: "Birthdate", width: "" }, { key: "Email", width: "" }, { key: "Date", width: "" },{ key: "Action", width: "12%" }])
                    break
                case GOOGLE_SHEET:
                    setHeading("Google Sheet")
                    setTBHead([{ key: "ID", width: "10%" }, { key: "Name", width: "" }, { key: "Access Token", width: "" }, { key: "Refresh Token", width: "" }, { key: "Action", width: "12%" }])
                    break
                case FORM_QUESTION:
                    setHeading("Question")
                    setTBHead([{ key: "ID", width: "8%" }, { key: "Title", width: "" }, { key: "Description", width: "" }, { key: "Information", width: "" }, { key: "Page Title", width: "" }, { key: "Action", width: "5%" }])
                    break
                case FORM_VALUE:
                    setHeading("Value")
                    setTBHead([{ key: "ID", width: "10%" }, { key: "Question", width: "" }, { key: "Value", width: "" }, { key: "Type", width: "" }, { key: "Page Title", width: "" }, { key: "Action", width: "12%" }])
                    break
                case FORM_SUB_QUESTION:
                    setHeading("Sub Question")
                    setTBHead([{ key: "ID", width: "10%" }, { key: "Question", width: "" }, { key: "Value", width: "" }, { key: "Title", width: "" }, { key: "Description", width: "" }, { key: "Information", width: "" }, { key: "Page Title", width: "" }, { key: "Action", width: "12%" }])
                    break
                case FORM_SUB_VALUE:
                    setHeading("Sub Value")
                    setTBHead([{ key: "ID", width: "10%" }, { key: "Question", width: "" }, { key: "Value", width: "" }, { key: "Sub Question", width: "" }, { key: "Title", width: "" }, { key: "Description", width: "" }, { key: "Type", width: "" }, { key: "Page Title", width: "" }, { key: "Action", width: "12%" }])
                    break
                default:
                    break
            }
        }
        return () => { load = true }
    }, [])
    const [show, setShow] = useState(false);
    const handleAdd = () => {
        setAction("add");
        setShow(true)
    }
    const handleEdit = (item) => {
        setAction("edit");
        setShow(true)
        setEditItem(item)

    }
    const handleView = (item) => {
        setAction("view");
        setShow(true)
        setViewItem(item)

    }
    const handleDelete = (id) => {
        setAction("delete");
        setShow(true)
        setdeleteId(id)
    }
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const handleEditLeads = (item) => {
        setShowEditLeads(true)
        setTitle('user')
        setEditLeads(item);
    }
    const brandPerPageList = useSelector((state) => state.AdminReducer.brandPerPageList);
    const brand_path = useSelector((state) => state.AdminReducer.brand_path);
    const brandTotalItemCount = useSelector((state) => state.AdminReducer.brandTotalItemCount);

    const insurCompPerPageList = useSelector((state) => state.AdminReducer.insurCompPerPageList);
    const insur_comp_path = useSelector((state) => state.AdminReducer.insur_comp_path);
    const insurCompTotalItemCount = useSelector((state) => state.AdminReducer.insurCompTotalItemCount);

    const modelPerPageList = useSelector((state) => state.AdminReducer.modelPerPageList);
    const modelTotalItemCount = useSelector((state) => state.AdminReducer.modelTotalItemCount);

    const vehiclePerPageList = useSelector((state) => state.AdminReducer.vehiclePerPageList);
    const vehicleTotalItemCount = useSelector((state) => state.AdminReducer.vehicleTotalItemCount);

    const trimNamePerPageList = useSelector((state) => state.AdminReducer.trimNamePerPageList);
    const trimNameTotalItemCount = useSelector((state) => state.AdminReducer.trimNameTotalItemCount);

    const userPerPageList = useSelector((state) => state.AdminReducer.userPerPageList);
    const userItemCount = useSelector((state) => state.AdminReducer.userItemCount);

    const googleSheetAc = useSelector((state) => state.AdminReducer.googleSheetAc);
    const googleSheetUserItemCount = useSelector((state) => state.AdminReducer.googleSheetUserItemCount);

    const formQueList = useSelector((state) => state.AdminReducer.formQueList);
    const formQueTotalItemCount = useSelector((state) => state.AdminReducer.formQueTotalItemCount);

    const formValList = useSelector((state) => state.AdminReducer.formValList);
    const formValTotalItemCount = useSelector((state) => state.AdminReducer.formValTotalItemCount);

    const formSQueList = useSelector((state) => state.AdminReducer.formSQueList);
    const formSQueTotalItemCount = useSelector((state) => state.AdminReducer.formSQueTotalItemCount);

    const formSValList = useSelector((state) => state.AdminReducer.formSValList);
    const formSValTotalItemCount = useSelector((state) => state.AdminReducer.formSValTotalItemCount);

    const exportUser = userPerPageList?.map((i, index) => {
        return { "Sr.No.": index + 1, "First Name": i.firstname, "Last Name": i.lastname, "Birth Date": moment(i?.birthdate).format('YYYY-MM-DD'), "Unit": i.unit, "Address": i.address, "City": i.city, "State": i.state, "Zipcode": i.zipcode,"Date":i.created_at }
    })
    useEffect(() => {
        switch (path) {
            case ALL_BRANDS:
                setLoading(true);
                dispatch(getDataList(setLoading, `admin/get-brands-list/?page=${currentPage === 0 ? 1 : currentPage}`, GET_BRANDS_LIST));
                break
            case ALL_MODELS:
                setLoading(true);
                dispatch(getDataList(setLoading, `admin/get-models-list/?page=${currentPage === 0 ? 1 : currentPage}`, GET_MODELS_LIST));
                break
            case ALL_TRIMNAME:
                setLoading(true);
                dispatch(getDataList(setLoading, `admin/get-trimNames-list/?page=${currentPage === 0 ? 1 : currentPage}`, GET_TRIMNAME_LIST));
                break
            case ALL_VEHICLE:
                setLoading(true);
                dispatch(getDataList(setLoading, `admin/get-vehicles-list/?page=${currentPage === 0 ? 1 : currentPage}`, GET_VEHICLES_LIST));
                break
            case ALL_LEAD:
                setLoading(true);
                dispatch(getDataList(setLoading, `admin/get-user-detail/?page=${currentPage === 0 ? 1 : currentPage}`, GET_USER_LIST));
                break
            case ALL_INSUR_COMP:
                setLoading(true);
                dispatch(getDataList(setLoading, `admin/get-insur-comp-list/?page=${currentPage === 0 ? 1 : currentPage}`, GET_INSUR_COMPS_LIST));
                break
            case GOOGLE_SHEET:
                setLoading(true);
                dispatch(getDataList(setLoading, `admin/get-google-sheet-users/?page=${currentPage === 0 ? 1 : currentPage}`, GET_GOOGEL_SHEET_USER));
                break
            case FORM_QUESTION:
                setLoading(true);
                dispatch(getDataList(setLoading, `admin/get-form-question/?page=${currentPage === 0 ? 1 : currentPage}`, GET_FORM_QUESTION));
                break
            case FORM_VALUE:
                setLoading(true);
                dispatch(getDataList(setLoading, `admin/get-form-value/?page=${currentPage === 0 ? 1 : currentPage}`, GET_FORM_VALUE));
                break
            case FORM_SUB_QUESTION:
                setLoading(true);
                dispatch(getDataList(setLoading, `admin/get-form-sub-question/?page=${currentPage === 0 ? 1 : currentPage}`, GET_FORM_SUB_QUESTION));
                break
            case FORM_SUB_VALUE:
                setLoading(true);
                dispatch(getDataList(setLoading, `admin/get-form-sub-value/?page=${currentPage === 0 ? 1 : currentPage}`, GET_FORM_SUB_VALUE));
                break
            default:
                break
        }

    }, [currentPage]);
    const [bulkEdit, setBulkEdit] = useState(false);
    const [bulkSave, setBulkSave] = useState(false);
    const handleBulkEdit = () => {

        swal({
            icon: "info",
            title: "Are you sure?",
            text: path == ALL_VEHICLE ? "You want to edit engine name in bulk!" : path == ALL_TRIMNAME ? "You want to edit trim name in bulk!" : "",
            type: "success",
            closeOnClickOutside: false,
            buttons: {
                cancel: {
                    text: "Cancel",
                    value: null,
                    visible: true,
                    className: "swal-cancle",
                    closeModal: true,
                },
                confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    className: "swal-ok",
                    closeModal: true
                }
            },

        }).then(okay => {
            if (okay) {
                setBulkEdit(true);
            }
        });
    }
    const handleBulkDelete = () => {
        setBulkEdit(false);
        setEngNm({})
        setBulkArray([])
    }
    const [engNm, setEngNm] = useState({});
    const [bulkArray, setBulkArray] = useState([]);
    const [editLoading, setEditLoading] = useState(false);
    const handleBulkSave = () => {
        setBulkSave(true)
        let obj = Object.keys(engNm)
        obj.map((item) => {
            setBulkArray((bulkArray) => [...bulkArray, { id: item, value: engNm[item] }])
        })
    }
    useEffect(() => {
        if (bulkArray.length > 0 && bulkSave) {
            setEditLoading(true)
            let setLoading = setEditLoading;
            if (path == ALL_VEHICLE) {
                dispatch(curdData(bulkArray, setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim, val?.setHideSelectFromPage, getDataList, currentPage, "Engine name updated sucessfully!", EDIT_ENGINE_NAME, `admin/bulk-edit-engine`, setCurrentPage, `admin/get-vehicles-list/?page=${currentPage}`, GET_VEHICLES_LIST));
            }
            else {
                dispatch(curdData(bulkArray, setLoading, setShow, setAction, val?.setHideSelectModel, val?.setHideSelectTrim, val?.setHideSelectFromPage, getDataList, currentPage, "Trim name updated sucessfully!", BULK_EDIT_TRIM, `admin/bulk-edit-trim`, setCurrentPage, `admin/get-trimNames-list/?page=${currentPage}`, GET_TRIMNAME_LIST));
            }
            setTimeout(() => {
                setBulkEdit(false)
                setEngNm({})
                setBulkSave(false)
                setBulkArray([]);
            }, 1500)

        }
    }, [bulkSave == true])
    useEffect(() => { setBulkArray([...new Map(bulkArray.map((item) => [item?.id, item])).values()]) }, [bulkEdit])
    return (
        <>

            {show ? <AddModel heading={heading} setHeading={setHeading} action={action} setAction={setAction} show={show} setShow={setShow} editItem={editItem} setEditItem={setEditItem} deleteId={deleteId} setdeleteId={setdeleteId} currentPage={currentPage} setCurrentPage={setCurrentPage} viewItem={viewItem} setViewItem={setViewItem} /> : null}
            {showEditLeads ? <EditLeadsModel title={title} setTitle={setTitle} showEditLeads={showEditLeads} setShowEditLeads={setShowEditLeads} editLeads={editLeads} setEditLeads={setEditLeads} currentPage={currentPage} setCurrentPage={setCurrentPage} /> : null}
            <div className={`container-fluid `}>
                <AdminStater />
                <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
                    <div className="file-manager-content w-100 p-3 pb-0">

                        <div className="rounded mb-3">
                            <div className="row g-2 justify-content-end">
                                <div className="col-lg-auto ">

                                    {path == ALL_LEAD ? <CsvLink data={exportUser} fileName="all_leads" withTimeStamp>
                                        <InputButton className="btn btn-primary createTask" name={`Export`} icon="las la-arrow-down" />
                                    </CsvLink> : path == GOOGLE_SHEET ? <GoogleButton
                                        options={options}
                                        apiUrl={`${backend_url}api/auth/google`}
                                        className="btn btn-primary createTask"
                                    > + Add Account</GoogleButton> : (path == FORM_QUESTION || path == FORM_VALUE || path == FORM_SUB_QUESTION || path == FORM_SUB_VALUE) ? <div className='' /> : <InputButton className="btn btn-primary createTask" type="button" databstoggle="modal" databstarget="#createTask" icon="ri-add-fill align-bottom" name={`Add ${heading}`} event={() => { handleAdd() }} />}
                                </div>
                            </div>
                        </div>
                        <div className={`todo-content position-relative px-4 mx-n4 ${(path == FORM_QUESTION || path == FORM_VALUE || path == FORM_SUB_QUESTION || path == FORM_SUB_VALUE) ? 'todo-height' : ''}`} id="todo-content">
                            <div className="todo-task" id="todo-task">
                                <div className="table-responsive table-card mb-1 p-3">
                                    <table className="table align-middle table-nowrap" id="customerTable">
                                        <thead className="table-orange">
                                            <tr>
                                                {tbhead.map((t, index) => {
                                                    return <th className="" key={index} style={{ width: t?.width, fontSize: '17px' }}>{t?.key}{t?.edit && !bulkEdit && !loading ? <InputButton className={"btn btn-primary py-0 px-1 ms-1 fs-6"} icon={"ri-edit-line"} event={handleBulkEdit} /> : t?.edit && bulkEdit ? <>{Object.keys(engNm).length != 0 ? editLoading ? <InputLodingButton className="btn btn-info py-0 px-1 ms-1 fs-6 btn-load" /> : <InputButton className={"btn btn-info py-0 px-1 ms-1 fs-6"} event={handleBulkSave} name="✓" /> : null}<InputButton className={"btn btn-danger py-0 px-1 ms-1 fs-6"} event={handleBulkDelete} name="✗" /></> : null}</th>
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody className="list form-check-all" >
                                            {heading == "Brand" ?
                                                loading ?
                                                    <tr className='border-0'><td colSpan={window.innerWidth > 500 ? 4 : 3} className='border-0'>
                                                        <div className='position-relative mt-2 mb-5'><Spinner animation="border" className='loader-top' /></div>
                                                    </td></tr>
                                                    :
                                                    brandPerPageList?.map((b, index) => {
                                                        return <tr key={index} style={{ height: "60px" }}>
                                                            <td>{index + 1}</td>
                                                            <td>{b?.brand_name}</td>
                                                            <td>{b?.image ? <img src={`${brand_path}/${b?.image}`} alt="image" className='' style={{ height: "33px", width: "33px" }} /> : "-"}</td>
                                                            <td>
                                                                <div className="d-flex gap-2 ">
                                                                    <InputButton className="btn btn-sm btn-success edit-item-btn" name="Edit" event={() => { handleEdit(b) }}></InputButton>
                                                                    <InputButton className="btn btn-sm btn-danger remove-item-btn" name="Remove" event={() => { handleDelete(b?.id) }}></InputButton>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    })
                                                : heading == "Model" ?
                                                    loading ? <tr className='border-0'><td colSpan={window.innerWidth > 500 ? 4 : 3} className='border-0'><div className='position-relative mt-2 mb-5'><Spinner animation="border" className='loader-top' /></div></td></tr> :
                                                        modelPerPageList?.map((b, index) => {
                                                            return <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{b?.brand_name}</td>
                                                                <td>{b?.model_name}</td>
                                                                <td>
                                                                    <div className="d-flex gap-2 ">
                                                                        <InputButton className="btn btn-sm btn-success edit-item-btn" name="Edit" event={() => { handleEdit(b) }}></InputButton>
                                                                        <InputButton className="btn btn-sm btn-danger remove-item-btn" name="Remove" event={() => { handleDelete(b?.id) }}></InputButton>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        })
                                                    : heading == "Trim Name" ?
                                                        loading ? <tr className='border-0'><td colSpan={window.innerWidth > 500 ? 5 : 4} className='border-0'><div className='position-relative mt-2 mb-5'><Spinner animation="border" className='loader-top' /></div></td></tr> :
                                                            trimNamePerPageList?.map((b, index) => {
                                                                return <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{!bulkEdit ? b?.name : <input type='text' className='form-control' defaultValue={b?.name} onChange={(e) => { setEngNm((engNm) => { return { ...engNm, [b?.id]: e.target.value } }) }} />}</td>
                                                                    {/* <td>{b?.name}</td> */}
                                                                    <td>{b?.brand_name}</td>
                                                                    <td>{b?.model_name}</td>
                                                                    <td>
                                                                        <div className="d-flex gap-2 ">
                                                                            <InputButton className="btn btn-sm btn-success edit-item-btn" name="Edit" event={() => { handleEdit(b) }}></InputButton>
                                                                            <InputButton className="btn btn-sm btn-danger remove-item-btn" name="Remove" event={() => { handleDelete(b?.id) }}></InputButton>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            })
                                                        : heading == "Vehicle" ?
                                                            loading ? <tr className='border-0'><td colSpan={window.innerWidth > 500 ? 9 : 4} className='border-0'><div className='position-relative mt-2 mb-5'><Spinner animation="border" className='loader-top' /></div></td></tr> :
                                                                vehiclePerPageList?.map((b, index) => {
                                                                    return <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{b?.brand_name}</td>
                                                                        <td>{b?.model_name}</td>
                                                                        <td>{b?.name}</td>
                                                                        <td>{b?.year}</td>
                                                                        <td>{!bulkEdit ? b?.engine_name : <input type='text' className='form-control' defaultValue={b?.engine_name} onChange={(e) => { setEngNm((engNm) => { return { ...engNm, [b?.id]: e.target.value } }) }} />}</td>
                                                                        <td>{b?.engine_code}</td>
                                                                        <td>{b?.transmission_code}</td>
                                                                        <td>
                                                                            <div className="d-flex gap-2 ">
                                                                                <InputButton className="btn btn-sm btn-success edit-item-btn" name="Edit" event={() => { handleEdit(b) }}></InputButton>
                                                                                <InputButton className="btn btn-sm btn-danger remove-item-btn" name="Remove" event={() => { handleDelete(b?.id) }}></InputButton>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }) : heading == "Lead" ?
                                                                loading ? <tr className='border-0'><td colSpan={window.innerWidth > 500 ? 11 : 4} className='border-0'><div className='position-relative mt-2 mb-5'><Spinner animation="border" className='loader-top' /></div></td></tr> :
                                                                    userPerPageList?.map((b, index) => {
                                                                        return <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{b?.firstname}</td>
                                                                            <td>{b?.lastname}</td>
                                                                            <td>{moment(b?.birthdate).format("MM/DD/YYYY")}</td>
                                                                            <td>{b?.email}</td>
                                                                            <td>{moment(b?.created_at).format("MM/DD/YYYY HH:mm:ss")}</td>                                                                      
                                                                            <td>
                                                                                <div className="d-flex gap-2 ">
                                                                                    <InputButton className="btn btn-sm btn-success edit-item-btn" name="Edit" event={() => { handleEditLeads(b) }}></InputButton>
                                                                                    <InputButton className="btn btn-sm btn-primary edit-item-btn" name="View" event={() => { handleView(b) }}></InputButton>
                                                                                    <InputButton className="btn btn-sm btn-danger remove-item-btn" name="Remove" event={() => { handleDelete(b?.id) }}></InputButton>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    }) : heading == "Company" ?
                                                                    loading ? <tr className='border-0'><td colSpan={window.innerWidth > 500 ? 4 : 3} className='border-0'><div className='position-relative mt-2 mb-5'><Spinner animation="border" className='loader-top' /></div></td></tr> :
                                                                        insurCompPerPageList?.map((b, index) => {
                                                                            return <tr key={index} style={{ height: "60px" }}>
                                                                                <td>{index + 1}</td>
                                                                                <td>{b?.company_name}</td>
                                                                                <td>{b?.image ? <img src={`${insur_comp_path}/${b?.image}`} alt="image" className='' style={{ height: "33px", width: "33px" }} /> : "-"}</td>
                                                                                <td>
                                                                                    <div className="d-flex gap-2 ">
                                                                                        <InputButton className="btn btn-sm btn-success edit-item-btn" name="Edit" event={() => { handleEdit(b) }}></InputButton>
                                                                                        <InputButton className="btn btn-sm btn-danger remove-item-btn" name="Remove" event={() => { handleDelete(b?.id) }}></InputButton>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        }) : heading == "Google Sheet" ?
                                                                        loading ? <tr className='border-0'><td colSpan={window.innerWidth > 500 ? 5 : 3} className='border-0'><div className='position-relative mt-2 mb-5'><Spinner animation="border" className='loader-top' /></div></td></tr> :
                                                                            googleSheetAc?.map((b, index) => {
                                                                                return <tr key={index}>
                                                                                    <td>{index + 1}</td>
                                                                                    <td>{b?.name}</td>
                                                                                    <td><div className='token-text'>{b?.access_token}</div></td>
                                                                                    <td><div className='token-text'>{b?.refresh_token}</div></td>
                                                                                    <td>
                                                                                        <div className="d-flex gap-2 ">
                                                                                            <InputButton className="btn btn-sm btn-danger remove-item-btn" name="Remove" event={() => { handleDelete(b?.id) }}></InputButton>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            }) : heading == "Question" ?
                                                                            loading ? <tr className='border-0'><td colSpan={window.innerWidth > 500 ? 5 : 3} className='border-0'><div className='position-relative mt-2 mb-5'><Spinner animation="border" className='loader-top' /></div></td></tr> :
                                                                                formQueList?.map((b, index) => {
                                                                                    return <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td><div className='que-text'>{b?.q_title}</div></td>
                                                                                        <td><div className='que-text'>{b?.q_desc ? b?.q_desc : "-"}</div></td>
                                                                                        <td><div className='que-text'>{b?.q_info ? b?.q_info : "-"}</div></td>
                                                                                        <td><div className='que-text'>{b?.page_title ? b?.page_title : "-"}</div></td>
                                                                                        <td>
                                                                                            <div className="d-flex gap-2 ">
                                                                                                <InputButton className="btn btn-sm btn-success remove-item-btn" name="Edit" event={() => { handleEdit(b) }}></InputButton>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                }) : heading == "Value" ?
                                                                                loading ? <tr className='border-0'><td colSpan={window.innerWidth > 500 ? 5 : 3} className='border-0'><div className='position-relative mt-2 mb-5'><Spinner animation="border" className='loader-top' /></div></td></tr> :
                                                                                    formValList?.map((b, index) => {
                                                                                        return <tr key={index}>
                                                                                            <td>{index + 1}</td>
                                                                                            <td>{b?.q_title}</td>
                                                                                            <td>{b?.q_value}</td>
                                                                                            <td>{b?.qv_type}</td>
                                                                                            <td><div className='que-text'>{b?.page_title ? b?.page_title : "-"}</div></td>
                                                                                            <td>
                                                                                                <div className="d-flex gap-2 ">
                                                                                                    <InputButton className="btn btn-sm btn-success remove-item-btn" name="Edit" event={() => { handleEdit(b) }}></InputButton>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    }) : heading == "Sub Question" ?
                                                                                    loading ? <tr className='border-0'><td colSpan={window.innerWidth > 500 ? 7 : 4} className='border-0'><div className='position-relative mt-2 mb-5'><Spinner animation="border" className='loader-top' /></div></td></tr> :
                                                                                        formSQueList?.map((b, index) => {
                                                                                            return <tr key={index}>
                                                                                                <td>{index + 1}</td>
                                                                                                <td><div className='sque-text'>{b?.q_title}</div></td>
                                                                                                <td><div className='sque-text'>{b?.q_value}</div></td>
                                                                                                <td><div className='sque-text'>{b?.s_title}</div></td>
                                                                                                <td><div className='sque-text'>{b?.s_desc ? b?.s_desc : "-"}</div></td>
                                                                                                <td><div className='sque-text'>{b?.s_info ? b?.s_info : "-"}</div></td>
                                                                                                <td><div className='sque-text'>{b?.page_title ? b?.page_title : "-"}</div></td>
                                                                                                <td>
                                                                                                    <div className="d-flex gap-2 ">
                                                                                                        <InputButton className="btn btn-sm btn-success remove-item-btn" name="Edit" event={() => { handleEdit(b) }}></InputButton>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        }) : heading == "Sub Value" ?
                                                                                        loading ? <tr className='border-0'><td colSpan={window.innerWidth > 500 ? 8 : 3} className='border-0'><div className='position-relative mt-2 mb-5'><Spinner animation="border" className='loader-top' /></div></td></tr> :
                                                                                            formSValList?.map((b, index) => {
                                                                                                return <tr key={index}>
                                                                                                    <td>{index + 1}</td>
                                                                                                    <td><div className='sv-text'>{b?.q_title}</div></td>
                                                                                                    <td><div className='sv-text'>{b?.q_value}</div></td>
                                                                                                    <td><div className='sv-text'>{b?.s_title}</div></td>
                                                                                                    <td><div className='sv-text'>{b?.sv_title}</div></td>
                                                                                                    <td><div className='sv-text'>{b?.sv_desc ? b?.sv_desc : "-"}</div></td>
                                                                                                    <td><div className='sv-text'>{b?.sv_type}</div></td>
                                                                                                    <td><div className='sv-text'>{b?.page_title ? b?.page_title : "-"}</div></td>
                                                                                                    <td>
                                                                                                        <div className="d-flex gap-2 ">
                                                                                                            <InputButton className="btn btn-sm btn-success remove-item-btn" name="Edit" event={() => { handleEdit(b) }}></InputButton>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            }) : null
                                            }

                                        </tbody>
                                    </table>
                                    <div className="justify-content-end mt-2">
                                        <Pagination
                                            activePage={currentPage}
                                            itemsCountPerPage={10}
                                            totalItemsCount={path == ALL_BRANDS ? brandTotalItemCount : path == ALL_INSUR_COMP ? insurCompTotalItemCount : path == ALL_MODELS ? modelTotalItemCount : path == ALL_TRIMNAME ? trimNameTotalItemCount : path == ALL_VEHICLE ? vehicleTotalItemCount : path == ALL_LEAD ? userItemCount : path == GOOGLE_SHEET ? googleSheetUserItemCount : path == FORM_QUESTION ? formQueTotalItemCount : path == FORM_VALUE ? formValTotalItemCount : path == FORM_SUB_QUESTION ? formSQueTotalItemCount : path == FORM_SUB_VALUE ? formSValTotalItemCount : 0}
                                            pageRangeDisplayed={5}
                                            innerClass="pagination listjs-pagination mb-0"
                                            activeClass="page-item-active"
                                            activeLinkClass="page-item-active"
                                            linkClass="page-item"
                                            onChange={(value) => { handlePageChange(value) }}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* 
                </div>

            </div> */}
            <ToastContainer />
        </>
    )
}

export default CurdPage