import React, { useContext, useEffect } from 'react'
import '../css/AdminSidebar.css'
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { ACTIVE_CAMPAIGN, ALL_BRANDS, ALL_INSUR_COMP, ALL_LEAD, ALL_MODELS, ALL_TRIMNAME, ALL_VEHICLE, DASHBOARD, FORM_QUESTION, FORM_SETTING, FORM_SUB_QUESTION, FORM_SUB_VALUE, FORM_VALUE, GOOGLE_SHEET, ZAPIER } from '../services/AdminRoutePath';
import { useState } from 'react';
import { AdminValAuthContext } from '../context/AdminAuthProvider';
import Offcanvas from 'react-bootstrap/Offcanvas';
const AdminSidebar = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => val?.setShowSidebar(true);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const val =useContext(AdminValAuthContext)
  const { pathname } = useLocation();
  const [selectedMenu,setSelectedMenu] = useState('');
  const obj = [
    // { menu: "Dashboard", subMenu: [{ subMenuItem: "" }], path: DASHBOARD, key: 1, icon:"ri-dashboard-2-line"},
    { menu: "Lead Management", subMenu: [{ subMenuItem: "All Lead",path: ALL_LEAD }], path:[ALL_LEAD],key: 5, icon: " bx bx-list-check" },
    { menu: "Brand", subMenu: [{ subMenuItem: "All Brand" ,path: ALL_BRANDS}], path:[ALL_BRANDS], key: 1, icon: "bx bx-hive" },
    { menu: "Model", subMenu: [{ subMenuItem: "All Model",path: ALL_MODELS }], path:[ALL_MODELS],key: 2, icon: "bx bx-qr" },
    { menu: "Trim Name", subMenu: [{ subMenuItem: "All Trim Name",path: ALL_TRIMNAME }], path:[ALL_TRIMNAME], key: 3, icon: "las la-layer-group" },
    { menu: "Vehicle", subMenu: [{ subMenuItem: "All Vehicle" ,path: ALL_VEHICLE}], path:[ALL_VEHICLE],key: 4, icon: "las la-car-side" },
    { menu: "Insurance Company", subMenu: [{ subMenuItem: "All Company",path: ALL_INSUR_COMP }], path:[ALL_INSUR_COMP],key: 6, icon: "las la-city" },
    { menu: "Integration", subMenu: [{ subMenuItem: "Google Sheet",path: GOOGLE_SHEET},{subMenuItem: "Active Campaign",path: ACTIVE_CAMPAIGN}], path:[ACTIVE_CAMPAIGN,GOOGLE_SHEET],key: 7, icon: "bx bx-collection" },
    // { menu: "Form Setting", subMenu: [], path:[FORM_SETTING],key: 8, icon: "ri-settings-4-line" },
    { menu: "Edit Form ", subMenu: [{ subMenuItem: "Question",path: FORM_QUESTION},{subMenuItem: "Value",path: FORM_VALUE},{subMenuItem: "Sub Question",path: FORM_SUB_QUESTION},{subMenuItem: "Sub Value",path: FORM_SUB_VALUE}], path:[FORM_QUESTION,FORM_VALUE,FORM_SUB_QUESTION,FORM_SUB_VALUE],key: 8, icon: "ri-file-edit-line" },
  ]

  const showSubList = (num,index) => {
    setSelectedMenu(index);
    var id = document.getElementById(`list${num}`);
    for (let i = 1; i <= obj?.length; i++) {
      var listId = document.getElementById(`list${i}`);
      if (i == num ) continue
      else if (listId.classList.contains('d-block')) {

        listId.classList.remove('d-block');
        listId.classList.add('d-none');
      }
    }
    if (id.classList.contains('d-block')) {
      id.classList.remove('d-block');
      id.classList.add('d-none');
    }
    else if (!id.classList.contains('d-block')) {
      id.classList.remove('d-none');
      id.classList.add('d-block');
    }
  }

  useEffect(() => {
    if (pathname == DASHBOARD || pathname == FORM_SETTING) {
      setSelectedMenu('');
      for (let i = 1; i <= obj?.length; i++) {
        var listId = document.getElementById(`list${i}`)
        listId?.classList.remove('d-block');
        listId?.classList.add('d-none');

      }
    }
  }, [pathname])

  const handleListClick=(path)=>{
    if(window.innerWidth <= 500)
    {
      val?.setShowSidebar(false);
    }
    navigate(path);
  }
  return (
    <>
    {window.innerWidth <= 500 ? <> <div className={`side`}>
    <Offcanvas show={val?.showSidebar} onHide={handleClose} scroll={true}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='p-0'>
        <div className={`sidebar-app app-menu navbar-menu sidebar-background1`}> 
        <div className="navbar-brand-box">
          <a className="logo logo-light d-block" onClick={() => { navigate(DASHBOARD) }} style={{ cursor: "pointer" }}>
            <span className="logo-sm">
              <img src={require('../../assets/logo_green_orange2.png')} height={50} className={`logo-image-color`} />
            </span>
            <span className="logo-lg ">
              <img src={require('../../assets/logo_green_orange2.png')} height={60} className={`logo-image-color`} />
            </span>
          </a>
          <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
            <i className="ri-record-circle-line" />
          </button>
        </div>
        <div id="scrollbar">
          <div className="container-fluid">
            <ul className="navbar-nav" id="navbar-nav">

              {/* <li className="menu-title text-start text-white"><span data-key="t-menu">Menu</span></li> */}
              <li className="nav-item">
                <a className={`nav-link menu-link ${pathname == DASHBOARD ? 'selected' : 'not-selected'}`} role="button" aria-expanded="false" aria-controls="sidebarDashboards" onClick={() => { handleListClick(DASHBOARD) }}>
                  <i className="ri-dashboard-2-line" /> <span data-key="t-dashboards" >Dashboard</span>
                </a>
              </li>
              {obj && obj?.map((o, index) => {
                return <li className={`nav-item`} key={index}>
                  <a id={index+1} className={`nav-link menu-link ${selectedMenu ?(selectedMenu == index+1) ? 'selected select-arrow' : 'not-selected': o?.path.includes(pathname) ? 'selected select-arrow ' : 'not-selected'}`} onClick={() => showSubList(o?.key,index+1)} href="#" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarDashboards1">
                    <i className={o?.icon} /> <span data-key="t-dashboards">{o?.menu}</span>
                  </a>
                  <div className={`collapse menu-dropdown ${o?.path.includes(pathname) ? 'selected d-block ' : 'not-selected d-none '}`} id={`list${o?.key}`}>
                    <ul className={`nav nav-sm flex-column`}>
                      {o?.subMenu?.map((o1, index) => {
                        return <li className={`nav-item`} key={index} onClick={() => {handleListClick(o1?.path)}}>
                          <div className='d-flex nav-dash'>
                            <div className={`nav-link-dash ${o1?.path == pathname ? 'selected ' : 'not-selected'}`}>–</div>
                          <a className={`nav-link ${o1?.path == pathname ? 'selected ' : 'not-selected'} `} data-key="t-analytics"> {o1?.subMenuItem}</a></div> 
                        </li>
                      })}
                    </ul>
                  </div>
                </li>
              })}
              <li className="nav-item">
                <a className={`nav-link menu-link ${pathname == FORM_SETTING ? 'selected' : 'not-selected'}`} role="button" aria-expanded="false" aria-controls="sidebarDashboards" onClick={() => { handleListClick(FORM_SETTING) }}>
                  <i className="ri-settings-4-line" /> <span data-key="t-dashboards" >Form Setting</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>    
        </Offcanvas.Body>
      </Offcanvas>
    </div></> : <> <div className={`sidebar-app app-menu navbar-menu sidebar-background1 ${val?.showSidebar ? 'sidebar-open' : "sidebar-close"}`}> 
        <div className="navbar-brand-box">
          <a className="logo logo-light d-block" onClick={() => { navigate(DASHBOARD) }} style={{ cursor: "pointer" }}>
            <span className="logo-sm">
              <img src={require('../../assets/logo_green_orange2.png')} height={50} className={`logo-image-color`} />
            </span>
            <span className="logo-lg ">
              <img src={require('../../assets/logo_green_orange2.png')} height={60} className={`logo-image-color`} />
            </span>
          </a>
          <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
            <i className="ri-record-circle-line" />
          </button>
        </div>
        <div id="scrollbar">
          <div className="container-fluid">
            <ul className="navbar-nav" id="navbar-nav">

              {/* <li className="menu-title text-start text-white"><span data-key="t-menu">Menu</span></li> */}
              <li className="nav-item">
                <a className={`nav-link menu-link ${pathname == DASHBOARD ? 'selected' : 'not-selected'}`} role="button" aria-expanded="false" aria-controls="sidebarDashboards" onClick={() => { handleListClick(DASHBOARD) }}>
                  <i className="ri-dashboard-2-line" /> <span data-key="t-dashboards" >Dashboard</span>
                </a>
              </li>
              {obj && obj?.map((o, index) => {
                return <li className={`nav-item`} key={index}>
                  <a id={index+1} className={`nav-link menu-link ${selectedMenu ?(selectedMenu == index+1) ? 'selected select-arrow' : 'not-selected': o?.path.includes(pathname) ? 'selected select-arrow ' : 'not-selected'}`} onClick={() => showSubList(o?.key,index+1)} href="#" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarDashboards1">
                    <i className={o?.icon} /> <span data-key="t-dashboards">{o?.menu}</span>
                  </a>
                  <div className={`collapse menu-dropdown ${o?.path.includes(pathname) ? 'selected d-block ' : 'not-selected d-none '}`} id={`list${o?.key}`}>
                    <ul className={`nav nav-sm flex-column`}>
                      {o?.subMenu?.map((o1, index) => {
                        return <li className={`nav-item`} key={index} onClick={() => {handleListClick(o1?.path)}}>
                          <div className='d-flex nav-dash'>
                            <div className={`nav-link-dash ${o1?.path == pathname ? 'selected ' : 'not-selected'}`}>–</div>
                          <a className={`nav-link ${o1?.path == pathname ? 'selected ' : 'not-selected'} `} data-key="t-analytics"> {o1?.subMenuItem}</a></div> 
                        </li>
                      })}
                    </ul>
                  </div>
                </li>
              })}
               <li className="nav-item">
                <a className={`nav-link menu-link ${pathname == FORM_SETTING ? 'selected' : 'not-selected'}`} role="button" aria-expanded="false" aria-controls="sidebarDashboards" onClick={() => { handleListClick(FORM_SETTING) }}>
                  <i className="ri-settings-4-line" /> <span data-key="t-dashboards" >Form Setting</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div> </>}
       
   

    </>
  )
}

export default AdminSidebar