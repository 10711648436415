import React, { useEffect } from 'react'
import { useState } from 'react';
import { useLocation } from 'react-router'
import { ACTIVE_CAMPAIGN, ALL_BRANDS, ALL_INSUR_COMP, ALL_LEAD, ALL_MODELS, ALL_TRIMNAME, ALL_VEHICLE,  FORM_QUESTION, FORM_SETTING, FORM_SUB_QUESTION, FORM_SUB_VALUE, FORM_VALUE, GOOGLE_SHEET } from '../services/AdminRoutePath';

const AdminStater = () => {
  const location = useLocation();
  const [data, setData] = useState({ top: "", center: "", bottom: "" })
  useEffect(() => {
    switch (location.pathname) {
      case ALL_BRANDS:
        setData({ top: "Manage Brands", center: "Brand", bottom: "All Brand" })
        break;
      case ALL_MODELS:
        setData({ top: "Manage Models", center: "Model", bottom: "All Model" })
        break
      case ALL_TRIMNAME:
        setData({ top: "Manage Trim Names", center: "Trim Name", bottom: "All Trim Name" })
        break
      case ALL_VEHICLE:
        setData({ top: "Manage Vehicles", center: "Vehicle", bottom: "All Vehicle" })
        break
      case ALL_LEAD:
        setData({ top: "Manage Leads", center: "Lead", bottom: "All Lead" })
        break
      case ALL_INSUR_COMP:
        setData({ top: "Manage Insurance Company", center: "Insurance Company", bottom: "All Inaurance Company" })
        break
      case GOOGLE_SHEET:
        setData({ top: "Manage Google Sheet Account", center: "Integration", bottom: "Google Sheet" })
        break
      case ACTIVE_CAMPAIGN:
        setData({ top: "Manage Active Campaign Account", center: "Integration", bottom: "Active Campaign" })
        break
      case FORM_SETTING:
        setData({ top: "Manage Form Setting", center: "Form Setting", bottom: "" })
        break
      case FORM_QUESTION:
        setData({ top: "Manage Form Question", center: "Edit Form", bottom: "Form Question" })
        break
      case FORM_VALUE:
        setData({ top: "Manage Form Value", center: "Edit Form", bottom: "Form Value" })
        break
      case FORM_SUB_QUESTION:
        setData({ top: "Manage Form Sub Question", center: "Edit Form", bottom: "Form Sub Question" })
        break
      case FORM_SUB_VALUE:
        setData({ top: "Manage Fprm Sub Value", center: "Edit Form", bottom: "Fprm Sub Value" })
        break
      default:
        break;

    }
  }, [])
  return (
    <>
      <div className='mt-1'>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0">{data?.top}</h4>
              {location.pathname == FORM_SETTING ? null : <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><a>{data?.center}</a></li>
                  <li className="breadcrumb-item ">{data?.bottom}</li>
                </ol>
              </div>}
             
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminStater