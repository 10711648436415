import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import RadioInput from "../../comp/js/RadioInput";
import ButtonInput from "../../comp/js/ButtonInput";
import { useNavigate } from "react-router";
import { AiOutlineCheck } from "react-icons/ai";
import Number from "../../step/Number";
import SearchSelect from "../../comp/js/SearchSelect";
import { useDispatch, useSelector } from "react-redux";
import { HOME_OWNER, HOME_START } from "../../services/UserRoutes";
import TextFieldInput from "../../comp/js/TextFieldInput";
import { BsInfoCircle } from 'react-icons/bs'
import { saveHomeDetail } from "../../../redux/action/UserAction";
import { NavLink } from "react-router-dom";
import { btn_col, color, foundation_types, que_col, que_value_col, requiredFeild } from "../../services/Methods";
import CurrencyInput from 'react-currency-input-field';
import classNames from "classnames";
import { UserValContext } from "../../context/UserContextProvider";
import { differenceInYears, isValid, parse } from "date-fns"
import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min';
import moment from "moment/moment";

const HomeDetail = () => {

    const [builtYear, setBuiltYear] = useState(1800);
    const [homeError, setHomeError] = useState('');
    const dispatch = useDispatch();
    let { userHomeDetail } = useSelector((state) => state?.persistedReducer);
    const { submitting, setSubmitting, dot_color,button_color } = useContext(UserValContext)

    const numberStyle = { padding: "5px 7px" };
    const [validation, setValidation] = useState(yup.string().required(requiredFeild));

    const [validationObj, setValidationObj] = useState({
        home_own: validation,
        built_year: yup.number('year must be a number').min(1700, 'We can only accept homes built between 1800-2023').max(new Date().getFullYear(), 'We can only accept homes built between 1800-2023').required(requiredFeild),
        fire_station: validation,
        roof_year: yup.number('year must be a number').min(builtYear, "Enter a year from when your home was built and today's date").max(new Date().getFullYear(), "Enter a year from when your home was built and today's date").required(requiredFeild),
        square_footage: yup.number('year must be a number').min(10, "Please enter a number from 10 to 999,999").max(999999, "Please enter a number from 10 to 999,999").required(requiredFeild),
        rebuilt_cost: yup.number('year must be a number').min(50000, "Replacement cost must be at least $50,000.").required(requiredFeild),
        foundation_type: yup.object().required(requiredFeild),
    });

    const naviagte = useNavigate();

    const { setErrors, values, setFieldValue, errors, handleBlur, handleChange, handleSubmit, touched, setValues,setFieldError,
    } = useFormik({
        initialValues: {
            home_own: userHomeDetail?.home_own ? userHomeDetail?.home_own : "", built_year: userHomeDetail?.built_year ? userHomeDetail?.built_year : "", foundation_type: userHomeDetail?.foundation_type ? userHomeDetail?.foundation_type : "", fire_station: userHomeDetail?.fire_station ? userHomeDetail?.fire_station : "", roof_year: userHomeDetail?.roof_year ? userHomeDetail?.roof_year : "", square_footage: userHomeDetail?.square_footage ? userHomeDetail?.square_footage : "", rebuilt_cost: userHomeDetail?.rebuilt_cost ? userHomeDetail?.rebuilt_cost : "", buy_home: userHomeDetail?.buy_home ? userHomeDetail?.buy_home : ""
        },
        validationSchema: yup.object().shape(validationObj),
        onSubmit: (values, { resetForm }) => {
            dispatch(saveHomeDetail(values));
            if (values && !homeError) naviagte(HOME_OWNER);
        },
    });

    const handleRadio = (value, name) => {
        setFieldValue(name, value);
    };


    useEffect(() => {
        setValidationObj({ ...validationObj, roof_year: yup.number('year must be a number').min(builtYear, "Enter a year from when your home was built and today's date").max(new Date().getFullYear(), "Enter a year from when your home was built and today's date").required(requiredFeild) });
        setBuiltYear(values.built_year)
        // if (values.home_own == "Yes, I already own it") {
        //     setValidationObj({ ...validationObj, buy_home: validation });
        // }
        // else if (values.home_own != "Yes, I already own it")
        //     setValidationObj((validationObj) => {
        //         const newData = { ...validationObj };
        //         delete newData["buy_home"];
        //         return newData;
        //     });
    }, [values]);

    const calculateAge = (dob) => {

        let date = moment(dob, "MM/YYYY").isValid()
    
        if ((dob == '' || !dob) && submitting) {setFieldError('buy_home', 'This field is required !')}
        else {
          if (!date && submitting){setFieldError('buy_home', 'Invalid Date !')}
          else if(date) {
              let year = moment(dob,'MM/YYYY').year()
              if( (year < 1800) || (year >new Date().getFullYear())){setFieldError('buy_home', 'We can only accept homes built between 1800-2023')}
          }
        }
      }

      useEffect(() => {
        calculateAge(values?.buy_home)
        if(values && !errors) setSubmitting(false)
      }, [values, submitting, errors])

    return (
        <>
            <div
                className="container-fluid w-100 pt-3 g-3 mb-5 user-content"
                style={{ paddingTop: "20px" }}
            >
                <form onSubmit={handleSubmit}>
                    <div className="row g-2 mb-4">
                        <h2>Let’s build your protection from the foundation up!</h2>
                    </div>
                    <div className="row g-2 mt-4">
                        <div className={`${que_col} col-12`}>
                            <div className="d-flex">
                                <Number number={1} value={values.home_own} />
                                <div className="w-100">
                                    <h3>Do you already own this home?</h3>
                                    <h6 className="text-start w-w-100">You’ll still get quotes if you don’t own the home yet.</h6>
                                </div>
                            </div>
                        </div>
                        <div className={`${que_value_col} col-12`}>
                            <div
                                className="btn-group d-flex flex-column gap-3"
                                role="group"
                                aria-label="Basic radio toggle button group"
                                onChange={(e) => handleRadio(e.target.value, "home_own")}
                                onBlur={handleBlur}
                                value={values.home_own}
                            >
                                <RadioInput
                                    value={"Yes, I already own it"}
                                    name={'home_own'}
                                    w={"w-100"}
                                    val={values.home_own}
                                    error={errors.home_own}
                                    touch={touched.home_own}
                                    checked={values.home_own == "Yes, I already own it" ? true : false}
                                />
                                <RadioInput
                                    value={"Almost, I’m closing on it soon"}
                                    name={'home_own'}
                                    w={"w-100"}
                                    val={values.home_own}
                                    error={errors.home_own}
                                    touch={touched.home_own}
                                    checked={values.home_own == "Almost, I’m closing on it soon" ? true : false}
                                />
                                <RadioInput
                                    value={"No, I’m just looking around"}
                                    name={'home_own'}
                                    w={"w-100"}
                                    val={values.home_own}
                                    error={errors.home_own}
                                    touch={touched.home_own}
                                    checked={values.home_own == "No, I’m just looking around" ? true : false}
                                />
                            </div>
                            {errors.home_own && touched.home_own && (<span className="text-danger">{errors.home_own}</span>)}
                        </div>
                    </div>
                    {
                        <div className={`row g-2 mt-4 ${values.home_own == "Yes, I already own it" ? "target d-flex" : "target_none d-none"}`}>
                            <div className={`${que_col} col-12`}>
                                <div className="d-flex">
                                    <span
                                        className="me-2 badge border border-user-secondary rounded-circle text-dark"
                                        style={{ height: "28px", width: "28px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: values.buy_home ? dot_color : "", padding: "2px", }}>
                                        {values.buy_home ? (<AiOutlineCheck style={{ color: "white", fontSize: "20px" }} />) : (<div style={numberStyle}>2</div>)}
                                    </span>
                                    <div className="w-100 d-flex flex-column">
                                        <h3>When did you buy your home?</h3>
                                        <h6 className="text-start w-w-100">Month & year of purchase</h6>
                                    </div>
                                </div>
                            </div>
                            <div className={`${que_value_col} col-12`}>
                                <div className="btn-group d-flex flex-column gap-3" role="group" aria-label="Basic radio toggle button group">
                                    <TextFieldInput
                                        placeholder={"Buy year"}
                                        touched={touched?.bdate}
                                        handleBlur={handleBlur}
                                        maxlength={7}
                                        pattern="[0-9/]+"
                                        type={'tel'}
                                        w={"w-100"}
                                        holder={"MM/YYYY"}
                                        error={errors?.buy_home}
                                        name={"buy_home"}
                                        value={values?.buy_home}
                                        handleChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row g-2 mt-4">
                        <div className={`${que_col} col-12`}>
                            <div className="d-flex">
                                <span
                                    className="me-2 badge border border-user-secondary rounded-circle text-dark"
                                    style={{
                                        height: "28px",
                                        width: "28px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        backgroundColor: values.built_year ? dot_color : "",
                                        padding: "2px",
                                    }}
                                >
                                    {values.built_year ? (
                                        <AiOutlineCheck
                                            style={{ color: "white", fontSize: "20px" }}
                                        />
                                    ) : (
                                        <div style={numberStyle}>{values.home_own == "Yes, I already own it" ? 3 : 2}</div>
                                    )}
                                </span>
                                <div className="w-100 d-flex align-items-center">
                                    <h3 className="mb-0">What year was your home built?</h3>
                                </div>
                            </div>
                        </div>
                        <div className={`${que_value_col} col-12`}>
                            <div
                                className="btn-group d-flex flex-column gap-3"
                                role="group"
                                aria-label="Basic radio toggle button group"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.built_year}
                            >
                                <TextFieldInput
                                    type={'number'}
                                    w={"w-100"}
                                    placeholder={"Year built"}
                                    holder={'YYYY'}
                                    error={errors.built_year}
                                    touched={touched.built_year}
                                    name={"built_year"}
                                    value={values.built_year}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`row g-2 mt-4`}>
                        <div className={`${que_col} col-12`}>
                            <div className="d-flex">
                                <span
                                    className="me-2 badge border border-user-secondary rounded-circle text-dark"
                                    style={{ height: "28px", width: "28px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: values.foundation_type ? dot_color : "", padding: "2px" }}
                                >
                                    {values.foundation_type ? (<AiOutlineCheck style={{ color: "white", fontSize: "20px" }} />) : (<div style={numberStyle}>{values.home_own == "Yes, I already own it" ? 4 : 3}</div>)}
                                </span>
                                <div className="w-100 d-flex flex-column">
                                    <h3>What type of foundation does your home have?</h3>
                                    <h6 className="text-start mb-0 w-100">If you have more than one foundation type supporting your home, choose the option that makes up the majority.</h6>
                                    <span className="description fw-bold mt-1" style={{ color: dot_color }}>
                                        <BsInfoCircle className="fs-5 me-1" />
                                        <a href="#" style={{ color: dot_color }} className="m-0 text-decoration-none">Learn more about foundation types</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={`${que_value_col} col-12`}>
                            <SearchSelect
                                w={"w-100"}
                                placeholder={"Select foundation type"}
                                isSearchable={true}
                                name={"foundation_type"}
                                value={values.foundation_type}
                                error={errors.foundation_type}
                                touched={touched.foundation_type}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                opt={foundation_types}
                            />
                            {/* {errors.foundation_type && touched.foundation_type && (<span className="text-danger">{errors.foundation_type}</span>)} */}
                        </div>
                    </div>
                    <div className="row g-2 mt-4">
                        <div className={`${que_col} col-12`}>
                            <div className="d-flex">
                                <span
                                    className="me-2 badge border border-user-secondary rounded-circle text-dark" style={{ height: "28px", width: "28px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: values.roof_year ? dot_color : "", padding: "2px", }}
                                >
                                    {values.roof_year ? (<AiOutlineCheck style={{ color: "white", fontSize: "20px" }} />) : (<div style={numberStyle}>{values.home_own == "Yes, I already own it" ? 5 : 4}</div>)}
                                </span>
                                <div className="w-100 d-flex align-items-center"><h3 className="mb-0"> When was your roof installed or last replaced?</h3></div>
                            </div>
                        </div>
                        <div className={`${que_value_col} col-12`}>
                            <div className="btn-group d-flex flex-column gap-3" role="group" aria-label="Basic radio toggle button group">
                                <TextFieldInput
                                    type={'number'}
                                    w={"w-100"}
                                    placeholder={"Year"}
                                    error={errors.roof_year}
                                    touched={touched.roof_year}
                                    name={"roof_year"}
                                    value={values.roof_year}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row g-2 mt-4">
                        <div className={`${que_col} col-12`}>
                            <div className="d-flex">
                                <Number number={values.home_own == "Yes, I already own it" ? 6 : 5} value={values.square_footage} />
                                <div className="w-100">
                                    <h3>What is the square footage?</h3>
                                    <h6 className="text-start w-w-100">Dont’t include square footage from your garage, basement, or attic.</h6>
                                </div>
                            </div>
                        </div>
                        <div className={`${que_value_col} col-12`}>
                            <div className="btn-group d-flex flex-column gap-3" role="group" aria-label="Basic radio toggle button group">
                                <TextFieldInput
                                    type={'number'}
                                    w={"w-100"}
                                    placeholder={"Square Footage"}
                                    error={errors.square_footage}
                                    touched={touched.square_footage}
                                    name={"square_footage"}
                                    value={values.square_footage}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={`row g-2 mt-4`}>
                        <div className={`${que_col} col-12`}>
                            <div className="d-flex">
                                <span
                                    className="me-2 badge border border-user-secondary rounded-circle text-dark"
                                    style={{ height: "28px", width: "28px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: values.rebuilt_cost ? dot_color : "", padding: "2px", }}>
                                    {values.rebuilt_cost ? (<AiOutlineCheck style={{ color: "white", fontSize: "20px" }} />) : (<div style={numberStyle}>{values.home_own == "Yes, I already own it" ? 7 : 6}</div>)}
                                </span>
                                <div className="w-100 d-flex flex-column">
                                    <h3>How much would it cost to rebuild your home?</h3>
                                    <h6 className="text-start mb-0 w-100">Insurance companies use this amount to set your rate, not your home’s market value.</h6>
                                    <span className="description fw-bold mt-1" style={{ color: dot_color }}>
                                        <BsInfoCircle className="fs-5 me-1" />
                                        <a href="#" style={{ color: dot_color }} className="m-0 text-decoration-none">Learn more about estimating rebuild cost</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={`${que_value_col} col-12`}>
                            <div className="btn-group d-flex flex-column gap-3" role="group" aria-label="Basic radio toggle button group">
                                <CurrencyInput
                                    className={classNames(`form-control form-control-md py-3 `, { 'is-invalid': submitting && errors.rebuilt_cost })}
                                    type="cost"
                                    prefix="$"
                                    placeholder="Rebuild Cost"
                                    defaultValue={1000}
                                    decimalsLimit={2}
                                    onValueChange={(value, name) => setFieldValue('rebuilt_cost', value)}
                                    error={errors.rebuilt_cost}
                                    touched={touched.rebuilt_cost}
                                    name={"rebuilt_cost"}
                                    value={values.rebuilt_cost}
                                    handleChange={(e) => console.log(e.target.value)}
                                    handleBlur={handleBlur}
                                />
                            </div>
                            {errors.rebuilt_cost && <span className=" text-danger fs-4">{submitting && errors.rebuilt_cost}</span>}
                        </div>
                    </div>

                    <div className="row g-2 mt-4">
                        <div className={`${que_col} col-12`}>
                            <div className="d-flex">
                                <span
                                    className="me-2 badge border border-user-secondary rounded-circle text-dark"
                                    style={{ height: "28px", width: "28px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: values.fire_station ? dot_color : "", padding: "2px", }}>
                                    {values.fire_station ? (<AiOutlineCheck style={{ color: "white", fontSize: "20px" }} />) : (<div style={numberStyle}>{values.home_own == "Yes, I already own it" ? 8 : 7}</div>)}
                                </span>
                                <div className="w-100 d-flex align-items-center">
                                    <h3 className="mb-0">How close do you live to the closest fire station?</h3>
                                </div>
                            </div>
                        </div>
                        <div className={`${que_value_col} col-12`}>
                            <div
                                className="btn-group d-flex flex-column gap-3"
                                role="group"
                                aria-label="Basic radio toggle button group"
                                onChange={(e) => handleRadio(e.target.value, "fire_station")}
                                onBlur={handleBlur}
                                value={values.fire_station}
                            >
                                <RadioInput
                                    value={"1-5 miles"}
                                    name={'fire_station'}
                                    w={"w-100"}
                                    val={values.fire_station}
                                    error={errors.fire_station}
                                    touch={touched.fire_station}
                                    checked={values.fire_station == "1-5 miles" ? true : false}
                                />
                                <RadioInput
                                    value={"5-10 miles"}
                                    name={'fire_station'}
                                    w={"w-100"}
                                    val={values.fire_station}
                                    error={errors.fire_station}
                                    touch={touched.fire_station}
                                    checked={values.fire_station == "5-10 miles" ? true : false}
                                />
                                <RadioInput
                                    value={"10+ miles"}
                                    name={'fire_station'}
                                    w={"w-100"}
                                    val={values.fire_station}
                                    error={errors.fire_station}
                                    touch={touched.fire_station}
                                    checked={values.fire_station == "10+ miles" ? true : false}
                                />
                            </div>
                            {errors.fire_station && touched.fire_station && (<span className="text-danger fs-4">{errors.fire_station}</span>)}
                        </div>
                    </div>
                    <div className="row">
                        <div className={`${btn_col} col-12 d-flex justify-content-between`}>
                            <NavLink to={HOME_START} className={'mt-lg-0 mt-4'} style={{ color: "#4a4f53", fontWeight: "600", display: "flex", alignItems: "center", }}>Back</NavLink>
                            <ButtonInput name={"Show my quotes"} type="submit" />
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default HomeDetail;
