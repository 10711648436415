import React, { useContext, useEffect, useState } from "react";
import ButtonInput from "../../../comp/js/ButtonInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { BsInfoCircle } from "react-icons/bs";
import TextFieldInput from "../../../comp/js/TextFieldInput";
import SearchSelect from "../../../comp/js/SearchSelect";
import { NavLink, useNavigate } from "react-router-dom";
import { GOOGLE_API_KEY, MANUAL_START, VEHICLE_SELECT } from "../../../services/UserRoutes";
import { AiOutlineCheck } from 'react-icons/ai'
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { useDispatch, useSelector } from 'react-redux';
import { saveManualStartDetail, storeManualDetail } from "../../../../redux/action/UserAction";
import classNames from "classnames";
import { color, options, que_col, que_value_col, requiredFeild, showError } from "../../../services/Methods";
import { UserValContext } from "../../../context/UserContextProvider";
import { differenceInYears, parse } from "date-fns"
import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min';
import moment from "moment";
import { toast ,ToastContainer} from "react-toastify";

const ManualStartDetail = () => {

  const numberStyle = { padding: '5px 7px', fontSize: '18px' }
  const [dateError, setDateError] = useState('');

  const dispatch = useDispatch()
  let details = useSelector((state) => state?.persistedReducer?.userManualStartDetail);

  let {userManualStart} = useSelector((state)=>state.persistedReducer)

  const {submitting, dot_color ,setSubmitting,button_color} = useContext(UserValContext)

  // two form data object
  let insur_obj = { is_curr_insurance: userManualStart?.car_insurance == 'Yes' ? 1 : 0, insured: userManualStart?.insured == '' ? null : userManualStart?.insured, living_home: userManualStart?.rent_home, is_auto_home: userManualStart?.auto_home == 'Yes' ? 1 : 0, take_insurance: userManualStart?.car_insurance, life_event_policy: userManualStart?.life_event == '' ? null : userManualStart?.life_event?.value }

  const navigate = useNavigate();
  const { values, setFieldValue, errors, handleBlur, handleChange, handleSubmit, touched, setValues, setFieldError } = useFormik({
    initialValues: { unit: details?.unit ? details.unit : "", address: details?.address ? details.address : "", city: details?.city ? details.city : "", state: details?.state ? details.state : "", zipcode: details?.zipcode ? details.zipcode : "", bdate: details?.bdate ? details.bdate : "", fname: details?.fname ? details.fname : "", lname: details?.lname ? details.lname : "", email: details?.email ? details.email : "" },
    validationSchema: yup.object().shape({
      address: yup.string().required(requiredFeild), zipcode: yup.string().required(requiredFeild), city: yup.string().required(requiredFeild), state: yup.object().required(requiredFeild), fname: yup.string().required(requiredFeild), lname: yup.string().required(requiredFeild),
      email: yup.string().email("Invalid Email address").required("Email is required"),
      // bdate: yup.date().required(requiredFeild).max(sub({ years: 16 }, new Date()), "User must be 16 years old"),
    }),
    onSubmit: (values, { resetForm }) => {
      if (values) {
        // dispatch(saveManualStartDetail(values))

        // two forms data object
        let user_obj = { address: values?.address, unit: values?.unit == '' ? null : values?.unit, city: values?.city, state: values?.state?.value, zipcode: values?.zipcode, firstname: values?.fname, lastname: values?.lname, birthdate: moment(values?.bdate, "MM/DD/YYYY").format("YYYY-MM-DD"), email: values?.email }

        dispatch(storeManualDetail(details?.email ? details?.email:null,{user_obj,insur_obj},navigate,values,toast))
        // navigate(VEHICLE_SELECT);
      }
    },
  });

  const handleAddress = ({ formatted_address }) => {

    // console.log(place.formatted_address.split(',').at(-2).slice(0, 3),'=======place=========')
    let address = formatted_address.split(',')
    setFieldValue('address', formatted_address.split(',').at(0))
    setFieldValue('city', address.at(-3))
    setFieldValue('state', { value: address.at(-2).slice(0, 3), label: address.at(-2).slice(0, 3) })
    setFieldValue('zipcode', address.at(-2).split(' ').at(-1))
  }

  const calculateAge = (dob) => {
    const date = parse(dob, "MM/dd/yyyy", new Date())
      if (date == 'Invalid Date' && submitting) setFieldError('bdate', 'Invalid Date !')
      const age = differenceInYears(new Date(), date)
      if (age < 16) setFieldError('bdate', 'User must be 16 years old !')
    
  }
  useEffect(() => {
    if ((values.bdate == '' || !values.bdate) && submitting) setFieldError('bdate', 'this field is required !')
    else if(values?.bdate?.length == 10)calculateAge(values?.bdate)
    else setFieldError['bdate'] = ''
    if(values && !errors) setSubmitting(false)
  }, [values, submitting, errors])

  return (
    <>
      <div
        className="container-fluid w-100 pt-3 g-3 mb-5"
        style={{ paddingTop: "20px" }}
      >
        <div className="row gy-4 mb-4">
          <h2>Let’s find out a little about you</h2>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="row gy-4">
            <div className={`${que_col} col-12`}>
              <div className="d-flex">
                <span
                  className="me-2 badge border border-user-secondary rounded-circle text-dark"
                  style={{ height: '28px', width: '28px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: values.address && values.city && values.state && values.zipcode ? dot_color : '', padding: '2px' }}
                >
                  {(values.address && values.city && values.state && values.zipcode) ? <AiOutlineCheck style={{ color: 'white', fontSize: '20px' }} /> : <div style={numberStyle}>1</div>}
                </span>
                <div className="w-100">
                  <h3>Where do you park your car overnight?</h3>
                  <span
                    className="description fw-bold"
                    style={{ color: dot_color }}
                  >
                    <BsInfoCircle className="fs-5 me-1" />
                    <a
                      href="#"
                      style={{ color: dot_color }}
                      className="m-0 text-decoration-none"
                    >
                      Learn about why your address matters
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className={`${que_value_col} col-12`}>
              <div className="btn-group d-flex flex-lg-row flex-column gap-3" role="group" aria-label="Basic radio toggle button group">
                <div className="d-flex flex-column w-100">
                  <ReactGoogleAutocomplete
                    apiKey={GOOGLE_API_KEY}
                    options={{ types: ["geocode", "establishment"], componentRestrictions: { country: "us" } }}
                    types={["address"]}
                    onChange={(e) => setFieldValue('address', e.target.value)}
                    placeholder={values?.address ? values?.address : "Address"}
                    onPlaceSelected={(place) => handleAddress(place)
                    }
                    style={{ height: '52px', borderRadius: '4px' }}
                    className={classNames(`${!errors.address && 'user-autocomplete'} form-control form-control-md `, { 'is-invalid': (values?.address == '' || !values?.address) && errors?.address })}
                    name={'address'}
                    value={values.address}
                  />
                  {(!values?.address || values?.address == '') && errors.address && touched.address && <span className="text-danger fs-4">{showError(errors.address, submitting)}</span>}
                </div>
                <div className="d-flex flex-column w-100">
                  <TextFieldInput
                    w={"w-100"}
                    placeholder={"Unit #"}
                    name={"unit"}
                    value={values.unit}
                    error={errors.unit}
                    touched={touched.unit}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </div>
              </div>
              <div
                className="btn-group d-flex flex-row gap-3 mt-3"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <div className="d-flex flex-column w-100">
                  <TextFieldInput
                    w={"w-100"}
                    placeholder={"City"}
                    name={"city"}
                    value={values.city}
                    error={errors.city}
                    touched={touched.city}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </div>
                <div className="d-flex flex-column w-100">
                  <SearchSelect
                    w={"w-100"}
                    placeholder={"Select State"}
                    isSearchable={false}
                    name={"state"}
                    value={values.state}
                    error={errors.state}
                    touched={touched.state}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    opt={options}
                  />
                </div>
              </div>
              <div
                className="btn-group d-flex flex-column gap-3 mt-3"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <TextFieldInput
                  w={"w-100"}
                  placeholder={"Zipcode"}
                  name={"zipcode"}
                  value={values.zipcode}
                  error={errors.zipcode}
                  touched={touched.zipcode}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>
            </div>
          </div>
          <div className="row gy-4 mt-3">
            <div className={`${que_col} col-12`}>
              <div className="d-flex">
                <span
                  className="me-2 badge border border-user-secondary rounded-circle text-dark"
                  style={{ height: '28px', width: '28px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: (values.fname && values.lname && values.bdate && values?.email) ? dot_color : '', padding: '2px' }}
                >
                  {(values.fname && values.lname && values.bdate && values?.email) ? <AiOutlineCheck style={{ color: 'white', fontSize: '20px' }} /> : <div style={numberStyle}>2</div>}
                </span>
                <div className="w-100">
                  <h3>Tell us a little about yourself</h3>
                  <span
                    className="description fw-bold"
                    style={{ color: dot_color }}
                  >
                    <BsInfoCircle className="fs-5 me-1" />
                    <a
                      href="#"
                      style={{ color: dot_color }}
                      className="m-0 text-decoration-none"
                    >
                      Learn about how your information is used
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className={`${que_value_col} col-12`}>
              <div
                className="btn-group d-flex flex-column gap-3"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <TextFieldInput
                  w={"w-100"}
                  placeholder={"First name"}
                  error={errors.fname}
                  touched={touched.fname}
                  name={"fname"}
                  value={values.fname}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <TextFieldInput
                  w={"w-100"}
                  placeholder={"Last name"}
                  error={errors.lname}
                  touched={touched.lname}
                  name={"lname"}
                  value={values.lname}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <TextFieldInput
                  w={"w-100"}
                  placeholder={"Email"}
                  error={errors.email}
                  touched={touched.email}
                  name={"email"}
                  value={values.email}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                {/* <TextFieldInput
                  type={'date'}
                  w={"w-100"}
                  placeholder={"MM-DD-YYYY"}
                  error={errors.bdate}
                  touched={touched.bdate}
                  name={"bdate"}
                  value={values.bdate}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                /> */}
                <TextFieldInput
                  maxlength={10}
                  pattern="[0-9/]+"
                  type={'tel'}
                  w={"w-100"}
                  placeholder={"Birthdate"}
                  holder={'MM/DD/YYYY'}
                  error={errors.bdate}
                  value={values?.bdate}
                  handleChange={handleChange}
                  touched={touched?.bdate}
                  name={"bdate"}
                  handleBlur={handleBlur}
                />
              </div>
            </div>
          </div>

          <div className="row mt-3 mt-lg-0">
          {/* col-lg-10 */}
            <div className="col-12 gy-5 d-flex justify-content-between">
              <NavLink className={'mt-lg-0 mt-4'} to={MANUAL_START} style={{ color: "#4a4f53", fontWeight: "600", display: "flex", alignItems: "center" }}>
                Back
              </NavLink>
              <ButtonInput name={"Save & continue"} type="submit" errors={errors} />
            </div>
          </div>
        </form>
      </div>
      <ToastContainer/>
    </>
  );
};

export default ManualStartDetail;
