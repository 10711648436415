import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router';
import { getAdminRole, getAdminToken, getLoggedInAdmin } from '../services/AdminLocalStorage';
import { ADMIN_LOGIN, ALL_BRANDS, ALL_MODELS, ALL_TRIMNAME, ALL_VEHICLE, DASHBOARD } from '../services/AdminRoutePath';

const AdminProtected = ({ Component }) => {

    let adminToken = getAdminToken();
    let adminRole = getAdminRole();
    const navigate = useNavigate();
    const {pathname} = useLocation();
    useEffect(()=>{
        if(!adminToken && !adminRole )
        {
            navigate(ADMIN_LOGIN)
        }
    },[])

    return (
        <>
            <Component />
        </>
    )
}

export default AdminProtected