import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import InputColor from 'react-input-color';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { EDIT_FONT_COLOR, formColorData, getDatas, GET_FORM_COLOR } from '../../redux/action/AdminAction';
import InputButton from '../Input Function/InputButton';
import InputLodingButton from '../Input Function/InputLodingButton';
import AdminPreviewForm from '../layouts/AdminPreviewForm';
import AdminStater from '../layouts/AdminStater';
import BackDrop from '../Input Function/BackDrop'
import '../css/AdminFormSetting.css'
import { ToastContainer } from 'react-toastify';
import { useContext } from 'react';
import { AdminValAuthContext } from '../context/AdminAuthProvider';
const FormsColorChange = () => {
  const dispatch = useDispatch();
  const val = useContext(AdminValAuthContext)
  const [loading, setLoading] = useState(false);
  const [bLoading, setBLoading] = useState(false);
  let load = false;

  useEffect(() => {
    if (!load) {
          setBLoading(true);
          dispatch(getDatas(setBLoading, `/admin/get-form-color`, GET_FORM_COLOR));
    }
    return () => { load = true }
  }, [])
  const formColor = useSelector((state) => state.AdminReducer.formColor);
  useEffect(() => {
    val?.setFontColor(formColor?.font_color)
    val?.setBtnColor(formColor?.button_color)
    val?.setbgColor(formColor?.bg_color)
    val?.setDotColor(formColor?.dot_color)
  }, [formColor])
  const handleColorSubmit = (font,btn,bg,dot) => {
    const formdata = new FormData();
    formdata.append('font_color', font);
    formdata.append('bg_color', bg);
    formdata.append('btn_color', btn);
    formdata.append('dot_color',dot)
    setLoading(true);
    dispatch(formColorData(formdata, setLoading, getDatas, "Color update successfully.", EDIT_FONT_COLOR, `/admin/update-form-color`, `/admin/get-form-color`, GET_FORM_COLOR));
  }
  const obj = [
    { title: "Select Font Color", color: val?.fontColor, setColor: val?.setColor },
    { title: "Select Button Color", color: val?.btnColor, setColor: val?.setColor1 },
    { title: "Select Background Color", color: val?.bgColor, setColor: val?.setColor2 },
    { title: "Select Dot Color", color: val?.dotColor, setColor: val?.setColor3 },
  ]
  return (
    <>
      {bLoading ? <BackDrop /> : null}
      <div className="container-fluid">
        <AdminStater />
        <div className="todo-content position-relative px-3 mx-n4 form-todo-content" id="todo-content" style={{ height: `calc(100vh - 209px)` }}>
          <div className="todo-task" id="todo-task">
            <div className="row">
              <div className="col-xxl-2">
                <div className="card"  >
                  <div className="card-body">
                    {obj && obj.map((i, indx) => {
                      return <div className="d-flex align-items-center mb-2" key={indx}>
                        <div className="flex-grow-1">
                          <h5 className="card-title mb-0 text-dark" style={{fontSize:"13px"}}>{i.title}</h5>
                        </div>
                        <div className="flex-shrink-0">
                          <InputColor
                            initialValue={`${i.color}`}
                            onChange={i.setColor}
                            placement="right"
                          />
                        </div>
                      </div>
                    })}
                    <div className='d-grid justify-content-end'>
                      {
                        loading ? <InputLodingButton name={"Saveing.."} className="btn btn-primary btn-load py-1" /> : <InputButton className="btn btn-primary createTask py-1" type="button" name={`Save`} event={() => { handleColorSubmit(val?.color.hex,val?.color1.hex,val?.color2.hex,val?.color3.hex) }} />
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-10">
              {/* <div className="todo-content position-relative px-3 mx-n4 form-todo-content" id="todo-content" style={{ height: `calc(100vh - 209px)` }}>
          <div className="todo-task" id="todo-task"> */}
                <div className='pe-none'>
                 <AdminPreviewForm/>
                  {/* <AdminPreviewForm dcolor={`${color1?.hex ? color1?.hex : btnColor}`} bcolor={`${color2?.hex ? color2?.hex : bgColor}`} fcolor={`${color?.hex ? color?.hex : fontColor}`} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default React.memo(FormsColorChange)